import {
  Add as AddIcon,
  Cancel,
  Contactless,
  CreditCard,
  Delete as DeleteIcon,
  DoDisturbOn,
  LocalShipping,
  Payments,
  PermContactCalendar,
  Receipt,
  RequestQuote,
  Visibility,
} from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DoneIcon from '@mui/icons-material/Done';
import DraftsIcon from '@mui/icons-material/Drafts';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import SendIcon from '@mui/icons-material/Send';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {
  Badge,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import {
  addMonths,
  eachMonthOfInterval,
  endOfMonth,
  format,
  startOfMonth,
  parseJSON,
  set,
  eachDayOfInterval,
} from 'date-fns';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import DatePickerHeader from '../../../components/DatePickerHeader';
import EntityNavigator from '../../../components/EntityNavigator';
import FilePageDialog from '../../../components/FilePageDialog';
import '../../../css/dataGrid.css';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useSignalREffect from '../../../hooks/useSignalREffect';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import { ChimneyControlAppointmentStatuses } from '../../../types/ChimneyControlAppointmentStatuses';
import { EmailStatuses } from '../../../types/EmailStatuses';
import { EntityTypes } from '../../../types/EntityTypes';
import { translateEmailStatusName } from '../../../utils/nameFormatters';
import ChimneyControlAppointmentDialog from '../chimneyControlAppointments/ChimneyControlAppointmentDialog';
import ChimneySweepingCertificateEmailDialog from './ChimneySweepingCertificateEmailDialog';
import { formatCurrency } from '../../../utils/valueFormatters';
import ChimneySweepingCertificatePickerDialog from '../../blling/BillingPage/ChimneySweepingCertificatePickerDialog';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { ModuleSettingsType } from '../../../types/ModuleSettingsType';
import useModuleSettings from '../../../hooks/useModuleSettings';
import SelectOrderIdDialog from '../../../components/SelectOrderIdDialog';
import { ChimneySweepingCertificateStatus } from '../../../types/ChimneySweepingCertificateStatus';
import { WorkItemStatus } from '../../erp/workItems/WorkItemForm';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import useConfirmOfferDialog from '../../../hooks/useConfirmOfferDialog';
import StornoCreateDialog from './StornoCreateDialog';

export interface ChimneySweepingCertificate {
  id: number;
  reason: string;
  validity: Date;
  addressId: number;
  clientId: number;
  name: string;
  documentNumber: string;
}

const ChimneySweepingCertificatePage = (props: any) => {
  const { listNotSent = false, listDue = false, externalRows = null } = props;
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const { ConfirmOfferDialog, setOfferParams } = useConfirmOfferDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { checkPermission } = useCheckPermission();
  const userId = useSelector((state: any) => state.user.userInfo.userId);
  const [now, setNow] = useState<Date>(new Date());
  const [stornoDialogOpen, setStornoDialogOpen] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: startOfMonth(moment(now).startOf('day').toDate()),
    endDate: endOfMonth(moment(now).endOf('day').toDate()),
  });
  const [tabValue, setTabValue] = useState<number>(2);
  const [orderSelectDialog, setOrderSelectDialog] = useState<any>({
    open: false,
    clientId: 0,
    chimneySweepingCertificateId: 0,
    orderId: 0,
  });
  const [invoiceDialog, setInvoiceDialog] = useState({
    open: false,
    id: 0,
    clientId: 0,
  });
  const [self, setSelf] = useState(
    checkPermission(['ChimneySweepingCertificateViewDueAll']) ? false : true
  );
  const { enqueueSnackbar } = useSnackbar();
  const { ConfirmDialog, setConfirmParams } = useConfirmDialog();

  const [chimneyControlAppointmentDialog, setChimneyControlAppointmentDialog] =
    useState<any>({
      open: false,
      entity: {},
      chimneySweepingCertificateId: 0,
      readonly: false,
      employeeId: 0,
      hasClientSpecialNeeds: false,
      specialNeeds: '',
      isOfferMandatory: false,
    });
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const [emailDialog, setEmailDialog] = useState({
    open: false,
    id: 0,
    clientId: 0,
    orderId: 0,
  });
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor(
    'ChimneySweepingCertificatesPage.title'
  );
  const moduleSettings = useModuleSettings({
    type: ModuleSettingsType.ChimneySweepingCertificateInvoice,
    userId: userId,
  });

  const fetchData = async (abort?: AbortController) => {
    if (externalRows) {
      return setRows(externalRows);
    }

    const { startDate, endDate } = selectedInterval;

    // Parse the startDate and endDate to Date objects
    let start = new Date(startDate);
    let end = new Date(endDate);
    // Helper function to perform the query based on the list type
    const performQuery = async (start, end) => {
      if (listDue) {
        return await chimneySweepingCertificateService.listDue(
          self,
          start,
          end,
          abort?.signal
        );
      } else if (listNotSent) {
        return await chimneySweepingCertificateService.listNotSent(
          start,
          end,
          abort?.signal
        );
      } else {
        return await chimneySweepingCertificateService.list(
          start,
          end,
          null,
          abort?.signal
        );
      }
    };

    try {
      if (!endDate) {
        end = new Date();
      }
      let months = eachMonthOfInterval({ start, end });
      if (eachDayOfInterval({ start, end }).length < 28) {
        months = [start];
      }
      let allRecords = [];

      for (let i = months.length - 1; i >= 0; i--) {
        let isSameStartMonth =
          months[i].getMonth() === start.getMonth() &&
          months[i].getFullYear() === start.getFullYear();
        let isSameEndMonth =
          months[i].getMonth() === end.getMonth() &&
          months[i].getFullYear() === end.getFullYear();
        const monthStart =
          1 === months.length ? start : isSameStartMonth ? start : months[i];
        const monthEnd =
          i === months.length - 1
            ? end
            : isSameEndMonth
              ? end
              : endOfMonth(monthStart);
        const formattedStart = monthStart;
        const formattedEnd = monthEnd;
        if (i === months.length - 1) {
          dispatch({
            type: 'SHOW_QUERY',
            payload: { abort: abort },
          });
        }
        const response = await performQuery(formattedStart, formattedEnd);

        if (response.canceled) {
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        if (response.hasError) {
          console.error('Query had errors:', response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        allRecords = allRecords.concat(response.records);
        setRows(allRecords);
        if (i === months.length - 1) {
          dispatch({ type: 'HIDE' });
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      enqueueSnackbar('An error occurred while fetching data.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    let abort = new AbortController();
    fetchData(abort);

    return () => {
      abort.abort();
    };
  }, [listNotSent, self, selectedInterval, listDue, externalRows]);

  const connection = useSignalREffect('emailHub');
  const handleEmailChanged = useCallback(
    (newStatus: number, emailId: number, openetAt: Date | null) => {
      setRows((prevRows) => {
        return prevRows.map((row) => {
          let found = row.emails.find((email: any) => email.id === emailId);
          if (found) {
            row.lastEmailStatus = newStatus as EmailStatuses;
            found.status = newStatus as EmailStatuses;
            found.openedAt = new Date(openetAt);
          }
          return row;
        });
      });
    },
    []
  );

  const updateStatus = (
    id: number,
    status: ChimneySweepingCertificateStatus
  ) => {
    dispatch({ type: 'SHOW_QUERY' });
    chimneySweepingCertificateService
      .updateStatus({ id, status })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres módosítás', {
            variant: 'success',
          });
          setRows((prevRows) => {
            return prevRows.map((row) => {
              if (row.id === id) {
                row.status = status;
              }
              return row;
            });
          });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  const createStornoCert = (documentNumber: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    return chimneySweepingCertificateService
      .create({
        name: 'ÉGÉSTERMÉK-ELVEZETŐK ELLENŐRZÉSE, SZÜKSÉG SZERINTI TISZTÍTÁSA, 4 ÉVENKÉNTI MŰSZAKI FELÜLVIZSGÁLATA',
        id: 0,
        isNotRealCert: true,
        airSpaceConnectionAmount: '',
        clientId: null,
        employeeId: null,
        serviceProviderId: null,
        approverName: '',
        documentNumber: documentNumber,
        approverTitle: '',
        others: '',
        date: now,
        workAddressId: null,
        carbonMonoxideSensors: [],
        obligedToInstallCODevice: '',
        obligedToInstallCODevicePlaceNumber: '',
        hasCODevice: '',
        cODevicePlace: '',
        cODeviceWorks: '',
        cleaningDone: '',
        calibrationDone: '',
        hasDocuments: '',
        expired: '',
        errorMessage: '',
        appearedOnLocation: true,
        inspection: true,
        cleaning: false,
        technicalReview: false,
        chimneyControls: [],
        hasDeliveryFee: true,
        isPaid: true,
        paymentMethod: 'Készpénz',
        status: ChimneySweepingCertificateStatus.Cancelled,
      })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          setRows((prev) => {
            return [...prev, response.result];
          });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  useEffect(() => {
    if (!connection) return;

    var callback = handleEmailChanged;
    connection.on('EmailStatusChanged', callback);

    return () => {
      connection.off('EmailStatusChanged', callback);
    };
  }, [connection, handleEmailChanged]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    if (listDue) {
      let readonly = false;

      if (params.row.chimneyControlAppointment.id > 0) {
        readonly =
          !checkPermission(['ChimneyControlAppointmentEdit']) ||
          params.row.chimneyControlAppointment.createdById !== userId;
      } else {
        readonly = !checkPermission(['ChimneyControlAppointmentCreate']);
      }

      let hasAppointment = params.row.chimneyControlAppointment.id > 0;

      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            hasAppointment ? (
              <Tooltip title="Egyeztetés megtekintése">
                <Visibility />
              </Tooltip>
            ) : (
              <Tooltip title="Egyeztetés">
                <DoneIcon />
              </Tooltip>
            )
          }
          label="Egyeztetés"
          onClick={() => {
            setChimneyControlAppointmentDialog({
              open: true,
              entity:
                params.row.chimneyControlAppointment.id > 0
                  ? params.row.chimneyControlAppointment
                  : {},
              chimneySweepingCertificateId: params.row.id,
              readonly: readonly,
              employeeId: params.row.employeeId,
              hasClientSpecialNeeds: params.row.hasClientSpecialNeeds,
              specialNeeds: params.row.clientSpecialNeeds,
              isOfferMandatory: params.row.isOfferMandatory,
            });
          }}
        />
      );
    }
    if (
      checkPermission(['CreateBillingoInvoice']) &&
      !listDue &&
      !listNotSent &&
      !(params.row.orderId > 0) &&
      params.row.status !== ChimneySweepingCertificateStatus.Cancelled
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Számlázás">
              <NoteAddIcon />
            </Tooltip>
          }
          label="Számlázás"
          onClick={() => {
            params.row.hasClientSpecialNeeds
              ? setConfirmParams({
                  open: true,
                  name: params.row.clientSpecialNeeds,
                  onConfirm: async () => {
                    if (params.row.isOfferMandatory) {
                      setOfferParams({
                        open: true,
                        name: 'Kérjük ellenőrizze hogy készült-e árajánlat számlázást megelőzően!',
                        onConfirm: async () => {
                          setInvoiceDialog({
                            open: true,
                            id: params.row.id,
                            clientId: params.row.clientId,
                          });
                        },
                      });
                    } else {
                      setInvoiceDialog({
                        open: true,
                        id: params.row.id,
                        clientId: params.row.clientId,
                      });
                    }
                  },
                })
              : params.row.isOfferMandatory
                ? setOfferParams({
                    open: true,
                    name: 'Készült az árajánlat, kérjük ellenőrizze!',
                    onConfirm: async () => {
                      setInvoiceDialog({
                        open: true,
                        id: params.row.id,
                        clientId: params.row.clientId,
                      });
                    },
                  })
                : setInvoiceDialog({
                    open: true,
                    id: params.row.id,
                    clientId: params.row.clientId,
                  });
          }}
        />
      );
    }
    if (checkPermission(['ViewFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '4 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon color="primary" />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }
    if (checkPermission(['SendEmail']) && !listDue) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Tanúsítvány küldése">
              <SendIcon />
            </Tooltip>
          }
          label="Tanúsítvány küldése"
          disabled={!params.row.clientId}
          onClick={() => {
            setEmailDialog({
              open: true,
              id: params.row.id,
              clientId: params.row.clientId,
              orderId: params.row.orderId,
            });
          }}
        />
      );
    }

    let readonly = !checkPermission(['ChimneySweepingCertificateEdit']);

    actions.push(
      <EntityNavigator
        entityType={EntityTypes.ChimneySweepingCertificate}
        entityId={params.row.id}
        value={readonly ? 'Megtekintés' : 'Szerkesztés'}
        showIcon
        readonly={readonly}
        onSave={() => {
          fetchData();
        }}
      />
    );
    if (!listDue && !listNotSent) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip
              title={
                params.row.status === ChimneySweepingCertificateStatus.Valid
                  ? 'Sztornó'
                  : 'Érvényes'
              }
            >
              {params.row.status === ChimneySweepingCertificateStatus.Valid ? (
                <Cancel color="error" />
              ) : (
                <DoneIcon color="success" />
              )}
            </Tooltip>
          }
          label={
            params.row.status === ChimneySweepingCertificateStatus.Valid
              ? 'Sztornó'
              : 'Érvényes'
          }
          onClick={() => {
            params.row.status === ChimneySweepingCertificateStatus.Valid
              ? updateStatus(
                  params.row.id,
                  ChimneySweepingCertificateStatus.Cancelled
                )
              : updateStatus(
                  params.row.id,
                  ChimneySweepingCertificateStatus.Valid
                );
          }}
          showInMenu
        />
      );

      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Számla hozzárendelése">
              <Receipt />
            </Tooltip>
          }
          label="Számla hozzárendelése"
          onClick={() => {
            setOrderSelectDialog({
              open: true,
              clientId: params.row.clientId,
              chimneySweepingCertificateId: params.row.id,
              orderId: params.row.orderId,
            });
          }}
          showInMenu
        />
      );
      if (params.row.orderId > 0) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'error'}
            icon={
              <Tooltip title="Számla leválasztása">
                <DoDisturbOn />
              </Tooltip>
            }
            label="Számla leválasztása"
            onClick={() => {
              handleSetOrderId(params.row.id, null);
            }}
            showInMenu
          />
        );
      }
      if (checkPermission(['ChimneySweepingCertificateDelete'])) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Törlés">
                <DeleteIcon />
              </Tooltip>
            }
            label="Törlés"
            showInMenu
            onClick={() =>
              setParams({
                open: true,
                name: '',
                onConfirm: async () => handleDelete(params.row.id),
              })
            }
          />
        );
      }
    }

    return actions;
  };

  const getHeaders = (params: GridRowParams, color: any) => {
    var actions = [];
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus !== EmailStatuses.Sent &&
      params.row.lastEmailStatus !== EmailStatuses.Opened &&
      params.row.lastEmailStatus !== EmailStatuses.Delivered
    ) {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip
              title={`${translateEmailStatusName(params.row.lastEmailStatus)}`}
            >
              <UnsubscribeIcon />
            </Tooltip>
          }
          label="Sikerleten"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Delivered
    ) {
      actions.push(
        <GridActionsCellItem
          color={'primary'}
          icon={
            <Tooltip title="Megérkezett">
              <MarkEmailReadIcon />
            </Tooltip>
          }
          label="Megérkezett"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Opened
    ) {
      actions.push(
        <GridActionsCellItem
          color={'success'}
          icon={
            <Tooltip title="Megnyitva">
              <DraftsIcon />
            </Tooltip>
          }
          label="Megnyitva"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Sent
    ) {
      actions.push(
        <GridActionsCellItem
          color={'warning'}
          icon={
            <Tooltip title="Elküldve">
              <MailOutlineIcon />
            </Tooltip>
          }
          label="Elküldve"
          onClick={() => {}}
        />
      );
    }
    if (!params.row.lastEmailStatus) {
      actions.push(
        <GridActionsCellItem
          icon={
            <Tooltip title="Nincs kiküldve">
              <MailOutlineIcon />
            </Tooltip>
          }
          label="Elküldve"
          onClick={() => {}}
        />
      );
    }

    if (params.row.hasContract) {
      actions.push(
        <GridActionsCellItem
          color={'success'}
          icon={
            <Tooltip
              title={
                <div style={{ whiteSpace: 'pre-line', fontSize: '15px' }}>
                  {`Van Szerződés!\nKedvezmény: ${params.row.contractDiscount}\nLeírás: ${params.row.contractDescription}`}
                </div>
              }
            >
              <PermContactCalendar />
            </Tooltip>
          }
          label="Megérkezett"
          onClick={() => {}}
        />
      );
    } else {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip title="Nincs szerződés">
              <PermContactCalendar />
            </Tooltip>
          }
          label="Nincs szerződés"
          onClick={() => {}}
        />
      );
    }

    if (params.row.paymentMethod === 'Készpénz') {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip title="Készpénz">
              <Payments color="success" />
            </Tooltip>
          }
          label="Nincs szerződés"
          onClick={() => {}}
        />
      );
    } else if (params.row.paymentMethod === 'Átutalás') {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip
              title={`Átutalás(${
                params.row.isInvoicePaid ? 'Fizetve' : 'Nem fizetve'
              })`}
            >
              <RequestQuote
                color={params.row.isInvoicePaid ? 'success' : 'primary'}
              />
            </Tooltip>
          }
          label="Nincs szerződés"
          onClick={() => {}}
        />
      );
    } else if (params.row.paymentMethod === 'Bankkártya') {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip title="Bankkártya">
              <CreditCard color="success" />
            </Tooltip>
          }
          label="Nincs szerződés"
          onClick={() => {}}
        />
      );
    } else if (params.row.paymentMethod === 'Utánvét') {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip title="Utánvét">
              <LocalShipping color="primary" />
            </Tooltip>
          }
          label="Nincs szerződés"
          onClick={() => {}}
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'getHeaders',
      type: 'actions',
      align: 'center',
      flex: 100,
      getActions: getHeaders,
    } as GridActionsColDef,
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 150,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleDateString()
          : 'Nincs';
      },
    },
    {
      field: 'documentNumber',
      headerClassName: 'title',
      headerName: 'Dokumentum száma',
      flex: 100,
      valueGetter(params) {
        return params.value ? parseInt(params.value) : params.value;
      },
      valueFormatter(params) {
        return params.value ? params.value : 'Nincs';
      },
    },
    {
      field: 'employeeName',
      headerName: 'Dolgozó',
      flex: 100,
    },

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 200,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
            // disableLink={externalRows}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'clientPhone',
      headerName: 'Elérhetőség',
      flex: 150,
      valueGetter(params) {
        return (
          (params.row.clientPhone ?? '') +
          (params.row.clientPhone ? ';' : '') +
          (params.row.workAddressEmail ?? '')
        );
      },
    },
    { field: 'workAddress', headerName: 'Cím', flex: 150 },
    { field: 'invoiceNumber', headerName: 'Számlaszám', flex: 150 },
    {
      field: 'finalPriceGross',
      headerName: 'Végösszeg(Bruttó)',
      flex: 100,
      valueFormatter(params) {
        return params.value
          ? formatCurrency(params.value?.toFixed(0))
          : formatCurrency(0);
      },
    },

    {
      field: 'actions',
      type: 'actions',
      flex: 300,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    chimneySweepingCertificateService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const handleSetOrderId = (certId: number, orderId: any) => {
    chimneySweepingCertificateService
      .setOrderId({ chimneySweepingCertificateId: certId, orderId })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres hozzárendelés', {
            variant: 'success',
          });
          setRows((prev) => {
            return prev.map((row) => {
              if (row.id === certId) {
                row.orderId = orderId;
                row.invoiceNumber = response.result.invoiceNumber;
              }
              return row;
            });
          });
        }
      });
  };

  return (
    <Paper>
      <Grid container p={3}>
        {!externalRows && (
          <Grid item xs={12}>
            <DatePickerHeader
              selectedInterval={selectedInterval}
              setSelectedInterval={setSelectedInterval}
              tabValue={tabValue}
              setTabValue={setTabValue}
              netGrossPicker={false}
              localStorageKey={'ChimneySweepingCertificatesPage'}
            />
          </Grid>
        )}
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <h2>Tanúsítványok{titleDescriptor}</h2>
          </Grid>
          {checkPermission(['GetChimneySweepingCertificateModuleSettings']) && (
            <Grid item>{moduleSettings?.ModuleComponent()}</Grid>
          )}
        </Grid>
        {listDue && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      !checkPermission(['ChimneySweepingCertificateViewDueAll'])
                    }
                    checked={self}
                    onChange={(e, checked) => {
                      setSelf(checked);
                    }}
                  />
                }
                label={'Saját adatok'}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display={'flex'} marginBottom={2}>
                <Box marginRight={3}>
                  <Box display="flex" alignItems="center">
                    <div
                      style={{
                        color: 'lightgreen',
                        fontSize: '24px',
                        marginRight: '8px',
                      }}
                    >
                      ▬
                    </div>
                    <div>Egyeztetett</div>
                  </Box>
                </Box>
                <Box marginRight={3}>
                  <Box display="flex" alignItems="center">
                    <div
                      style={{
                        color: 'green',
                        fontSize: '24px',
                        marginRight: '8px',
                      }}
                    >
                      ▬
                    </div>
                    <div>Teljesített</div>
                  </Box>
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <div
                      style={{
                        color: 'red',
                        fontSize: '24px',
                        marginRight: '8px',
                      }}
                    >
                      ▬
                    </div>
                    <div>Sikertelen</div>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </>
        )}
        <Grid container item xs={12} justifyContent="end">
          {!externalRows && (
            <>
              {checkPermission(['ChimneySweepingCertificateCreate']) &&
                !listNotSent &&
                !listDue && (
                  <>
                    <Grid item>
                      <Tooltip title="Sztornózott tanúsítvány létrehozása">
                        <IconButton
                          color="error"
                          onClick={() => {
                            setStornoDialogOpen(true);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Tanúsítvány létrehozása">
                        <IconButton
                          component={RouterLink}
                          to={`/erp/chimneySweepingCertificates/create`}
                          color="primary"
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </>
                )}
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns.filter((column) => {
              if (listDue) {
                return column.field !== 'getHeaders';
              } else {
                return true;
              }
            })}
            localStorageKey={'ChimneySweepingCertificatesPage'}
            getRowId={(row) => row.id}
            getRowClassName={(params) => {
              if (listDue) {
                return params.row.chimneyControlAppointment.id > 0 &&
                  params.row.chimneyControlAppointment.status ===
                    ChimneyControlAppointmentStatuses.Planned
                  ? 'planned'
                  : params.row.chimneyControlAppointment.id > 0 &&
                      params.row.chimneyControlAppointment.status ===
                        ChimneyControlAppointmentStatuses.Failed
                    ? 'failed'
                    : params.row.chimneyControlAppointment.id > 0 &&
                        params.row.chimneyControlAppointment.status ===
                          ChimneyControlAppointmentStatuses.Completed
                      ? 'completed'
                      : '';
              } else {
                return params.row.status ===
                  ChimneySweepingCertificateStatus.Cancelled
                  ? 'failed'
                  : '';
              }
            }}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <ConfirmOfferDialog />
        <ChimneySweepingCertificateEmailDialog
          open={emailDialog.open}
          clientId={emailDialog.clientId}
          setOpen={setEmailDialog}
          chimneySweepingCertificateId={emailDialog.id}
          orderId={emailDialog.orderId}
          onSent={() => {
            fetchData();
          }}
        />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.ChimneySweepingCertificate}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'ChimneySweepingCertificatesPage'}
        />
        <ChimneyControlAppointmentDialog
          entity={chimneyControlAppointmentDialog.entity}
          onSubmit={(appointment) => {
            if (
              appointment.status ===
              ChimneyControlAppointmentStatuses.CompletedByOther
            ) {
              setRows(
                rows.filter(
                  (row) => row.id !== appointment.chimneySweepingCertificateId
                )
              );
            } else {
              let newRows = rows.map((row) => {
                if (row.id === appointment.chimneySweepingCertificateId) {
                  row.chimneyControlAppointment = appointment;
                }
                return row;
              });
              setRows(newRows);
            }
          }}
          readonly={chimneyControlAppointmentDialog.readonly}
          open={chimneyControlAppointmentDialog.open}
          employeeId={chimneyControlAppointmentDialog.employeeId}
          isOfferMandatory={chimneyControlAppointmentDialog.isOfferMandatory}
          specialNeeds={chimneyControlAppointmentDialog.specialNeeds}
          hasClientSpecialNeeds={
            chimneyControlAppointmentDialog.hasClientSpecialNeeds
          }
          onClose={() => {
            setChimneyControlAppointmentDialog({
              open: false,
              entity: {},
              chimneySweepingCertificateId: 0,
            });
          }}
          chimneySweepingCertificateId={
            chimneyControlAppointmentDialog.chimneySweepingCertificateId
          }
        />
        <ChimneySweepingCertificatePickerDialog
          open={invoiceDialog.open}
          clientId={invoiceDialog.clientId}
          setOpen={setInvoiceDialog}
          chimneySweepingCertificateId={invoiceDialog.id}
          moduleSettings={moduleSettings?.settings}
          onCreated={() => {
            fetchData();
          }}
        />
        <SelectOrderIdDialog
          open={orderSelectDialog.open}
          clientId={orderSelectDialog.clientId}
          orderId={orderSelectDialog.orderId}
          onClose={() =>
            setOrderSelectDialog({
              open: false,
              clientId: 0,
              chimneySweepingCertificateId: 0,
            })
          }
          onSelected={(selectedOrder: any) => {
            handleSetOrderId(
              orderSelectDialog.chimneySweepingCertificateId,
              selectedOrder.id
            );
          }}
        />
        <ConfirmDialog
          actionName={
            'Az ügyfélnek speciális igényei vannak. Biztosan szeretné folytatni?'
          }
        />
        <StornoCreateDialog
          open={stornoDialogOpen}
          setOpen={setStornoDialogOpen}
          onSave={createStornoCert}
        />
      </Grid>
    </Paper>
  );
};

export default ChimneySweepingCertificatePage;
