import {
  Add as AddIcon,
  Create,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Refresh,
  Warning,
} from '@mui/icons-material';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../components/DataList';
import { useBarcodeScanner } from '../../components/barcode-scanner/useBarcodeScanner';
import useCheckPermission from '../../hooks/useCheckPermission';
import orderService from '../../services/sales/orderService';
import {
  setConversionRate,
  setCurrency,
  setIncludeUnpriced,
  setInvoiceLanguage,
  setPriceCategoryId,
  setWarehouseId,
} from '../../stateManagement/actions/itemActions';
import {
  addToCart,
  addToItemPackages,
  addToServices,
  removeFromCart,
  removeFromItemPackages,
  removeFromServices,
  replaceCartItem,
  replaceItemPackage,
  reset,
  setAddressId,
  setClientId,
  setCompanyId,
  setCreateInvoice,
  setDescription,
  setDiscount,
  setDocumentBlockId,
  setDueDate,
  setFulfillmentDate,
  setInvoiceInfo,
  setInvoiceType,
  setIsBooking,
  setIsElectronic,
  setItemPackages,
  setPaid,
  setPaymentType,
  setSavedPrices,
  setServices,
} from '../../stateManagement/actions/salesActions';
import * as fromClients from '../../stateManagement/thunks/clientsThunk';
import * as fromItems from '../../stateManagement/thunks/itemsThunk';
import AddNewItemDialog from './AddNewItemDialog';

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { CollapsePaper } from '../../components/CollapsePaper';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import CurrencyPicker from '../../components/CurrencyPicker';
import EntityNavigator from '../../components/EntityNavigator';
import { InvoiceTypePicker } from '../../components/InvoiceTypePicker';
import OrderPDFDialog from '../../components/OrderPDFDialog';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import userService from '../../services/authority/userService';
import documentBlockService from '../../services/billing/documentBlockService';
import serviceService from '../../services/erp/serviceService';
import taxTypeService from '../../services/erp/taxTypeService';
import { ClientTypes } from '../../types/ClientTypes';
import { Currency } from '../../types/Currency';
import { DocumentBlockTypes } from '../../types/DocumentBlockTypes';
import { DocumentLanguage } from '../../types/DocumentLanguage';
import { EntityTypes } from '../../types/EntityTypes';
import { InvoiceTypes } from '../../types/InvoiceTypes';
import { formatCurrency } from '../../utils/valueFormatters';
import ClientCreate from '../crm/clients/ClientCreate';
import AddnewItemPackageDialog from './AddnewItemPackageDialog';
import EditItemDialog from './EditItemDialog';
import EditItemPackageDialog from './EditItemPackageDialog';
import SelectServiceDialog from './SelectServiceDialog';

export interface CartItem {
  id: number;
  itemId: number;
  amount: number;
  itemName: string;
  discount: number;
  itemNumber: string;
  sellPriceNet: number;
  sellPriceGross: number;
  taxTypeId: number;
  comment: string;
}
export interface invoiceInfo {
  customerName: string;
  customerPostalCode: string;
  customerCity: string;
  customerStreetAddress: string;
  customerTaxNumber: string;
  customerPhoneNumber: string;
  customerComment: string;
  customerEmailAddress: string;
  customerSendEmail: boolean;
}
export interface CartService {
  id: number;
  name: string;
  description: string;
  servicePriceNet: number;
  servicePriceGross: number;
  serviceCategoryId: number;
  unitOfQuantity: string;
  amount: number;
  discount: number;
}

export interface Item {
  itemId: number;
  itemName: string;
  availableAmount: number;
  itemNumber: string;
  barcode: string;
  qrCode: string;
  sellPriceNet: number;
  sellPriceGross: number;
  comment: string;
}

const SalesPage = () => {
  const {
    cart,
    services,
    discount,
    description,
    invoiceType,
    isElectronic,
    previousInvoiceType,
    originalCart,
    isBooking,
    bookings,
    isCopy,
    orderId,
    isReadonly,
    createInvoice,
    clients,
    paymentType,
    clientId,
    companyId,
    itemPackages,
    documentBlockId,
    invoiceInfo,
    addressId,
    paid,
    dueDate,
    fulfillmentDate,
    savedPrices,
  } = useSelector<any>((state) => state.sales) as any;
  const {
    items,
    warehouseId,
    warehouses,
    priceCategories,
    priceCategoryId,
    currency,
    conversionRate,
    invoiceLanguage,
  } = useSelector<any>((state) => state.items) as any;
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [priceCategoryChanged, setPriceCategoryChanged] =
    useState<boolean>(false);
  const [addItemDialogOpen, setAddItemDialogOpen] = useState<boolean>(false);
  const [addItemPackageDialogOpen, setAddItemPackageDialogOpen] =
    useState<boolean>(false);
  const [addItemPackageDialogId, setAddItemPackageDialogId] =
    useState<number>(0);
  const [orderPDFDialog, setOrderPDFDialog] = useState<any>({
    open: false,
    orderId: 0,
    values: {},
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  var [now, setNow] = useState<Date>(new Date());
  var client = clients.find((x) => x.id === clientId);

  const [editItemPackageDialog, setEditItemPackageDialog] = useState<any>({
    open: false,
    row: {
      id: 0,
      name: 'Termékcsomag ' + 0,
      itemNumber: '',
      amount: 1,
      discount: 0,
      sellPriceGross: 0,
      sellPriceNet: 0,
      taxTypeId: 1,
      comment: '',
      items: [],
      services: [],
    },
  });
  const [addServiceDialog, setAddServiceDialog] = useState<any>({
    open: false,
    serviceToEdit: null,
  });
  const [companies, setCompanies] = useState<any[]>([]);
  const [allServices, setAllServices] = useState<any[]>([]);
  const [invoicePrefixes, setInvoicePrefixes] = useState<any[]>([]);
  const userDistributor = useSelector((state: any) => state.userDistributor);
  const userPriceCategory = useSelector(
    (state: any) => state.userPriceCategory
  );
  const user = useSelector((state: any) => state.user.userInfo);
  const [taxes, setTaxes] = useState<any[]>([]);
  const paymentTypes = ['Készpénz', 'Bankkártya', 'Átutalás', 'Utánvét'];
  const [editItemDialogOpen, setEditItemDialogOpen] = useState({
    open: false,
    row: {
      itemId: 0,
      itemNumber: '',
      itemName: '',
      amount: 0,
      discount: 0,
      sellPriceGross: 0,
      sellPriceNet: 0,
      taxTypeId: 0,
      comment: '',
      isComplexItem: false,
      parts: [],
    },
  });

  const titleDescriptor = useFunctionDescriptor('SalesPage.title');

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);
  const readonly =
    isReadonly ||
    (orderId === 0
      ? !checkPermission(['OrderCreate'])
      : !checkPermission(['OrderEdit']));

  const getAvailableAmount = (itemId: number, cart: any) => {
    let warehouseAmount = 0;
    let found = items.find((x) => x.itemId === itemId);

    if (found) {
      if (found?.isComplexItem) {
        let partCount = found.parts?.map((x) => {
          let available = getAvailableAmount(x.partItemId, cart);

          return { count: Math.floor(available / x.partAmount) };
        }) ?? [{ count: 0 }];
        if (partCount.length > 0) {
          let minCount = Math.min(...partCount.map((x) => x.count));
          warehouseAmount = minCount;
        }
      } else {
        warehouseAmount = found.availableAmount;
      }
    }
    if (previousInvoiceType === InvoiceTypes.WayBill) {
      let found = originalCart.find((x) => x.itemId === itemId);
      if (found) {
        warehouseAmount += found.amount;
      }
    }
    if (cart?.length > 0) {
      let cartAmount = 0;

      if (!found?.isComplexItem) {
        let complexItemsInCart = cart.filter(
          (x) => items.find((y) => x.itemId === y.itemId)?.isComplexItem
        );
        if (complexItemsInCart.length > 0) {
          complexItemsInCart.forEach((complexItem) => {
            let complexItemParts = items.find(
              (x) => x.itemId === complexItem.itemId
            )?.parts;
            complexItemParts?.forEach((part) => {
              if (part.partItemId === itemId) {
                cartAmount += part.partAmount * complexItem.amount;
              }
            });
          });
        }
        cartAmount += cart
          .filter((x) => x.itemId === itemId)
          .reduce((acc, cur) => (acc += cur.amount), 0);
      }
      if (bookings?.length > 0) {
        let item = bookings.find((x) => x.itemId === itemId);
        if (item) {
          warehouseAmount += item.amount;
        }
      }
      return warehouseAmount - cartAmount;
    }

    return warehouseAmount;
  };

  const addCartItem = (item: CartItem) => {
    var found = cart.find((x) => x.itemId === item.itemId);
    if (found) {
      if (
        (invoiceType as InvoiceTypes) === InvoiceTypes.DepositInvoice ||
        getAvailableAmount(item.itemId, cart) >= item.amount
      ) {
        dispatch(
          replaceCartItem({
            cartItem: {
              ...found,
              amount: found.amount + item.amount,
            },
            oldId: item.itemId,
          })
        );
        setAddItemDialogOpen(false);
        return;
      }
    } else {
      if (
        item.itemId < 0 ||
        (invoiceType as InvoiceTypes) === InvoiceTypes.DepositInvoice ||
        getAvailableAmount(item.itemId, cart) >= item.amount
      ) {
        dispatch(addToCart({ ...item }));
        setAddItemDialogOpen(false);
        return;
      }
    }

    enqueueSnackbar('Nem áll rendelkezésre a kívánt mennyiség!', {
      variant: 'error',
    });
  };

  const handleBarcodeRead = (barcode) => {
    if (barcode.item) {
      if (!addItemPackageDialogOpen && !editItemPackageDialog.open) {
        addCartItem({
          ...barcode.item,
          amount: 1,
          discount: 0,
          taxTypeId: taxes[0].id,
          sellPriceNet: barcode.item?.net / conversionRate,
          sellPriceGross: barcode.item?.gross / conversionRate,
          comment: '',
        });
      }
    } else {
      enqueueSnackbar(
        'Ismeretlen árucikk, ellenőrizze hogy van-e kiválasztott árkategória / raktár',
        {
          variant: 'error',
        }
      );
    }
  };

  useBarcodeScanner(
    handleBarcodeRead,
    items,
    5,
    !addItemPackageDialogOpen && !editItemPackageDialog.open
  );

  useEffect(() => {
    setCompanies(
      clients
        .filter((x) => x.clientType === ClientTypes.Distributor)
        .map((x) => x)
    );
  }, [clients]);

  useEffect(() => {
    let type =
      invoiceType === InvoiceTypes.WayBill
        ? DocumentBlockTypes.Waybill
        : invoiceType === InvoiceTypes.Receipt
          ? DocumentBlockTypes.Receipt
          : invoiceType === InvoiceTypes.FeeColleciton
            ? DocumentBlockTypes.FeeCollection
            : invoiceType === InvoiceTypes.DepositInvoice
              ? DocumentBlockTypes.DepositInvoice
              : DocumentBlockTypes.Invoice;
    documentBlockService.list(type, user.userId).then((response) => {
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setDocumentBlocks(response.records);
        if (documentBlockId === 0) {
          dispatch(setDocumentBlockId(response.records[0]?.id ?? 0));
        }
      }
    });
  }, [invoiceType]);

  useEffect(() => {
    if (user.userId > 0) {
      userService.get(user.userId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          return enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
        setInvoicePrefixes(response.result?.invoicePrefixes?.split(';') ?? []);
      });
    }
  }, [user]);

  useEffect(() => {
    if (userDistributor.selectedDistributor > 0 && !(orderId > 0)) {
      dispatch(setCompanyId(userDistributor.selectedDistributor));
      dispatch(
        setInvoiceInfo({
          ...invoiceInfo,
          companyId: userDistributor.selectedDistributor,
        })
      );
    }
  }, [userDistributor]);

  useEffect(() => {
    if (userPriceCategory.selectedPriceCategoryId > 0 && !(orderId > 0)) {
      dispatch(setPriceCategoryId(userPriceCategory.selectedPriceCategoryId));
    }
  }, [userPriceCategory]);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        return enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
      setTaxes(response.records);
    });
  }, []);

  useEffect(() => {
    serviceService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        return enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
      setAllServices(response.records);
    });
  }, []);

  useEffect(() => {
    if (warehouseId > 0 && priceCategoryId > 0) {
      dispatch(fromItems.loadItems());
    }
  }, [warehouseId, priceCategoryId, invoiceType]);

  useEffect(() => {
    let statement = priceCategoryChanged;
    if (statement) {
      refreshPricing(false, true);
    }
    setPriceCategoryChanged(false);
  }, [items]);

  const refreshPricing = (isRollBack: boolean, isAuto: boolean = false) => {
    let itemList = isRollBack ? savedPrices.items : items;
    let serviceList = isRollBack ? savedPrices.services : allServices;

    if (!isRollBack && !isAuto) {
      dispatch(
        setSavedPrices({
          isPreview: true,
          services: services,
          itemPackages: itemPackages,
          items: cart.map((c) => ({
            ...c,
            net: c.sellPriceNet,
          })),
        })
      );
    } else {
      dispatch(
        setSavedPrices({
          isPreview: false,
          items: [],
          services: [],
          itemPackages: [],
        })
      );
    }

    if (cart && cart?.length > 0) {
      cart.map((x) => {
        let found = itemList.find((y) => y.itemId === x.itemId);
        if (found) {
          dispatch(
            replaceCartItem({
              cartItem: {
                ...x,
                sellPriceNet: found?.net / conversionRate,
                sellPriceGross:
                  ((found?.net ?? 0) *
                    (1 +
                      (taxes?.find((y) => y.id === x.taxTypeId)?.value ?? 27) /
                        100)) /
                  conversionRate,
              },
              oldId: found.itemId,
            })
          );
        }
      });
    }
    if (itemPackages && itemPackages.length > 0) {
      if (!isRollBack) {
        itemPackages.map((itemPackage) => {
          let sellPriceGross = 0;
          let sellPriceNet = 0;
          let newItems = itemPackage.items?.map((item) => {
            let found = items.find((y) => y.itemId === item.itemId);
            let gross =
              (found?.net ?? 0) *
              (1 +
                (taxes?.find((y) => y.id === itemPackage.taxTypeId)?.value ??
                  27) /
                  100);
            sellPriceGross +=
              ((gross * (item?.amount ?? 0)) / conversionRate) *
              (item?.discount !== 0 ? 1 - item?.discount / 100 : 1);
            sellPriceNet +=
              (((found?.net ?? 0) * (item?.amount ?? 0)) / conversionRate) *
              (item?.discount !== 0 ? 1 - item?.discount / 100 : 1);
            return {
              ...item,
              sellPriceNet: (found?.net ?? 0) / conversionRate,
              sellPriceGross: gross / conversionRate,
            };
          });
          let newServices = itemPackage.services?.map((service) => {
            let found = allServices.find((s) => s.id === service?.id);
            let gross =
              (found?.servicePriceNet ?? 0) *
              (1 +
                (taxes?.find((y) => y.id === itemPackage.taxTypeId)?.value ??
                  27) /
                  100);
            sellPriceGross +=
              ((gross * (service?.amount ?? 0)) / conversionRate) *
              (service?.discount !== 0 ? 1 - service?.discount / 100 : 1);
            sellPriceNet +=
              (((found?.servicePriceNet ?? 0) * (service?.amount ?? 0)) /
                conversionRate) *
              (service?.discount !== 0 ? 1 - service?.discount / 100 : 1);
            return {
              ...service,
              servicePriceNet: (found?.servicePriceNet ?? 0) / conversionRate,
              servicePriceGross: gross / conversionRate,
            };
          });
          dispatch(
            replaceItemPackage({
              itemPackage: {
                ...itemPackage,
                items: newItems,
                services: newServices,
                sellPriceGross: sellPriceGross,
                sellPriceNet: sellPriceNet,
              },
              oldId: itemPackage.id,
            })
          );
        });
      } else {
        dispatch(setItemPackages(savedPrices.itemPackages));
      }
    }
    if (services && services.length > 0) {
      let newServices = services?.map((service) => {
        let found = serviceList.find((s) => s.id === service.id);
        return {
          ...service,
          servicePriceNet: found?.servicePriceNet ?? 0,
          servicePriceGross: found?.servicePriceGross ?? 0,
        };
      });
      dispatch(setServices(newServices));
    }
  };

  const handleCurrencyChange = (
    previousConversionRate: number,
    conversionRate: number
  ) => {
    if (items && items.length > 0) {
      cart.map((x) => {
        let prevSellPriceGross =
          (x.sellPriceGross * previousConversionRate) / conversionRate;
        let prevSellPriceNet =
          (x.sellPriceNet * previousConversionRate) / conversionRate;
        dispatch(
          replaceCartItem({
            cartItem: {
              ...x,
              sellPriceNet: prevSellPriceNet,
              sellPriceGross: prevSellPriceGross,
            },
            oldId: x.itemId,
          })
        );
      });
    }
    if (itemPackages && itemPackages.length > 0) {
      itemPackages.map((itemPackage) => {
        let sellPriceGross = 0;
        let sellPriceNet = 0;
        let newItems = itemPackage.items?.map((item) => {
          let prevSellPriceGross =
            (item.sellPriceGross * previousConversionRate) / conversionRate;
          let prevSellPriceNet =
            (item.sellPriceNet * previousConversionRate) / conversionRate;
          sellPriceGross += (prevSellPriceGross ?? 0) * (item?.amount ?? 0);
          sellPriceNet += (prevSellPriceNet ?? 0) * (item?.amount ?? 0);

          return {
            ...item,
            sellPriceNet: prevSellPriceNet ?? 0,
            sellPriceGross: prevSellPriceGross ?? 0,
          };
        });
        let newServices = itemPackage.services?.map((service) => {
          let prevSellPriceGross =
            (service.servicePriceGross * previousConversionRate) /
            conversionRate;
          let prevSellPriceNet =
            (service.servicePriceNet * previousConversionRate) / conversionRate;
          sellPriceGross += (prevSellPriceGross ?? 0) * (service?.amount ?? 0);
          sellPriceNet += (prevSellPriceNet ?? 0) * (service?.amount ?? 0);
          return {
            ...service,
            servicePriceNet: prevSellPriceNet ?? 0,
            servicePriceGross: prevSellPriceGross ?? 0,
          };
        });
        dispatch(
          replaceItemPackage({
            itemPackage: {
              ...itemPackage,
              items: newItems,
              services: newServices,
              sellPriceGross: sellPriceGross,
              sellPriceNet: sellPriceNet,
            },
            oldId: itemPackage.id,
          })
        );
      });
    }
    if (services && services.length > 0) {
      let newServices = services?.map((service) => {
        let prevSellPriceGross =
          (service.servicePriceGross * previousConversionRate) / conversionRate;
        let prevSellPriceNet =
          (service.servicePriceNet * previousConversionRate) / conversionRate;
        return {
          ...service,
          servicePriceNet: prevSellPriceNet ?? 0,
          servicePriceGross: prevSellPriceGross ?? 0,
        };
      });
      dispatch(setServices(newServices));
    }
  };

  useEffect(() => {
    dispatch(fromItems.loadWarehouses());
    dispatch(fromItems.loadPriceCategories());
    dispatch(fromClients.loadClients());
    if (!(orderId > 0) && !isCopy) {
      let warehouseId = parseInt(
        window.localStorage.getItem('sell.warehouseId')
      );
      if (!isNaN(warehouseId)) {
        dispatch(setWarehouseId(warehouseId));
      }
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'itemNumber',
      headerName: 'Cikkszám',
      flex: 100,
      editable: false,
    },
    {
      field: 'itemName',
      headerName: 'Termék',
      flex: 100,
      editable: false,
    },
    {
      field: 'sellPriceNet',
      headerName: 'Nettó egységár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value, currency),
      editable: false,
    },
    {
      field: 'sellPriceGross',
      headerName: 'Bruttó egységár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value, currency),
      editable: false,
    },
    {
      field: 'discount',
      headerName: 'Engedmény',
      flex: 100,
      editable: false,
      valueFormatter: (params) => params.value + '%',
    },
    {
      field: 'amount',
      headerName: 'Mennyiség',
      editable: false,
      flex: 100,
    },
    {
      field: 'grossPrice',
      headerName: 'Bruttó ár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value, currency),
      editable: false,
    },
    {
      field: 'taxTypeId',
      headerName: 'ÁFA',
      flex: 100,
      editable: false,
      valueGetter: (params) => {
        const taxTypeName =
          taxes.find((x) => x.id === params.row.taxTypeId)?.name ?? '';
        const formattedTaxTypeName = isNaN(Number(taxTypeName))
          ? taxTypeName
          : `${taxTypeName}%`;
        return formattedTaxTypeName;
      },
    },
    {
      field: 'comment',
      headerName: 'Megjegyzés',
      editable: false,
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 100,
      disableExport: true,
      getActions: (params: GridRowParams, color: any) => {
        var actions = [];
        if (
          getAvailableAmount(params.row.itemId, cart) + params.row.amount <
          params.row.amount
        ) {
          actions.push(
            <GridActionsCellItem
              color={'error'}
              icon={
                <Tooltip title="Nincs elegendő raktáron lévő mennyiség">
                  <Warning />
                </Tooltip>
              }
              label={'Szerkesztés'}
              onClick={() => {}}
            />
          );
        }

        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Szerkesztés">
                <EditIcon />
              </Tooltip>
            }
            label={'Szerkesztés'}
            disabled={isReadonly && !params.row.isItemPackage}
            onClick={() => {
              params.row.isService
                ? setAddServiceDialog({
                    open: true,
                    serviceToEdit:
                      services.find((x) => x.id === params.row.itemId) ?? null,
                  })
                : params.row.isItemPackage
                  ? setEditItemPackageDialog({
                      open: true,
                      row: params.row,
                    })
                  : setEditItemDialogOpen({
                      open: true,
                      row: {
                        itemId: params.row.itemId,
                        itemName: params.row.itemName,
                        amount: params.row.amount,
                        discount: params.row.discount,
                        itemNumber: params.row.itemNumber,
                        sellPriceGross: params.row.sellPriceGross,
                        sellPriceNet: params.row.sellPriceNet,
                        taxTypeId: params.row.taxTypeId,
                        comment: params.row.comment ?? '',
                        isComplexItem: params.row?.isComplexItem,
                        parts: params.row.parts,
                      },
                    });
            }}
          />
        );

        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Törlés">
                <DeleteIcon />
              </Tooltip>
            }
            disabled={readonly}
            label="Delete"
            onClick={() =>
              setParams({
                open: true,
                name: 'Biztosan törölni szeretné?',
                onConfirm: async () => {
                  return params.row.isService
                    ? dispatch(removeFromServices(params.row.itemId))
                    : params.row.isItemPackage
                      ? dispatch(removeFromItemPackages(params.row.itemId))
                      : handleDelete(params.row.itemId);
                },
              })
            }
          />
        );

        return actions;
      },
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    dispatch(removeFromCart(id));
  };

  const handleSell = (booking: boolean = false) => {
    dispatch({ type: 'SHOW_QUERY' });

    orderService
      .save({
        id: orderId,
        items: cart,
        itemPackages: itemPackages,
        warehouseId: warehouseId,
        description,
        discount,
        isBooking: booking,
        priceCategoryId,
        createInvoice: booking ? false : createInvoice,
        invoiceInfo,
        addressId,
        paymentType,
        companyId,
        clientId: clientId === 0 ? null : clientId,
        services: services,
        invoiceType,
        isElectronic,
        currency,
        dueDate,
        fulfillmentDate,
        paid,
        conversionRate,
        invoiceLanguage,
        documentBlockId,
        invoiceNumberPrefix:
          documentBlockId > 0
            ? (documentBlocks.find((x) => x.id === documentBlockId)?.prefix ??
              '')
            : '',
      })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres tranzakció!', {
            variant: 'success',
          });
          if (response.result && response.result.content) {
            var byte = base64ToArrayBuffer(response.result.content);
            var blob = new Blob([byte], {
              type: response.result.contentType,
            });
            window.open(URL.createObjectURL(blob), '_blank');
          }

          dispatch(reset());
          dispatch(fromItems.loadItems());
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setIsSubmitting(false);
      });
  };

  function base64ToArrayBuffer(_base64Str) {
    var binaryString = window.atob(_base64Str);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const processEditItem = (cartItem: CartItem) => {
    if (cartItem.itemId < 0 && !isNaN(cartItem.sellPriceGross)) {
      let vat = taxes.find((x) => x.id === cartItem.taxTypeId)?.value ?? 27;
      cartItem.sellPriceNet = cartItem.sellPriceGross / (1 + vat / 100);
    }
    dispatch(replaceCartItem({ cartItem, oldId: cartItem.itemId }));
  };

  const getItemPriceGross = (itemId: number) => {
    return cart.find((x) => x.itemId === itemId)?.sellPriceGross ?? 0;
  };

  const getItemPriceNet = (itemId: number, taxTypeId: number) => {
    return itemId < 0
      ? (cart.find((x) => x.itemId === itemId)?.sellPriceNet ?? 0)
      : getItemPriceGross(itemId) /
          (1 + (taxes.find((x) => x.id === taxTypeId)?.value ?? 27) / 100);
  };

  const itemsAndServices = [
    ...cart.map((x) => ({
      ...x,
      isService: false,
      isItemPackage: false,
      comment: x.comment ?? '',
      itemNumber:
        items.find((y) => y.itemId === x.itemId)?.itemNumber ??
        x.itemNumber ??
        '',
      name:
        items.find((y) => y.itemId === x.itemId)?.itemName ?? x.itemName ?? '',
      sellPriceGross:
        x.itemId > 0 ? x.sellPriceGross : getItemPriceGross(x.itemId),
      sellPriceNet:
        x.itemId > 0 ? x.sellPriceNet : getItemPriceNet(x.itemId, x.taxTypeId),
      grossPrice:
        (x.itemId > 0 ? x.sellPriceGross : getItemPriceGross(x.itemId)) *
        x.amount *
        (x.discount !== 0 ? 1 - x.discount / 100 : 1) *
        (discount !== 0 ? 1 - discount / 100 : 1),
    })),
    ...services.map((y) => ({
      isService: true,
      isItemPackage: false,
      itemId: y.id,
      itemName: y.name,
      comment: y.comment,
      amount: y.amount,
      discount: y.discount,
      itemNumber: '',
      sellPriceGross: y.servicePriceGross,
      sellPriceNet: y.servicePriceNet,
      grossPrice:
        y.servicePriceGross *
        y.amount *
        (y.discount !== 0 ? 1 - y.discount / 100 : 1) *
        (discount !== 0 ? 1 - discount / 100 : 1),
      taxTypeId: y.taxTypeId,
    })),
    ...itemPackages.map((x) => ({
      ...x,
      isService: false,
      isItemPackage: true,
      itemId: x.id,
      comment: x.comment ?? '',
      itemNumber: '',
      itemName: x.name,
      sellPriceGross: x.sellPriceGross,
      sellPriceNet: x.sellPriceNet,
      grossPrice:
        x.sellPriceGross *
        x.amount *
        (x.discount !== 0 ? 1 - x.discount / 100 : 1) *
        (discount !== 0 ? 1 - discount / 100 : 1),
    })),
  ];

  const calculatefinalPrice = (isGross: boolean) => {
    let itemSum = 0;
    let serviceSum = 0;
    let itemPackageSum = 0;

    if (cart && cart.length > 0) {
      itemSum = cart.reduce(
        (sum, item) =>
          sum +
          (isGross ? item.sellPriceGross : item.sellPriceNet) *
            item.amount *
            (item.discount !== 0 ? 1 - item.discount / 100 : 1) *
            (discount !== 0 ? 1 - discount / 100 : 1),
        0
      );
    }

    if (services && services.length > 0) {
      serviceSum = services.reduce(
        (sum, service) =>
          sum +
          (isGross ? service.servicePriceGross : service.servicePriceNet) *
            (service.quantity > 0 ? service.quantity : service.amount) *
            (service.discount !== 0 ? 1 - service.discount / 100 : 1) *
            (discount !== 0 ? 1 - discount / 100 : 1),
        0
      );
    }

    if (itemPackages && itemPackages.length > 0) {
      itemPackageSum = itemPackages.reduce(
        (sum, itemPackage) =>
          sum +
          (isGross ? itemPackage.sellPriceGross : itemPackage.sellPriceNet) *
            itemPackage.amount *
            (itemPackage.discount !== 0 ? 1 - itemPackage.discount / 100 : 1) *
            (discount !== 0 ? 1 - discount / 100 : 1),
        0
      );
    }
    return itemSum + serviceSum + itemPackageSum;
  };

  const handleClientChange = (clientId: number, clients: any) => {
    let foundClient = clients.find((x) => x.id === clientId);
    let found = foundClient?.addresses?.find((g) => g.isBillingAddress);
    let firstAddress = foundClient?.addresses[0];
    if (found) {
      dispatch(setAddressId(found.id));
      dispatch(
        setInvoiceInfo({
          ...invoiceInfo,
          customerPostalCode: found.postalCode,
          customerCity: found.city,
          customerStreetAddress: `${found.street} ${found.houseNumber}`,
          clientId: clientId,
          customerName: foundClient?.clientName,
          customerTaxNumber: foundClient?.taxNumber,
          customerPhoneNumber: foundClient?.contact?.phone,
          customerEmailAddress: foundClient?.contact?.email,
        })
      );
    } else if (firstAddress) {
      dispatch(setAddressId(firstAddress.id));
      dispatch(
        setInvoiceInfo({
          ...invoiceInfo,
          customerPostalCode: firstAddress.postalCode,
          customerCity: firstAddress.city,
          customerStreetAddress: `${firstAddress.street} ${firstAddress.houseNumber}`,
          clientId: clientId,
          customerName: foundClient?.clientName,
          customerTaxNumber: foundClient?.taxNumber,
          customerPhoneNumber: foundClient?.contact?.phone,
          customerEmailAddress: foundClient?.contact?.email,
        })
      );
    } else {
      dispatch(setAddressId(0));
      dispatch(
        setInvoiceInfo({
          ...invoiceInfo,
          customerPostalCode: '',
          customerCity: '',
          customerStreetAddress: ``,
          clientId: clientId,
          customerName: foundClient?.clientName,
          customerTaxNumber: foundClient?.taxNumber,
          customerPhoneNumber: foundClient?.contact?.phone,
          customerEmailAddress: foundClient?.contact?.email,
        })
      );
    }
  };

  return (
    <Grid container sx={{ backgroundColor: '#EFEFEF' }} p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h2>
            {isBooking
              ? `Foglalás véglegesítése (${orderId})`
              : `Megrendelés szerkesztése ${
                  orderId === 0 ? '' : '(' + orderId + ')'
                }`}
            {titleDescriptor}
          </h2>
        </Grid>
        <Grid item xs={12}>
          <CollapsePaper
            title={
              <Grid container>
                <Grid item>
                  <Typography
                    textAlign="left"
                    fontWeight={'bold'}
                    fontSize={20}
                    sx={{ mt: 2 }}
                  >
                    Megrendelés adatai:
                  </Typography>{' '}
                </Grid>
              </Grid>
            }
            collapsed={false}
            children={
              <Grid container p={2} justifyContent={'center'} spacing={2}>
                <Grid item xs={12} p={2}>
                  <InvoiceTypePicker
                    collapsed={true}
                    invoiceType={invoiceType}
                    isElectronic={isElectronic}
                    setIsElectronic={(value: boolean) => {
                      dispatch(setIsElectronic(value));
                    }}
                    disabledTypes={
                      previousInvoiceType === null
                        ? []
                        : previousInvoiceType === InvoiceTypes.FeeColleciton
                          ? [previousInvoiceType, InvoiceTypes.WayBill]
                          : [
                              InvoiceTypes.FeeColleciton,
                              InvoiceTypes.DepositInvoice,
                              InvoiceTypes.WayBill,
                            ]
                    }
                    setInvoiceType={(value: InvoiceTypes) => {
                      dispatch(setInvoiceType(value as InvoiceTypes));
                      dispatch(
                        setIncludeUnpriced(
                          (value as InvoiceTypes) ===
                            InvoiceTypes.DepositInvoice
                            ? true
                            : false
                        )
                      );
                      if (value === InvoiceTypes.Receipt) {
                        dispatch(setClientId(0));
                      }
                      dispatch(setDocumentBlockId(0));
                    }}
                  />
                </Grid>
                {invoiceType && (
                  <>
                    {' '}
                    <Grid item xs={12} md={6} sx={{ px: { md: 2 }, p: 2 }}>
                      <Autocomplete
                        disablePortal
                        id="priceCategory"
                        disabled={readonly}
                        value={priceCategoryId}
                        onChange={(event, value) => {
                          dispatch(setPriceCategoryId(value));
                          window.localStorage.setItem(
                            'sell.priceCategoryId',
                            value
                          );
                          setPriceCategoryChanged(true);
                        }}
                        getOptionLabel={(option) =>
                          priceCategories.find((g) => g.id === option)?.name ??
                          ''
                        }
                        options={priceCategories.map((g) => g.id)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth label="Árcsoport" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ px: { md: 2 }, p: 2 }}>
                      <Autocomplete
                        disablePortal
                        id="warehouseId"
                        disabled={orderId !== 0 || readonly}
                        value={warehouseId}
                        onChange={(event, value) => {
                          dispatch(setWarehouseId(value));
                          window.localStorage.setItem(
                            'sell.warehouseId',
                            value
                          );
                          dispatch(reset());
                        }}
                        getOptionLabel={(option) =>
                          warehouses.find((g) => g.id === option)?.name ?? ''
                        }
                        options={warehouses.map((g) => g.id)}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth label="Raktár" />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            }
          />
        </Grid>
        {invoiceType && (
          <>
            {invoiceType !== InvoiceTypes.Receipt && (
              <Grid item xs={12} md={6}>
                <CollapsePaper
                  title={
                    <Grid container>
                      <Grid item>
                        <Typography
                          textAlign="left"
                          fontWeight={'bold'}
                          fontSize={20}
                          sx={{ mt: 2 }}
                        >
                          Ügyfél adatai:
                        </Typography>{' '}
                      </Grid>
                      <Grid item>
                        <EntityNavigator
                          entityType={EntityTypes.ClientEdit}
                          entityId={clientId}
                          value={'Szerkesztés'}
                          showIcon
                          onSave={(result) => {
                            dispatch(fromClients.loadClients());
                          }}
                        />
                      </Grid>
                    </Grid>
                  }
                  collapsed={false}
                  children={
                    <Grid container p={2} justifyContent={'center'} spacing={2}>
                      <Grid item xs={12}>
                        <CreateEntityDecorator
                          hideAdd={
                            clientId > 0 || checkPermission(['ClientAdd'])
                          }
                          AutocompleteComponent={
                            <Autocomplete
                              disablePortal
                              id="clientId"
                              disabled={
                                !checkPermission(['ClientView']) || readonly
                              }
                              value={clientId}
                              onChange={(event, value) => {
                                dispatch(setClientId(value));
                                handleClientChange(value, clients);
                              }}
                              getOptionLabel={(option) => {
                                if (option === null) {
                                  return 'Mindegy';
                                }
                                var found = clients.find(
                                  (g) => g.id === option
                                );
                                if (found) {
                                  if (!found.isPrivatePerson) {
                                    return `${found.id}. ${found.companyName} (${found.taxNumber})`;
                                  }
                                  return `${found.id}. ${found.name.fullName} (${found.companyName},${found.taxNumber})}`;
                                } else return '';
                              }}
                              options={[null, ...clients.map((g) => g.id)]}
                              filterOptions={(options, params) => {
                                // Convert the search term to lowercase for case-insensitive search
                                const searchTermLower =
                                  params.inputValue.toLowerCase();

                                // Perform a fuzzy search by splitting the search term into tokens
                                const searchTokens =
                                  searchTermLower.split(/\s+/);

                                // Filter items based on the search tokens
                                const filteredItems = clients.filter(
                                  (client) => {
                                    // Convert the item name to lowercase
                                    let name = '';
                                    if (!client?.isPrivatePerson) {
                                      name = `${client?.id}. ${client?.companyName} (${client?.taxNumber})`;
                                    } else {
                                      name = `${client?.id}. ${client?.name.fullName} (${client?.companyName},${client?.taxNumber})}`;
                                    }
                                    const itemNameLower = name.toLowerCase();

                                    // Check if each token is present in the item name
                                    return searchTokens.every((token) =>
                                      itemNameLower.includes(token)
                                    );
                                  }
                                );

                                return [
                                  null,
                                  ...filteredItems
                                    .sort((a, b) => {
                                      let nameA = '';
                                      let nameB = '';

                                      if (!a?.isPrivatePerson) {
                                        nameA = `${a?.companyName} (${a?.taxNumber})`;
                                      } else {
                                        nameA = `${a?.name.fullName} (${a?.companyName},${a?.taxNumber})}`;
                                      }

                                      if (!b?.isPrivatePerson) {
                                        nameB = `${b?.companyName} (${b?.taxNumber})`;
                                      } else {
                                        nameB = `${b?.name.fullName} (${b?.companyName},${b?.taxNumber})}`;
                                      }

                                      const nameALower = nameA.toLowerCase();
                                      const nameBLower = nameB.toLowerCase();

                                      // Sort based on whether the name starts with the first character of the search term
                                      const startsWithA = nameALower.startsWith(
                                        searchTermLower.charAt(0)
                                      );
                                      const startsWithB = nameBLower.startsWith(
                                        searchTermLower.charAt(0)
                                      );

                                      if (startsWithA && !startsWithB)
                                        return -1;
                                      if (!startsWithA && startsWithB) return 1;

                                      return nameALower.localeCompare(
                                        nameBLower
                                      );
                                    })
                                    .map((client) => client?.id),
                                ];
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Ügyfél"
                                />
                              )}
                            />
                          }
                          onSave={(client) => {
                            dispatch(fromClients.loadClients());

                            dispatch(setClientId(client?.id));
                            let found = client?.addresses?.find(
                              (g) => g.isBillingAddress
                            );
                            dispatch(
                              setInvoiceInfo({
                                ...invoiceInfo,
                                customerPostalCode: found.postalCode,
                                customerCity: found.city,
                                customerStreetAddress: `${found.street} ${found.houseNumber}`,
                                clientId: client?.id,
                                customerName: client?.clientName,
                                customerTaxNumber: client?.taxNumber,
                                customerPhoneNumber: client?.contact?.phone,
                                customerEmailAddress: client?.contact?.email,
                              })
                            );
                            dispatch(setAddressId(found.id));
                          }}
                          CreateEntityComponent={ClientCreate}
                        />
                      </Grid>
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={6}>
                          <Autocomplete
                            disablePortal
                            id="phone"
                            value={
                              client?.contact?.phone
                                ? client?.contact?.phone.split(';')
                                : []
                            }
                            multiple
                            freeSolo
                            disabled={readonly}
                            autoSelect
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Telefonszám"
                              />
                            )}
                            options={[]}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            disablePortal
                            id="email"
                            value={
                              client?.contact?.email
                                ? client?.contact?.email.split(';')
                                : []
                            }
                            multiple
                            freeSolo
                            disabled={readonly}
                            autoSelect
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="E-mail cím"
                              />
                            )}
                            options={[]}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Adószám"
                          value={client?.taxNumber ?? ''}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          id="billingAddressId"
                          value={addressId}
                          disabled={readonly}
                          onChange={(event, value) => {
                            dispatch(setAddressId(value));
                            let found = client?.addresses.find(
                              (g) => g.id === value
                            );
                            if (found) {
                              dispatch(
                                setInvoiceInfo({
                                  ...invoiceInfo,
                                  customerPostalCode: found.postalCode,
                                  customerCity: found.city,
                                  customerStreetAddress: `${found.street} ${found.houseNumber}`,
                                })
                              );
                            }
                          }}
                          getOptionLabel={(option) =>
                            client?.addresses.find((g) => g.id === option)
                              ?.fullAddress ?? ''
                          }
                          options={client?.addresses?.map((g) => g.id) ?? []}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Számlázási cím"
                              required
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Megjegyzés"
                          value={client?.comment}
                          disabled
                          multiline
                          minRows={2}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      {client?.hasSpecialNeeds && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Speciális igények"
                            value={client?.specialNeeds}
                            disabled
                            multiline
                            minRows={2}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          label="Hidden Field"
                          variant="outlined"
                          sx={{ visibility: 'hidden' }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Hidden Field"
                          variant="outlined"
                          sx={{ visibility: 'hidden' }}
                        />
                      </Grid>
                      {!client?.hasSpecialNeeds && (
                        <Grid item xs={12}>
                          <TextField
                            label="Hidden Field"
                            variant="outlined"
                            sx={{ visibility: 'hidden' }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <CollapsePaper
                title={
                  <Typography
                    textAlign="left"
                    fontWeight={'bold'}
                    fontSize={20}
                    sx={{ mt: 2 }}
                  >
                    Számla adatai:
                  </Typography>
                }
                collapsed={false}
                children={
                  <Grid container p={2} spacing={2}>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        id="serviceProviderId"
                        value={companyId}
                        disabled={readonly}
                        onChange={(event, value) => {
                          dispatch(setCompanyId(value));
                        }}
                        getOptionLabel={(option) =>
                          clients?.find((g) => g.id === option)?.companyName ??
                          ''
                        }
                        options={clients
                          ?.filter(
                            (x) => x.clientType === ClientTypes.Distributor
                          )
                          ?.map((g) => g.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            label="Számlakibocsátó"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        id="documentBlockId"
                        value={documentBlockId}
                        disabled={readonly}
                        onChange={(event, value) => {
                          dispatch(setDocumentBlockId(value));
                          let found = documentBlocks.find(
                            (x) => x.id === value
                          );
                          if (found) {
                            dispatch(
                              setInvoiceInfo({
                                ...invoiceInfo,
                                invoiceNumberPrefix: found.prefix,
                              })
                            );
                          }
                        }}
                        getOptionLabel={(option) =>
                          documentBlocks?.find((g) => g.id === option)?.name ??
                          ''
                        }
                        options={documentBlocks?.map((g) => g.id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            label="Bizonylattömb"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        disablePortal
                        id="paymentMethod"
                        value={paymentType}
                        disabled={readonly}
                        onChange={(event, value) => {
                          dispatch(setPaymentType(value));

                          if (value === 'Átutalás' || value === 'Utánvét') {
                            dispatch(
                              setDueDate(moment(now).add(8, 'days').toDate())
                            );
                            dispatch(setPaid(false));
                          } else {
                            dispatch(setDueDate(now));
                            dispatch(setPaid(true));
                          }
                        }}
                        options={paymentTypes?.map((g) => g)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            label="Fizetési mód"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={paid}
                            disabled={readonly}
                            onChange={(e, checked) => {
                              dispatch(setPaid(checked));
                            }}
                            color="primary"
                          />
                        }
                        label="Kifizetve"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <DatePicker
                        label="Teljesítés dátuma"
                        value={fulfillmentDate}
                        disabled={readonly}
                        onChange={(date) => {
                          dispatch(setFulfillmentDate(date));
                        }}
                        renderInput={(props) => <TextField {...props} />}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <DatePicker
                        label="Fizetési határidő"
                        value={dueDate}
                        disabled={readonly || paymentType !== 'Átutalás'}
                        onChange={(date) => {
                          dispatch(setDueDate(date));
                        }}
                        renderInput={(props) => <TextField {...props} />}
                      />
                      <Button
                        disabled={readonly || paymentType !== 'Átutalás'}
                        onClick={() => {
                          dispatch(
                            setDueDate(
                              moment(new Date()).add(8, 'days').toDate()
                            )
                          );
                        }}
                      >
                        +8
                      </Button>
                      <Button
                        disabled={readonly || paymentType !== 'Átutalás'}
                        onClick={() => {
                          dispatch(
                            setDueDate(
                              moment(new Date()).add(15, 'days').toDate()
                            )
                          );
                        }}
                      >
                        +15
                      </Button>
                      <Button
                        disabled={readonly || paymentType !== 'Átutalás'}
                        onClick={() => {
                          dispatch(
                            setDueDate(
                              moment(new Date()).add(30, 'days').toDate()
                            )
                          );
                        }}
                      >
                        +30
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Kedvezmény"
                        disabled={readonly}
                        value={discount}
                        type="number"
                        onChange={(e) => {
                          let discount = parseFloat(e.target.value);
                          if (isNaN(discount)) discount = 0;

                          if (discount >= 0 && discount <= 100) {
                            dispatch(setDiscount(discount));
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CurrencyPicker
                        currency={currency}
                        setCurrency={(value: Currency) => {
                          dispatch(setCurrency(value));
                        }}
                        conversionRate={conversionRate}
                        readonly={readonly}
                        setConversionRate={(value: number) => {
                          handleCurrencyChange(conversionRate, value);
                          dispatch(setConversionRate(value));
                        }}
                        localStorageKey="SalesPage"
                        language={invoiceLanguage}
                        setLanguage={(value: DocumentLanguage) => {
                          dispatch(setInvoiceLanguage(value));
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Megjegyzés"
                        value={description}
                        disabled={readonly}
                        onChange={(e) => {
                          dispatch(setDescription(e.target.value));
                          dispatch(
                            setInvoiceInfo({
                              ...invoiceInfo,
                              customerComment: e.target.value,
                            })
                          );
                        }}
                        multiline
                        minRows={2}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <CollapsePaper
                title={
                  <Typography
                    textAlign="left"
                    fontWeight={'bold'}
                    fontSize={20}
                    sx={{ mt: 2 }}
                  >
                    Termékek:
                  </Typography>
                }
                collapsed={false}
                children={
                  <Grid container p={2} spacing={2}>
                    <Grid item xs={12}>
                      {!readonly && (
                        <Grid
                          container
                          item
                          xs={12}
                          p={2}
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Grid item textAlign={'left'}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={savedPrices?.isPreview}
                                    onChange={(e, checked) =>
                                      refreshPricing(!checked)
                                    }
                                    color="primary"
                                  />
                                }
                                label="Friss árak használata"
                              />
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid item container>
                              <Grid item>
                                <Grid item textAlign={'right'}>
                                  <Tooltip title="Szolgáltatás hozzáadása">
                                    <IconButton
                                      color="primary"
                                      disabled={readonly}
                                      onClick={() =>
                                        setAddServiceDialog({
                                          open: true,
                                          serviceToEdit: null,
                                        })
                                      }
                                    >
                                      <PersonAddAlt1Icon />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <SelectServiceDialog
                                  services={allServices}
                                  serviceToEdit={addServiceDialog.serviceToEdit}
                                  open={addServiceDialog.open}
                                  taxes={taxes}
                                  setOpen={(open) => {
                                    setAddServiceDialog({
                                      open,
                                      serviceToEdit: null,
                                    });
                                  }}
                                  conversionRate={conversionRate}
                                  currency={currency}
                                  addToCart={(service) => {
                                    let found = services.find(
                                      (x) => x.id === service.id
                                    );
                                    if (found) {
                                      dispatch(removeFromServices(service.id));
                                      dispatch(addToServices({ ...service }));
                                    } else {
                                      dispatch(addToServices({ ...service }));
                                    }
                                    setAddServiceDialog({
                                      open: false,
                                      serviceToEdit: null,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Tooltip title="Termék hozzáadása">
                                  <IconButton
                                    color="primary"
                                    onClick={() => setAddItemDialogOpen(true)}
                                  >
                                    <AddIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip title="Egyedi tétel hozzáadása">
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      let id =
                                        cart.map((x) => x.orderId < 0).length +
                                        1;
                                      dispatch(
                                        addToCart({
                                          id: -id,
                                          itemId: -id,
                                          itemName: 'Gyüjtő ' + id,
                                          itemNumber:
                                            'A-' +
                                            id.toString().padStart(3, '0'),
                                          amount: 1,
                                          discount: 0,
                                          sellPriceNet: 0,
                                          sellPriceGross: 0,
                                          taxTypeId: taxes[0].id,
                                          comment: '',
                                        })
                                      );
                                    }}
                                  >
                                    <Create />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip title="Termékcsomag hozzáadása">
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      setAddItemPackageDialogId(
                                        itemPackages.length + 1
                                      );
                                      setAddItemPackageDialogOpen(true);
                                    }}
                                  >
                                    <Inventory2Icon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <DataList
                          rows={itemsAndServices}
                          localStorageKey={'SalesPage'}
                          columns={columns}
                          getRowId={(row) =>
                            `${row.isService}-${row.itemId}-${row.isItemPackage}`
                          }
                          isCellEditable={(params) => {
                            return (
                              params.row.itemId < 0 ||
                              params.field === 'amount' ||
                              params.field === 'discount'
                            );
                          }}
                          exportFields={[
                            'itemName',
                            'itemNumber',
                            'sellPriceNet',
                            'sellPriceGross',
                            'amount',
                            'discount',
                          ]}
                        />
                        <AddnewItemPackageDialog
                          open={addItemPackageDialogOpen}
                          setOpen={setAddItemPackageDialogOpen}
                          getAvailableAmount={getAvailableAmount}
                          items={items}
                          id={addItemPackageDialogId}
                          disableAmountCheck={
                            invoiceType === InvoiceTypes.DepositInvoice
                          }
                          itemPackages={itemPackages}
                          cart={[
                            ...cart,
                            ...itemPackages.map((x) => x.items).flat(),
                          ]}
                          taxes={taxes}
                          conversionRate={conversionRate}
                          currency={currency}
                          allServices={allServices}
                          discount={discount}
                          selectUnpriced={checkPermission(['TakeBackItems'])}
                          addToCart={(itemPackage: any) => {
                            dispatch(addToItemPackages({ ...itemPackage }));
                            setAddItemPackageDialogOpen(false);
                          }}
                          canOverrideGrossPrice={checkPermission([
                            'CreateOrderWithCustomPrice',
                          ])}
                        />
                        <EditItemPackageDialog
                          open={editItemPackageDialog.open}
                          setOpen={setEditItemPackageDialog}
                          getAvailableAmount={getAvailableAmount}
                          items={items}
                          disableAmountCheck={
                            invoiceType === InvoiceTypes.DepositInvoice
                          }
                          readonly={readonly}
                          itemPackages={itemPackages}
                          taxes={taxes}
                          cart={[
                            ...cart,
                            ...itemPackages
                              .filter(
                                (g) => g.id !== editItemPackageDialog.row.id
                              )
                              .map((x) => x.items)
                              .flat(),
                          ]}
                          row={editItemPackageDialog.row}
                          allServices={allServices}
                          discount={discount}
                          conversionRate={conversionRate}
                          currency={currency}
                          selectUnpriced={checkPermission(['TakeBackItems'])}
                          addToCart={(itemPackage: any) => {
                            dispatch(
                              replaceItemPackage({
                                itemPackage: itemPackage,
                                oldId: itemPackage.id,
                              })
                            );
                            setEditItemPackageDialog({
                              open: false,
                              row: {
                                id: 0,
                                name: 'Termékcsomag ' + 0,
                                itemNumber: '',
                                amount: 1,
                                discount: 0,
                                sellPriceGross: 0,
                                sellPriceNet: 0,
                                taxTypeId: 1,
                                comment: '',
                                items: [],
                                services: [],
                              },
                            });
                          }}
                          canOverrideGrossPrice={checkPermission([
                            'CreateOrderWithCustomPrice',
                          ])}
                        />
                        <AddNewItemDialog
                          open={addItemDialogOpen}
                          setOpen={setAddItemDialogOpen}
                          getAvailableAmount={getAvailableAmount}
                          items={items}
                          conversionRate={conversionRate}
                          cart={[
                            ...cart,
                            ...itemPackages.map((x) => x.items).flat(),
                          ]}
                          taxes={taxes}
                          selectUnpriced={checkPermission(['TakeBackItems'])}
                          addToCart={addCartItem}
                          disableAmountCheck={
                            invoiceType === InvoiceTypes.DepositInvoice
                          }
                          canOverrideGrossPrice={checkPermission([
                            'CreateOrderWithCustomPrice',
                          ])}
                          currency={currency}
                        />
                        <EditItemDialog
                          open={editItemDialogOpen.open}
                          setOpen={setEditItemDialogOpen}
                          disableAmountCheck={
                            invoiceType === InvoiceTypes.DepositInvoice
                          }
                          getAvailableAmount={(itemId) => {
                            return getAvailableAmount(itemId, [
                              ...cart,
                              ...itemPackages.map((x) => x.items).flat(),
                            ]);
                          }}
                          row={editItemDialogOpen.row}
                          items={items}
                          taxes={taxes}
                          onSubmit={processEditItem}
                          canOverrideGrossPrice={checkPermission([
                            'CreateOrderWithCustomPrice',
                          ])}
                          currency={currency}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent="end">
                      <Grid item>
                        Fizetendő(Nettó):{' '}
                        {formatCurrency(
                          (
                            cart.reduce(
                              (acc: number, cur: any) =>
                                acc +
                                getItemPriceNet(cur.itemId, cur.taxTypeId) *
                                  cur.amount *
                                  (cur.discount !== 0
                                    ? 1 - cur.discount / 100
                                    : 1) *
                                  (discount !== 0 ? 1 - discount / 100 : 1),
                              0
                            ) +
                            services.reduce(
                              (acc: number, cur: any) =>
                                acc +
                                cur.servicePriceNet *
                                  cur.amount *
                                  (cur.discount !== 0
                                    ? 1 - cur.discount / 100
                                    : 1) *
                                  (discount !== 0 ? 1 - discount / 100 : 1),
                              0
                            ) +
                            itemPackages.reduce(
                              (acc: number, cur: any) =>
                                acc +
                                cur.sellPriceNet *
                                  cur.amount *
                                  (cur.discount !== 0
                                    ? 1 - cur.discount / 100
                                    : 1) *
                                  (discount !== 0 ? 1 - discount / 100 : 1),
                              0
                            )
                          ).toFixed(2),
                          currency
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent="end">
                      <Grid item>
                        Fizetendő(Bruttó):
                        {formatCurrency(
                          (
                            cart.reduce(
                              (acc, cur) =>
                                acc +
                                getItemPriceGross(cur.itemId) *
                                  cur.amount *
                                  (cur.discount !== 0
                                    ? 1 - cur.discount / 100
                                    : 1) *
                                  (discount !== 0 ? 1 - discount / 100 : 1),
                              0
                            ) +
                            services.reduce(
                              (acc, cur) =>
                                acc +
                                cur.servicePriceGross *
                                  cur.amount *
                                  (cur.discount !== 0
                                    ? 1 - cur.discount / 100
                                    : 1) *
                                  (discount !== 0 ? 1 - discount / 100 : 1),
                              0
                            ) +
                            itemPackages.reduce(
                              (acc, cur) =>
                                acc +
                                cur.sellPriceGross *
                                  cur.amount *
                                  (cur.discount !== 0
                                    ? 1 - cur.discount / 100
                                    : 1) *
                                  (discount !== 0 ? 1 - discount / 100 : 1),
                              0
                            )
                          ).toFixed(2),
                          currency
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid container item xs={12} justifyContent="right" p={2} spacing={2}>
        <Grid item xs={12} md="auto" sx={{ textAlign: { xs: 'center' } }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={readonly}
                checked={createInvoice}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(setCreateInvoice(e.target.checked))
                }
              />
            }
            label="Számla"
          />
        </Grid>
        <Grid item xs={12} md="auto" textAlign={'center'}>
          <Button
            variant="contained"
            fullWidth
            disabled={
              isSubmitting ||
              readonly ||
              !paymentType ||
              !(companyId > 0) ||
              !(documentBlockId > 0) ||
              (clientId && !addressId) ||
              (invoiceType !== InvoiceTypes.Receipt && !clientId)
            }
            onClick={() => {
              dispatch(setIsBooking(false));
              setIsSubmitting(true);
              handleSell(false);
            }}
          >
            {createInvoice
              ? 'Számla mentése'
              : isBooking
                ? 'Foglalás véglegesítése'
                : 'Tranzakció inditása'}
          </Button>
        </Grid>
        {orderId > 0 && isBooking && invoiceType === InvoiceTypes.Invoice && (
          <Grid item xs={12} md="auto" textAlign={'center'}>
            <Button
              variant="contained"
              fullWidth
              disabled={readonly}
              onClick={() => {
                dispatch(setIsBooking(true));
                setIsSubmitting(true);

                handleSell(true);
              }}
            >
              Mentés foglalásként
            </Button>
          </Grid>
        )}
        {orderId === 0 &&
          !cart.some((x) => x.itemId < 0) &&
          invoiceType === InvoiceTypes.Invoice && (
            <Grid item xs={12} md="auto" textAlign={'center'}>
              <Button
                variant="contained"
                fullWidth
                disabled={readonly}
                onClick={() => {
                  if (!clientId) {
                    enqueueSnackbar('Kérjük válasszon ügyfelet!', {
                      variant: 'error',
                    });
                  } else {
                    dispatch(setIsBooking(true));
                    setIsSubmitting(true);

                    handleSell(true);
                  }
                }}
              >
                Tranzakció inditása foglalásként
              </Button>
            </Grid>
          )}
        <Grid item xs={12} md="auto" textAlign={'center'}>
          <Button
            variant="contained"
            disabled={
              (invoiceType !== InvoiceTypes.Receipt && !clientId) || !companyId
            }
            onClick={() => {
              let cl = clients.find((x) => x.id === clientId);
              let comp = companies.find((x) => x.id === companyId);
              setOrderPDFDialog({
                open: true,
                values: {
                  id: orderId,
                  warehouseId: warehouseId,
                  discount,
                  priceCategoryId,
                  isElectronic,
                  finalPriceNet: calculatefinalPrice(false),
                  finalPriceGross: calculatefinalPrice(true),
                  invoiceLanguage: invoiceLanguage,
                  paymentType,
                  documentBlockId,
                  templateSetting: documentBlocks.find(
                    (x) => x.id === documentBlockId
                  )?.documentTemplate?.setting,
                  companyId,
                  clientId: clientId === 0 ? null : clientId,
                  invoiceType,
                  items: [
                    ...cart.map((x) => ({
                      ...x,
                      quantity: x.amount,
                      sellPriceNet:
                        x.sellPriceNet *
                        (x.discount !== 0 ? 1 - x.discount / 100 : 1) *
                        (discount !== 0 ? 1 - discount / 100 : 1),
                      sellPriceGross:
                        x.sellPriceGross *
                        (x.discount !== 0 ? 1 - x.discount / 100 : 1) *
                        (discount !== 0 ? 1 - discount / 100 : 1),
                    })),
                  ],
                  invoiceNumber: 'TEST-123',
                  itemPackages: [
                    ...itemPackages.map((x) => ({
                      ...x,
                      items: [
                        ...x.items.map((c) => ({
                          ...c,
                          quantity: c.amount,
                          sellPriceNet:
                            c.sellPriceNet *
                            (c.discount !== 0 ? 1 - c.discount / 100 : 1) *
                            (discount !== 0 ? 1 - discount / 100 : 1),
                          sellPriceGross:
                            c.sellPriceGross *
                            (c.discount !== 0 ? 1 - c.discount / 100 : 1) *
                            (discount !== 0 ? 1 - discount / 100 : 1),
                        })),
                      ],
                      services: [
                        ...x.services.map((d) => ({
                          ...d,
                          serviceName: d.name,
                          quantity: d.amount,
                          serviceId: x.id,
                          servicePriceNet:
                            d.servicePriceNet *
                            (d.discount !== 0 ? 1 - d.discount / 100 : 1) *
                            (discount !== 0 ? 1 - discount / 100 : 1),
                          servicePriceGross:
                            d.servicePriceGross *
                            (d.discount !== 0 ? 1 - d.discount / 100 : 1) *
                            (discount !== 0 ? 1 - discount / 100 : 1),
                        })),
                      ],
                    })),
                  ],
                  services: [
                    ...services.map((x) => ({
                      ...x,
                      serviceName: x.name,
                      quantity: x.amount,
                      serviceId: x.id,
                      servicePriceNet:
                        x.servicePriceNet *
                        (x.discount !== 0 ? 1 - x.discount / 100 : 1) *
                        (discount !== 0 ? 1 - discount / 100 : 1),
                      servicePriceGross:
                        x.servicePriceGross *
                        (x.discount !== 0 ? 1 - x.discount / 100 : 1) *
                        (discount !== 0 ? 1 - discount / 100 : 1),
                    })),
                    ...itemPackages
                      .map((x) => {
                        return [
                          ...x.services.map((d) => ({
                            ...d,
                            serviceName: d.name,
                            quantity: d.amount,
                            orderItemPackageId: x.id,
                            servicePriceNet:
                              d.servicePriceNet *
                              (d.discount !== 0 ? 1 - d.discount / 100 : 1) *
                              (discount !== 0 ? 1 - discount / 100 : 1),
                            servicePriceGross:
                              d.servicePriceGross *
                              (d.discount !== 0 ? 1 - d.discount / 100 : 1) *
                              (discount !== 0 ? 1 - discount / 100 : 1),
                          })),
                        ];
                      })
                      .flat(),
                  ],
                  dateOfFulfillment: new Date(),
                  dueDate: new Date(),
                  order: {
                    addressId: addressId > 0 ? addressId : null,
                    client: cl
                      ? {
                          ...cl,
                          addresses: [
                            ...cl?.addresses?.map((x) => ({
                              ...x,
                              address: { ...x },
                            })),
                          ],
                        }
                      : {},
                    description,
                    currency: currency,
                  },
                  company: {
                    ...comp,
                    addresses: [
                      ...comp?.addresses?.map((x) => ({
                        ...x,
                        address: { ...x },
                      })),
                    ],
                  },
                },
              });
            }}
            fullWidth
          >
            Nyomtatási nézet
          </Button>
        </Grid>
        <Grid item xs={12} md="auto" textAlign={'center'}>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={() => dispatch(reset())}
          >
            Alaphelyzet
          </Button>
        </Grid>
      </Grid>

      <OrderPDFDialog
        open={orderPDFDialog.open}
        onClose={() => setOrderPDFDialog({ open: false, values: {} })}
        values={orderPDFDialog.values}
        currency={currency}
      />
      <ConfirmDeleteDialog />
    </Grid>
  );
};

export default SalesPage;
