import { CheckCircle as ApproveIcon, OpenInBrowser } from '@mui/icons-material';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataList from '../../components/DataList';
import useCheckPermission from '../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import orderService from '../../services/sales/orderService';
import financeService from '../../services/statistics/financeService';
import { formatCurrency } from '../../utils/valueFormatters';
import { set, parseJSON } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { EntityTypes } from '../../types/EntityTypes';
import EntityNavigator from '../../components/EntityNavigator';

const OutstandingOrderStatistics = () => {
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [approvePaymentDialog, setApprovePaymentDialog] = useState<any>({
    open: false,
    id: 0,
    payerName: '',
    dateOfPayment: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();
  const titleDescriptor = useFunctionDescriptor(
    'OutstandingOrderStatistics.title'
  );
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const { checkPermission } = useCheckPermission();
  const dispatch = useDispatch();

  const [listMy, setListMy] = useState<boolean>(
    checkPermission(['OutstandingOrderQueryAll']) ? false : true
  );

  useEffect(() => {
    let abort = new AbortController();

    dispatch({ type: 'SHOW_QUERY' });
    financeService
      .outstandingOrderQuery(
        { isExpired: isExpired, isSelf: listMy },
        abort.signal
      )
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setRows(response.records);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });

    return () => {
      abort.abort();
    };
  }, [isExpired, listMy]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Vevői fiók megnyitása">
            <OpenInBrowser />
          </Tooltip>
        }
        label="Vevői fiók megnyitásas"
        disabled={!params.row.invoiceUrl}
        onClick={() => window.open(params.row.invoiceUrl, '_blank')}
      />
    );

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'success'}
        icon={
          <Tooltip title="Kifizetve">
            <ApproveIcon />
          </Tooltip>
        }
        label="Kifizetve"
        onClick={() =>
          setApprovePaymentDialog({
            open: true,
            id: params.row.id,
            payerName: '',
            dateOfPayment: new Date(),
          })
        }
      />
    );
    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleDateString();
      },
    },
    {
      field: 'companyName',
      headerName: 'Számlakibocsátó',
      flex: 100,
    },

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'dueDate',
      headerName: 'Fizetési határidő',
      flex: 100,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: 'finalPriceNet',
      headerName: 'Nettó végösszeg',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'finalPriceGross',
      headerName: 'Bruttó végösszeg',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'invoiceNumber',
      headerName: 'Számlaszám',
      headerClassName: 'title',
      flex: 100,
    },
    {
      field: 'invoiceUrl',
      headerName: 'Számla link',
      flex: 100,
    },
    {
      minWidth: 50,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleApprove = (entity: any) => {
    orderService.approvePayment(entity).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres jóváhagyás', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== entity.id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Kintlévőségek{titleDescriptor}</h2>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginBottom={2}
            >
              <RadioGroup
                row
                value={isExpired ? 'expired' : 'notExpired'}
                onChange={(e) => {
                  const value = e.target.value;
                  setIsExpired(value === 'expired');
                }}
              >
                <FormControlLabel
                  value="notExpired"
                  control={<Radio color="primary" />}
                  label="Határidőn belül"
                />
                <FormControlLabel
                  value="expired"
                  control={<Radio color="primary" />}
                  label="Határidőn túl"
                />
              </RadioGroup>
            </Stack>
          </Grid>
          <Grid item xs={12} pl={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={listMy}
                  disabled={!checkPermission(['OutstandingOrderQueryAll'])}
                  onChange={(e, checked) => setListMy(checked)}
                />
              }
              label="Saját kintlévőségek"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            getRowId={(row) => row.orderId}
            localStorageKey={'OutstandingOrderStatistics'}
          />
        </Grid>
      </Grid>
      <ConfirmDeleteDialog />
      <Dialog
        open={approvePaymentDialog.open}
        onClose={() =>
          setApprovePaymentDialog({
            open: false,
            id: 0,
            payerName: '',
            dateOfPayment: new Date(),
          })
        }
      >
        <DialogTitle>Kifizetés jóváhagyása</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={2}>
            <Grid item pt={2} pb={2}>
              <TextField
                value={approvePaymentDialog.payerName}
                label="Fizető neve"
                onChange={(e) => {
                  setApprovePaymentDialog({
                    ...approvePaymentDialog,
                    payerName: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item pt={2} pb={2}>
              <DatePicker
                value={approvePaymentDialog.dateOfPayment}
                label="Kifizetés dátuma"
                onChange={(value) => {
                  setApprovePaymentDialog({
                    ...approvePaymentDialog,
                    dateOfPayment: value,
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleApprove({
                id: approvePaymentDialog.id,
                payerName: approvePaymentDialog.payerName,
                dateOfPayment: approvePaymentDialog.dateOfPayment,
              });
              setApprovePaymentDialog({
                open: false,
                id: 0,
                payerName: '',
                dateOfPayment: new Date(),
              });
            }}
          >
            Jóváhagyás
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setApprovePaymentDialog({
                open: false,
                id: 0,
                payerName: '',
                dateOfPayment: new Date(),
              });
            }}
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default OutstandingOrderStatistics;
