import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import FilesPage from '../pages/chimneySweeping/Files/FilesPage';

const FilePageDialog = (props: any) => {
  const {
    open,
    onClose,
    attachmentType,
    entityUniqueId = null,
    localStorageKey,
    onList,
    overrideFileName = null,
  } = props;

  function sanitizeFileName(fileName) {
    // Replace accented characters with unaccented equivalents
    fileName = fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Replace invalid filename characters with an underscore
    return fileName
      .replace(/[\/*:?"<>|.]/g, '')
      .replace(/ /g, '_')
      .trim()
      ?.toLowerCase();
  }

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle>Fájlok feltöltése</DialogTitle>
      <DialogContent>
        <FilesPage
          attachmentType={attachmentType}
          entityUniqueId={entityUniqueId}
          isDialog={true}
          overrideFileName={
            overrideFileName ? sanitizeFileName(overrideFileName) : null
          }
          localStorageKey={localStorageKey}
          onList={(length) => {
            onList(length);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Mégse</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilePageDialog;
