import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import {
  GridColDef,
  GridActionsColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startOfWeek, endOfWeek } from 'date-fns';
import { startOfMonth, endOfMonth } from 'date-fns';
import { startOfQuarter, endOfQuarter } from 'date-fns';
import { startOfYear, endOfYear, parseJSON } from 'date-fns';
import moment from 'moment';
import DataList from '../../components/DataList';
import useCheckPermission from '../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import userService from '../../services/authority/userService';
import clientService from '../../services/crm/clientService';
import expenseTypeService from '../../services/erp/expenseTypeService';
import financeService from '../../services/statistics/financeService';
import { ClientTypes } from '../../types/ClientTypes';
import { formatCurrency } from '../../utils/valueFormatters';
import { EntityStatuses } from '../../types/EntityStatuses';
import DialogActions from '@mui/material/DialogActions';
import orderService from '../../services/sales/orderService';
import expenseService from '../../services/erp/expenseService';
import { CheckCircle as ApproveIcon, OpenInBrowser } from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { EntityTypes } from '../../types/EntityTypes';
import EntityNavigator from '../../components/EntityNavigator';

const UnPaidExpenseStatistics = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [expenseTypes, setExpenseTypes] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const titleDescriptor = useFunctionDescriptor('ExpenseStatistics.title');
  const userId = useSelector((state: any) => state.user.userInfo.userId);
  const [totalNetPrice, setTotalNetPrice] = useState<number>(0);
  const [totalGrossPrice, setTotalGrossPrice] = useState<number>(0);
  const [selectedInterval, setSelectedInterval] = useState<string>('');

  const timeIntervalOptions = [
    { label: 'Napi', value: 'day' },
    { label: 'Heti', value: 'week' },
    { label: 'Havi', value: 'month' },
    { label: 'Negyedéves', value: 'quarter' },
    { label: 'Éves', value: 'year' },
  ];

  const [approvePaymentDialog, setApprovePaymentDialog] = useState<any>({
    open: false,
    id: 0,
    dateOfPayment: new Date(),
  });

  const [entity, setEntity] = useState<any>({
    employeeId: null,
    clientId: null,
    companyId: null,
    startDate: null,
    endDate: null,
    expenseTypeName: null,
    paymentType: null,
    isAcquisition: null,
    isPaid: false,
  });

  useEffect(() => {
    if (!checkPermission(['ExpenseQueryAll'])) {
      setEntity({ ...entity, employeeId: userId });
    }
  }, [userId]);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let abort = new AbortController();

    clientService.list(false, null, abort.signal).then((response) => {
      if (!response) return;
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
      }
    });

    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    expenseTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setExpenseTypes(response.records);
      }
    });
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'success'}
        icon={
          <Tooltip title="Kifizetve">
            <ApproveIcon />
          </Tooltip>
        }
        label="Kifizetve"
        onClick={() =>
          setApprovePaymentDialog({
            open: true,
            id: params.row.id,
            dateOfPayment: new Date(),
          })
        }
      />
    );
    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter: (params) => parseJSON(params.value).toLocaleDateString(),
    },
    {
      field: 'createdByName',
      headerName: 'Dolgozó',
      headerClassName: 'title',
      flex: 100,
    },
    { field: 'expenseTypeName', headerName: 'Kiadás típusa', flex: 100 },
    { field: 'acquisitionId', headerName: 'Beszerzés Azonosító', flex: 100 },
    { field: 'companyName', headerName: 'Számlakibocsátó', flex: 100 },
    {
      field: 'clientName',
      headerName: 'Ügyfél',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'priceNet',
      headerName: 'Nettó ár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'priceGross',
      headerName: 'Bruttó ár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'taxAmount',
      headerName: 'ÁFA',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    { field: 'receiptNumber', headerName: 'Bizonylatszám', flex: 100 },
    { field: 'paymentType', headerName: 'Fizetési mód', flex: 100 },
    {
      field: 'paymentDeadline',
      headerName: 'Fizetési határidő',
      flex: 200,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      minWidth: 50,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleSearch = () => {
    dispatch({ type: 'SHOW_QUERY' });
    financeService
      .expenseQuery(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setRows(response.records);
          const sumNetPrice = response.records.reduce(
            (total, row) => total + row.priceNet,
            0
          );
          setTotalNetPrice(sumNetPrice);
          const sumGrossPrice = response.records.reduce(
            (total, row) => total + row.priceGross,
            0
          );
          setTotalGrossPrice(sumGrossPrice);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  const handleApprove = (entity: any) => {
    expenseService.update(entity).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres fizetés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== entity.id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Befizetésre váró kiadások{titleDescriptor}</h2>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                id="selectedInterval"
                value={selectedInterval}
                onChange={(event, value) => {
                  setSelectedInterval(value);
                  const nowstart = moment(new Date()).startOf('day').toDate();
                  const nowend = moment(new Date()).endOf('day').toDate();
                  switch (value) {
                    case 'day':
                      setEntity({
                        ...entity,
                        startDate: nowstart,
                        endDate: nowend,
                      });
                      break;
                    case 'week':
                      const startOfWeekDate = startOfWeek(nowstart, {
                        weekStartsOn: 1,
                      });
                      const endOfWeekDate = endOfWeek(nowend, {
                        weekStartsOn: 1,
                      });
                      setEntity({
                        ...entity,
                        startDate: startOfWeekDate,
                        endDate: endOfWeekDate,
                      });
                      break;
                    case 'month':
                      const startOfMonthDate = startOfMonth(nowstart);
                      const endOfMonthDate = endOfMonth(nowend);
                      setEntity({
                        ...entity,
                        startDate: startOfMonthDate,
                        endDate: endOfMonthDate,
                      });
                      break;
                    case 'quarter':
                      const quarterStart = startOfQuarter(nowstart);
                      const quarterEnd = endOfQuarter(nowend);
                      setEntity({
                        ...entity,
                        startDate: quarterStart,
                        endDate: quarterEnd,
                      });
                      break;
                    case 'year':
                      const startOfYearDate = startOfYear(nowstart);
                      const endOfYearDate = endOfYear(nowend);
                      setEntity({
                        ...entity,
                        startDate: startOfYearDate,
                        endDate: endOfYearDate,
                      });
                      break;
                    default:
                      setEntity({ ...entity, startDate: null, endDate: null });
                      break;
                  }
                }}
                getOptionLabel={(option) => {
                  if (option === null) {
                    return 'Mindegy';
                  }
                  return (
                    timeIntervalOptions.find((o) => o.value === option)
                      ?.label ?? ''
                  );
                }}
                options={timeIntervalOptions.map((option) => option.value)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Időintervallum" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <DatePicker
                value={entity.startDate}
                onChange={(value) => setEntity({ ...entity, startDate: value })}
                label="Kezdő dátum"
                inputFormat="yyyy-MM-dd"
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <DatePicker
                value={entity.endDate}
                onChange={(value) => {
                  if (value !== null) {
                    setEntity({
                      ...entity,
                      endDate: moment(value).endOf('day').toDate(),
                    });
                  } else {
                    setEntity({
                      ...entity,
                      endDate: null,
                    });
                  }
                }}
                label="Vég dátum"
                inputFormat="yyyy-MM-dd"
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="employeeId"
              disabled={!checkPermission(['ExpenseQueryAll'])}
              value={entity.employeeId}
              onChange={(event, value) => {
                setEntity({ ...entity, employeeId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let user = users.find((g) => g.id === option);
                return `${user?.fullName} (${user?.email ?? ''})`;
              }}
              options={[null, ...users.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Dolgozó" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="clientId"
              disabled={!checkPermission(['ClientView'])}
              value={entity.clientId}
              onChange={(event, value) => {
                setEntity({ ...entity, clientId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }

                var found = clients.find((g) => g.id === option);
                if (found) {
                  return `${
                    found?.companyName === ''
                      ? found.name.fullName
                      : found.companyName
                  } (${found.taxNumber})}`;
                } else return 'Nem található';
              }}
              options={[null, ...clients.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Ügyfél" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="companyId"
              disabled={!checkPermission(['ClientView'])}
              value={entity.companyId}
              onChange={(event, value) => {
                setEntity({ ...entity, companyId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }

                var found = clients.find((g) => g.id === option);
                if (found) {
                  return `${
                    found?.isPrivatePerson
                      ? found?.name?.fullName
                      : found?.companyName
                  } (${found.taxNumber})}`;
                } else return 'Nem található';
              }}
              options={[
                null,
                ...clients
                  .filter((c) => c.clientType === ClientTypes.Distributor)
                  .map((g) => g.id),
              ]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Számlakibocsátó" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="expenseTypeName"
              disabled={!checkPermission(['ExpenseTypeView'])}
              value={entity.expenseTypeName}
              onChange={(event, value) => {
                setEntity({ ...entity, expenseTypeName: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                return option;
              }}
              options={[null, ...expenseTypes.map((g) => g.name)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Kiadás típusa" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="paymentType"
              value={entity.paymentType}
              onChange={(event, value) => {
                setEntity({ ...entity, paymentType: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                return option;
              }}
              options={[null, 'Készpénz', 'Bankkártya', 'Átutalás', 'Utánvét']}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Fizetési mód" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="isAcquisition"
              value={entity.isAcquisition}
              onChange={(event, value) => {
                setEntity({
                  ...entity,
                  isAcquisition:
                    value === null
                      ? null
                      : value === 'Beszerzés'
                        ? true
                        : false,
                });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                if (option === true) {
                  return 'Beszerzés';
                }
                if (option === false) {
                  return 'Nem beszerzés';
                }

                return option;
              }}
              options={[null, 'Beszerzés', 'Nem beszerzés']}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Kiadás eredete" />
              )}
            />
          </Grid>
          <Grid item xs={12} textAlign={'right'}>
            <Button variant="contained" onClick={() => handleSearch()}>
              Keresés
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={'bold'}>
              Összesen:
            </Typography>
          </Grid>
          <Grid item>
            {' '}
            <Typography variant="body1">
              {' '}
              Nettó: {formatCurrency(totalNetPrice)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Bruttó: {formatCurrency(totalGrossPrice)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'UnPaidExpenseStatistics'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
      <Dialog
        open={approvePaymentDialog.open}
        onClose={() =>
          setApprovePaymentDialog({
            open: false,
            id: 0,
            dateOfPayment: new Date(),
          })
        }
      >
        <DialogTitle>Kifizetés jóváhagyása</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={2}>
            <Grid item pt={2} pb={2}>
              <DatePicker
                value={approvePaymentDialog.dateOfPayment}
                label="Kifizetés dátuma"
                onChange={(value) => {
                  setApprovePaymentDialog({
                    ...approvePaymentDialog,
                    dateOfPayment: value,
                  });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              var newEntity = rows.find(
                (x) => x.id === approvePaymentDialog.id
              );
              handleApprove({
                ...newEntity,
                dateOfPayment: approvePaymentDialog.dateOfPayment,
                isPaid: true,
              });
              setApprovePaymentDialog({
                open: false,
                id: 0,
                dateOfPayment: new Date(),
              });
            }}
          >
            Jóváhagyás
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setApprovePaymentDialog({
                open: false,
                id: 0,
                dateOfPayment: new Date(),
              });
            }}
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default UnPaidExpenseStatistics;
