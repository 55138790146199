import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import {
  Box,
  Paper,
  IconButton,
  Grid,
  Tooltip,
  Checkbox,
  Dialog,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import { Info, Add, Close } from '@mui/icons-material';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import itemPriceService from '../../../services/pricing/itemPriceService';
import CreateItemAcquisitionDialog from './CreateItemAcquisitionDialog';
import acquisitionService from '../../../services/acquisitions/acquisitionService';
import { useBarcodeScanner } from '../../../components/barcode-scanner/useBarcodeScanner';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import { formatCurrency } from '../../../utils/valueFormatters';
import AcquisitionHistoryDialog from './AcquisitionHistoryDialog';

export interface AcquisitionPrice {
  itemId: number;
  itemName: string;
  brandName: string;
  itemGroupName: string;
  barcode: string;
  itemNumber: string;
  hasAcquisition: boolean;
  acquisitionHistory: AcquisitionHistory[];
}

export interface AcquisitionHistory {
  itemAcquisitionId: number;
  acquisitionId: number;
  acquisitionDate: Date;
  unitPrice: number;
}

const AcquisitionPricePage = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [createItemAcquisitionDialog, setCreateItemAcquisitionDialog] =
    useState<any>({ open: false, data: { itemName: '' } });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const [openDialog, setOpenDialog] = useState({
    open: false,
    row: { acquisitionHistory: {}, itemId: 0 },
  });

  const isSmallScreen = useMediaQuery('(min-width:600px)');

  const titleDescriptor = useFunctionDescriptor('AcquisitionPricePage.title');

  const loadData = useCallback(() => {
    let ac = new AbortController();

    dispatch({ type: 'SHOW_QUERY' });
    itemPriceService
      .listAcquisitionPrices(ac.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      ac.abort();
    };
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleBarcodeRead = (barcode) => {
    if (barcode.item) {
      setCreateItemAcquisitionDialog({
        open: true,
        data: barcode.item,
      });
    } else {
      enqueueSnackbar(
        'Ismeretlen árucikk, ellenőrizze hogy van-e kiválasztott árkategória / raktár',
        {
          variant: 'error',
        }
      );
    }
  };

  useBarcodeScanner(handleBarcodeRead, rows);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['AcquisitionCreate'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Beszerzési ár hozzáadása">
              <Add />
            </Tooltip>
          }
          label={'Beszerzési ár hozzáadása'}
          onClick={() => {
            setCreateItemAcquisitionDialog({
              open: true,
              data: params.row,
            });
          }}
        />
      );
    }

    return actions;
  };

  const handleCreateItemAcquisition = (itemId, unitPrice) => {
    dispatch({ type: 'SHOW_SAVE' });
    acquisitionService
      .createItemAcquisition({ itemId, unitPrice })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          loadData();
          setCreateItemAcquisitionDialog({
            open: false,
            data: { itemName: '' },
          });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'itemNumber',
      headerName: 'Cikkszám',
      headerClassName: 'title',
      flex: 100,
    },
    {
      field: 'itemName',
      headerName: 'Termék',
      flex: 100,
    },
    { field: 'brandName', headerName: 'Márka', flex: 100 },
    { field: 'itemGroupName', headerName: 'Termékcsoport', flex: 100 },
    {
      field: 'unitPrice',
      headerName: 'Nettó beszerzési ár',
      flex: 100,
      renderCell: (params) => (
        <Grid
          container
          justifyContent={isSmallScreen ? 'space-between' : 'center'}
          alignItems="center"
        >
          <Grid item>{formatCurrency(params.row.unitPrice)}</Grid>
          <Grid item>
            {checkPermission(['ItemStockView']) && (
              <Grid>
                <IconButton
                  onClick={() => setOpenDialog({ row: params.row, open: true })}
                >
                  <Info color="info" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 20,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const getAcquisitionHistory = () => {
    return (
      rows.find((x) => x.itemId === openDialog.row.itemId)
        ?.acquisitionHistory ?? []
    );
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Beszerzési árak{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            getRowId={(e) => e.itemId}
            localStorageKey={'AcquisitionPricePage'}
          />
        </Grid>
      </Grid>
      <CreateItemAcquisitionDialog
        open={createItemAcquisitionDialog.open}
        data={createItemAcquisitionDialog.data}
        onSubmit={handleCreateItemAcquisition}
        handleClose={() =>
          setCreateItemAcquisitionDialog({
            open: false,
            data: { itemName: '' },
          })
        }
      />
      <AcquisitionHistoryDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        getAcquisitionHistory={getAcquisitionHistory}
        loadData={loadData}
      />
    </Paper>
  );
};

export default AcquisitionPricePage;
