import axios from 'axios';
import { postData } from '../axiosUtils';

const chimneyService = {
  list: (addressId: number | null = null) => {
    return axios.post(`/api/chimney/list`, { addressId }).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/chimney/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/chimney/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/chimney/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  combine: (entity: any) => {
    return postData({
      url: `/api/chimney/combine`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/chimney/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default chimneyService;
