import {
  ContentCopy,
  Delete as DeleteIcon,
  Edit as EditIcon,
  OpenInBrowser,
  Sync,
  Visibility,
  Wysiwyg,
} from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import DraftsIcon from '@mui/icons-material/Drafts';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import SendIcon from '@mui/icons-material/Send';
import TaskIcon from '@mui/icons-material/Task';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
  FormControl,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import {
  parseJSON,
  endOfMonth,
  startOfMonth,
  eachMonthOfInterval,
  eachDayOfInterval,
} from 'date-fns';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CalendarWithNumberIcon from '../../../components/CalendarWithNumberIcon';
import DataList from '../../../components/DataList';
import EntityNavigator from '../../../components/EntityNavigator';
import FilePageDialog from '../../../components/FilePageDialog';
import OrderPDFDialog from '../../../components/OrderPDFDialog';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useSignalREffect from '../../../hooks/useSignalREffect';
import billingoService from '../../../services/billingoService';
import orderService from '../../../services/sales/orderService';
import szamlazzhuService from '../../../services/szamlazzhuService';
import {
  setConversionRate,
  setCurrency,
  setPriceCategoryId,
  setWarehouseId,
} from '../../../stateManagement/actions/itemActions';
import {
  setBookings,
  setCart,
  setClientId,
  setCompanyId,
  setDescription,
  setDiscount,
  setInvoiceType,
  setIsBooking,
  setIsCopy,
  setIsReadonly,
  setItemPackages,
  setOrderId,
  setPaymentType,
  setServices,
  setPreviousInvoiceType,
  setOriginalCart,
  setDocumentBlockId,
} from '../../../stateManagement/actions/salesActions';
import * as fromClients from '../../../stateManagement/thunks/clientsThunk';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import { EmailStatuses } from '../../../types/EmailStatuses';
import { EntityTypes } from '../../../types/EntityTypes';
import { InvoiceTypes } from '../../../types/InvoiceTypes';
import { translateEmailStatusName } from '../../../utils/nameFormatters';
import { formatCurrency } from '../../../utils/valueFormatters';
import InvoiceInfoDialog from '../InvoiceInfoDialog';
import OrderEmailDialog from './OrderEmailDialog';
import userService from '../../../services/authority/userService';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import documentBlockService from '../../../services/billing/documentBlockService';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import DatePickerHeader from '../../../components/DatePickerHeader';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import InvoiceStornoDialog from '../../../components/InvoiceStornoDialog';

export interface Order {
  id: number;
  warehouseId?: number;
  isBooking?: boolean;
  priceCategoryId: number;
  discount: number;
  items: OrderItem[];
  description: string;
  date: Date;
}

export interface OrderItem {
  itemId: number;
  amount: number;
  discount: number;
}

const OrdersPage = (props: any) => {
  const { externalRows = null } = props;
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const [showBookings, setShowBookings] = useState<boolean>(false);
  const [showDeletedItems, setShowDeletedItems] = useState<boolean>(false);
  const [showSales, setShowSales] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const userDistributor = useSelector((state: any) => state.userDistributor);
  const { checkPermission } = useCheckPermission();
  const [emailDialog, setEmailDialog] = useState<any>({ open: false, id: 0 });
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [reload, setReload] = useState<boolean>(false);
  const [syncDialog, setSyncDialog] = useState<boolean>(false);
  const [szamlazzhuSyncDialog, setSzamlazzhuSyncDialog] =
    useState<boolean>(false);
  const [szamlazzhuSyncFormat, setSzamlazzhuSyncFormat] = useState<string>('');
  const [dateOfSync, setDateOfSync] = useState<Date>(
    moment(new Date()).subtract(1, 'weeks').toDate()
  );
  const [orderPDFDialog, setOrderPDFDialog] = useState<any>({
    open: false,
    orderId: 0,
  });
  const user = useSelector((state: any) => state.user.userInfo);
  const [invoicePrefixes, setInvoicePrefixes] = useState<any[]>([]);

  const titleDescriptor = useFunctionDescriptor('OrdersPage.title');
  const [invoiceInfoDialogOpen, setInvoiceInfoDialogOpen] = useState<any>({
    open: false,
    orderId: 0,
  });
  const { clients } = useSelector<any>((state) => state.sales) as any;
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);
  const [now, setNow] = useState(new Date());
  const [selectedFilterType, setSelectedFilterType] = useState<string>(
    'filterDateByCreation' as
      | 'filterDateByPaid'
      | 'filterDateByCreation'
      | 'filterDateByFullfilment'
  );
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: startOfMonth(moment(now).startOf('month').toDate()),
    endDate: endOfMonth(moment(now).endOf('month').toDate()),
  });
  const [tabValue, setTabValue] = useState<number>(2);
  const [invoiceStornoDialog, setInvoiceStornoDialog] = useState<any>({
    open: false,
    entity: null,
  });
  const fetchData = async (
    showDeletedItems,
    showSales,
    reload,
    externalRows,
    selectedInterval,
    selectedFilterType,
    signal?: any
  ) => {
    if (externalRows) {
      return setRows(externalRows);
    }

    const { startDate, endDate } = selectedInterval;

    // Parse the startDate and endDate to Date objects
    let start = new Date(startDate);
    let end = new Date(endDate);
    // Helper function to perform the query based on the list type
    const performQuery = async (start, end) => {
      return await orderService.list(
        null,
        selectedFilterType === 'filterDateByPaid',
        selectedFilterType === 'filterDateByCreation',
        selectedFilterType === 'filterDateByFullfilment',
        start,
        end,
        showSales || showDeletedItems,
        showDeletedItems,
        signal
      );
    };

    try {
      if (!endDate) {
        end = new Date();
      }
      let months = eachMonthOfInterval({ start, end });
      if (eachDayOfInterval({ start, end }).length < 28) {
        months = [start];
      }
      let allRecords = [];

      for (let i = months.length - 1; i >= 0; i--) {
        let isSameStartMonth =
          months[i].getMonth() === start.getMonth() &&
          months[i].getFullYear() === start.getFullYear();
        let isSameEndMonth =
          months[i].getMonth() === end.getMonth() &&
          months[i].getFullYear() === end.getFullYear();
        const monthStart =
          1 === months.length ? start : isSameStartMonth ? start : months[i];
        const monthEnd =
          i === months.length - 1
            ? end
            : isSameEndMonth
              ? end
              : endOfMonth(monthStart);
        const formattedStart = monthStart;
        const formattedEnd = monthEnd;
        if (i === months.length - 1) {
          dispatch({ type: 'SHOW_QUERY' });
        }
        const response = await performQuery(formattedStart, formattedEnd);

        if (response.canceled) {
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        if (response.hasError) {
          console.error('Query had errors:', response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        allRecords = allRecords.concat(response.records);
        setRows(allRecords);
        if (i === months.length - 1) {
          dispatch({ type: 'HIDE' });
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      enqueueSnackbar('An error occurred while fetching data.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    let abort = new AbortController();

    if (externalRows) {
      return setRows(externalRows);
    }

    fetchData(
      showDeletedItems,
      showSales,
      reload,
      externalRows,
      selectedInterval,
      selectedFilterType,
      abort.signal
    );

    return () => {
      abort.abort();
    };
  }, [
    showDeletedItems,
    showSales,
    reload,
    externalRows,
    selectedInterval,
    selectedFilterType,
  ]);

  const getDate = (params: any) => {
    return new Date(params.row.date);
  };

  const szamlazzhuSync = (format: string) => {
    dispatch({ type: 'SHOW_QUERY' });
    szamlazzhuService
      .startSync(format, userDistributor.selectedDistributor)
      .then((response) => {
        enqueueSnackbar('Sikeres szinkronizáció!', {
          variant: 'success',
        });
        setTimeout(() => {
          setReload(!reload);
        }, 1000);
      })
      .catch((e) => {
        enqueueSnackbar('Sikertelen szinkronizáció!', {
          variant: 'error',
        });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  useEffect(() => {
    if (user.userId > 0) {
      userService.get(user.userId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          return enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
        setInvoicePrefixes(response.result?.invoicePrefixes?.split(';') ?? []);
      });
    }
  }, [user]);

  useEffect(() => {
    dispatch(fromClients.loadClients());
  }, []);

  useEffect(() => {
    documentBlockService
      .list(DocumentBlockTypes.Invoice, user.userId)
      .then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setDocumentBlocks(response.records);
        }
      });
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (
      !params.row.isDeleted &&
      (!params.row.invoiceNumber || params.row.invoiceNumber === '')
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Számla kiállítása">
              <TaskIcon />
            </Tooltip>
          }
          label="Számla kiállítása"
          showInMenu
          onClick={() => {
            dispatch(setCompanyId(params.row.companyId));
            dispatch(setClientId(params.row.clientId));
            dispatch(setPaymentType(params.row.paymentType));

            setInvoiceInfoDialogOpen({ open: true, orderId: params.row.id });
          }}
        />
      );
    }

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Számla másolása">
            <ContentCopy />
          </Tooltip>
        }
        label={'Számla másolása'}
        showInMenu
        onClick={() => {
          dispatch(setDiscount(params.row.discount));
          dispatch(setIsCopy(true));
          dispatch(setDescription(params.row.description));
          dispatch(setWarehouseId(params.row.warehouseId));
          dispatch(setOrderId(0));
          dispatch(setPriceCategoryId(params.row.priceCategoryId));
          let itemId = 0;
          dispatch(setCurrency(params.row.currency));
          dispatch(setConversionRate(params.row.conversionRate));
          dispatch(
            setCart(
              params.row.items
                .filter((x) => !x.orderItemPackageId)
                ?.map((x) => {
                  if (x.itemId === 0) {
                    itemId--;
                    return { ...x, itemId: itemId };
                  } else {
                    return x;
                  }
                })
            )
          );
          dispatch(setItemPackages(params.row.itemPackages));
          dispatch(
            setServices(
              params.row.services
                .filter((x) => !x.orderItemPackageId)
                .map((service) => ({
                  ...service,
                  amount: service.quantity,
                }))
            )
          );
          dispatch(setIsReadonly(false));
          dispatch(setIsBooking(params.row.isBooking));
          dispatch(setCompanyId(params.row.companyId));
          dispatch(
            setInvoiceType(
              params.row.isBooking
                ? InvoiceTypes.Invoice
                : params.row.invoiceType === InvoiceTypes.Receipt
                  ? InvoiceTypes.Invoice
                  : params.row.invoiceType
            )
          );
          dispatch(setPaymentType(params.row.paymentType));
          dispatch(setClientId(params.row.clientId));
          if (params.row.isBooking) dispatch(setBookings(params.row.items));

          navigate(`/sales/order`);
        }}
      />
    );

    if (
      (params.row.invoiceType === InvoiceTypes.FeeColleciton ||
        params.row.invoiceType === InvoiceTypes.DepositInvoice ||
        params.row.invoiceType === InvoiceTypes.WayBill) &&
      !params.row.isBooking &&
      !params.row.hasFinalOrder &&
      !params.row.isDeleted
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Végszámla kiállítása">
              <TaskIcon />
            </Tooltip>
          }
          label={'Végszámla kiállítása'}
          onClick={() => {
            dispatch(setDiscount(params.row.discount));
            dispatch(setDescription(params.row.description));
            dispatch(setWarehouseId(params.row.warehouseId));
            dispatch(setIsCopy(false));
            dispatch(setPriceCategoryId(params.row.priceCategoryId));
            let itemId = 0;
            dispatch(
              setCart(
                params.row.items
                  .filter((x) => !x.orderItemPackageId)
                  ?.map((x) => {
                    if (x.itemId === 0) {
                      itemId--;
                      return { ...x, itemId: itemId };
                    } else {
                      return x;
                    }
                  })
              )
            );

            dispatch(setItemPackages(params.row.itemPackages));
            dispatch(
              setServices(
                params.row.services
                  .filter((x) => !x.orderItemPackageId)
                  .map((service) => ({
                    ...service,
                    amount: service.quantity,
                  }))
              )
            );
            dispatch(setOrderId(params.row.id));
            dispatch(setIsReadonly(false));
            dispatch(setCurrency(params.row.currency));
            dispatch(setConversionRate(params.row.conversionRate));
            dispatch(setIsBooking(params.row.isBooking));
            dispatch(setCompanyId(params.row.companyId));
            if (params.row.invoiceType === InvoiceTypes.FeeColleciton) {
              dispatch(setInvoiceType(InvoiceTypes.DepositInvoice));
            } else {
              dispatch(setInvoiceType(InvoiceTypes.Invoice));
            }
            dispatch(setPreviousInvoiceType(params.row.invoiceType));
            dispatch(setOriginalCart(params.row.items));
            dispatch(setPaymentType(params.row.paymentType));
            dispatch(setClientId(params.row.clientId));
            if (params.row.isBooking) dispatch(setBookings(params.row.items));

            navigate(`/sales/order`);
          }}
        />
      );
    }

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '4 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon color="primary" />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    if (checkPermission(['SendEmail']) && params.row.pdfFileAttachmentId > 0) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          showInMenu
          icon={
            <Tooltip title="Nyugta küldése">
              <SendIcon />
            </Tooltip>
          }
          label="Nyugta küldése"
          disabled={!params.row.clientId}
          onClick={() => {
            setEmailDialog({ open: true, id: params.row.id });
          }}
        />
      );
    }

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Vevői fiók megnyitása">
            <OpenInBrowser />
          </Tooltip>
        }
        label="Vevői fiók megnyitásas"
        disabled={!params.row.invoiceUrl}
        onClick={() => window.open(params.row.invoiceUrl, '_blank')}
        showInMenu
      />
    );

    let readonly = !checkPermission(['OrderEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly || !params.row.isBooking ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={
          readonly || !params.row.isBooking ? 'Megtekintés' : 'Szerkesztés'
        }
        onClick={() => {
          if (!params.row.isBooking || readonly) {
            setOrderPDFDialog({ open: true, orderId: params.row.id });
          } else {
            dispatch(setDiscount(params.row.discount));
            dispatch(setDescription(params.row.description));
            dispatch(setWarehouseId(params.row.warehouseId));
            dispatch(setIsCopy(false));
            dispatch(setInvoiceType(InvoiceTypes.Invoice));
            dispatch(setPriceCategoryId(params.row.priceCategoryId));
            const items = params.row.items.filter(
              (x) => !x.orderItemPackageId && !x.parentItemId
            );

            // Create a map to store the unique items
            const itemMap = new Map();

            items.forEach((item) => {
              if (itemMap.has(item.itemId)) {
                // If the itemId already exists, add the amount to the existing item
                itemMap.get(item.itemId).amount += item.amount;
              } else {
                // If the itemId does not exist, add it to the map
                itemMap.set(item.itemId, { ...item });
              }
            });

            // Convert the map back to an array
            const uniqueItems = Array.from(itemMap.values());

            dispatch(setCart(uniqueItems));
            dispatch(setItemPackages(params.row.itemPackages));
            dispatch(setIsReadonly(false));
            dispatch(
              setServices(
                params.row.services
                  .filter((x) => !x.orderItemPackageId)
                  .map((service) => ({
                    ...service,
                    amount: service.quantity,
                  }))
              )
            );
            dispatch(setOrderId(params.row.id));
            dispatch(setIsBooking(params.row.isBooking));
            dispatch(setCurrency(params.row.currency));
            dispatch(setConversionRate(params.row.conversionRate));
            dispatch(setPaymentType(params.row.paymentType));
            dispatch(setClientId(params.row.clientId));
            if (params.row.isBooking) dispatch(setBookings(params.row.items));

            navigate(`/sales/order`);
          }
        }}
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Számla részletes megtekintése">
            <Wysiwyg />
          </Tooltip>
        }
        label={'Számla részletes megtekintése'}
        onClick={() => {
          dispatch(setDiscount(params.row.discount));
          dispatch(setDescription(params.row.description));
          dispatch(setCurrency(params.row.currency));
          dispatch(setConversionRate(params.row.conversionRate));
          dispatch(setIsCopy(false));
          dispatch(setWarehouseId(params.row.warehouseId));
          dispatch(setPriceCategoryId(params.row.priceCategoryId));
          let itemId = 0;
          dispatch(
            setCart(
              params.row.items
                .filter((x) => !x.orderItemPackageId)
                ?.map((x) => {
                  if (x.itemId === 0) {
                    itemId--;
                    return { ...x, itemId: itemId };
                  } else {
                    return x;
                  }
                })
            )
          );
          dispatch(setItemPackages(params.row.itemPackages));
          dispatch(
            setServices(
              params.row.services
                .filter((x) => !x.orderItemPackageId)
                .map((service) => ({
                  ...service,
                  amount: service.quantity,
                }))
            )
          );
          dispatch(setOrderId(params.row.id));
          dispatch(setIsBooking(params.row.isBooking));
          dispatch(setIsReadonly(true));
          dispatch(setCompanyId(params.row.companyId));
          dispatch(setInvoiceType(InvoiceTypes.Invoice));
          dispatch(setPaymentType(params.row.paymentType));
          dispatch(setClientId(params.row.clientId));
          if (params.row.isBooking) dispatch(setBookings(params.row.items));

          navigate(`/sales/order`);
        }}
        showInMenu
      />
    );

    if (checkPermission(['OrderDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title={showDeletedItems ? 'Törlés' : 'Sztornó'}>
              {showDeletedItems ? <DeleteIcon /> : <CancelIcon />}
            </Tooltip>
          }
          label="Törlés"
          showInMenu
          onClick={() =>
            setParams(() => {
              if (!params.row.invoiceNumber) {
                return {
                  name: 'Biztosan törölni szeretné?',
                  open: true,
                  onConfirm: async () => handleDelete(params.row, true),
                };
              } else {
                if (checkPermission(['OrderWithInvoiceDelete'])) {
                  return {
                    open: true,
                    name: showDeletedItems
                      ? 'Biztosan véglegesen törölni szeretné a számlát?'
                      : 'Biztosan sztornózni szeretné a számlát?',
                    onConfirm: async () =>
                      showDeletedItems
                        ? handleDelete(params.row, true)
                        : setInvoiceStornoDialog({
                            open: true,
                            entity: params.row,
                          }),
                  };
                } else {
                  return {
                    open: true,
                    name: 'Nincs jogosultsága a számla törléséhez!',
                    onConfirm: async () => handleDelete(params.row, false),
                  };
                }
              }
            })
          }
        />
      );
    }

    return actions;
  };

  const getHeaders = (params: GridRowParams, color: any) => {
    var actions = [];
    if (params.row.isBooking) {
      return actions;
    }
    if (params.row.isPaid || !params.row.invoiceNumber) {
      actions.push(
        <GridActionsCellItem
          color={'success'}
          icon={
            <Tooltip title="Kifizetve">
              <EventAvailableIcon fontSize="large" />
            </Tooltip>
          }
          label="Kifizetve"
          onClick={() => {}}
        />
      );
    } else {
      actions.push(
        <GridActionsCellItem
          color={
            new Date(params.row.dueDate) <= new Date() ? 'error' : 'primary'
          }
          icon={
            <CalendarWithNumberIcon
              tooltipLabel={`Lejárat: ${new Date(
                params.row.dueDate
              ).toLocaleDateString()}`}
              number={
                moment(new Date(params.row.dueDate)).diff(
                  moment(new Date()),
                  'days'
                ) * -1
              }
            />
          }
          label="Nincs kifizetve"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus !== EmailStatuses.Sent &&
      params.row.lastEmailStatus !== EmailStatuses.Opened &&
      params.row.lastEmailStatus !== EmailStatuses.Delivered
    ) {
      actions.push(
        <GridActionsCellItem
          color={'error'}
          icon={
            <Tooltip
              title={`${translateEmailStatusName(params.row.lastEmailStatus)}`}
            >
              <UnsubscribeIcon />
            </Tooltip>
          }
          label="Sikerleten"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Delivered
    ) {
      actions.push(
        <GridActionsCellItem
          color={'primary'}
          icon={
            <Tooltip title="Megérkezett">
              <MarkEmailReadIcon />
            </Tooltip>
          }
          label="Megérkezett"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Opened
    ) {
      actions.push(
        <GridActionsCellItem
          color={'success'}
          icon={
            <Tooltip title="Megnyitva">
              <DraftsIcon fontSize="medium" />
            </Tooltip>
          }
          label="Megnyitva"
          onClick={() => {}}
        />
      );
    }
    if (
      params.row.lastEmailStatus &&
      params.row.lastEmailStatus === EmailStatuses.Sent
    ) {
      actions.push(
        <GridActionsCellItem
          color={'warning'}
          icon={
            <Tooltip title="Elküldve">
              <MailOutlineIcon fontSize="medium" />
            </Tooltip>
          }
          label="Elküldve"
          onClick={() => {}}
        />
      );
    }
    if (!params.row.lastEmailStatus) {
      actions.push(
        <GridActionsCellItem
          icon={
            <Tooltip title="Nincs kiküldve">
              <MailOutlineIcon fontSize="medium" />
            </Tooltip>
          }
          label="Nincs kiküldve"
          onClick={() => {}}
        />
      );
    }
    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'getHeaders',
      type: 'actions',
      align: 'center',
      flex: 50,
      getActions: getHeaders,
    } as GridActionsColDef,

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 100,
      valueFormatter: (params: any) => {
        return;
      },
      renderCell(params) {
        if (!params.row.clientId) {
          return 'Nincs';
        }
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
            disableLink={externalRows}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'id',
      headerName: 'Azonosító',
      flex: 30,
    },
    {
      field: 'invoiceNumber',
      headerName: 'Számlaszám',
      flex: 100,
      valueFormatter: (params: any) => {
        return !!params.value ? params.value : 'Nincs';
      },
    },
    { field: 'description', headerName: 'Megjegyzés', flex: 100 },
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueGetter: getDate,
      valueFormatter: (params: any) => {
        return parseJSON(params.value).toLocaleString();
      },
    },
    {
      field: 'finalPrice',
      headerName: 'Összeg',
      flex: 100,

      valueGetter: (params: any) => {
        return formatCurrency(params.row.finalPrice, params.row.currency);
      },
    },
    {
      field: 'invoiceType',
      headerName: 'Típus',
      flex: 100,
      valueGetter: (params: any) => {
        return params.value === InvoiceTypes.Invoice
          ? 'Számla'
          : params.value === InvoiceTypes.Cancellation
            ? 'Sztornó'
            : params.value === InvoiceTypes.Modification
              ? 'Módosító'
              : params.value === InvoiceTypes.DepositInvoice
                ? 'Előlegszámla'
                : params.value === InvoiceTypes.FeeColleciton
                  ? 'Díjbekérő'
                  : params.value === InvoiceTypes.WayBill
                    ? 'Szállítólevél'
                    : params.value === InvoiceTypes.Receipt
                      ? 'Nyugta'
                      : '';
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 120,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ].filter((x) => (showBookings ? x.field !== 'finalPrice' : true));

  const handleDelete = (row: any, reason: any) => {
    if (checkPermission(['OrderWithInvoiceDelete'])) {
      const id = parseInt(row.id);
      const deleteLogically =
        row.invoiceNumber?.length > 0 && !showDeletedItems;
      orderService.delete(id, deleteLogically, reason).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres törlés', {
            variant: 'success',
          });
          setRows(rows.filter((row) => row.id !== id));
          if (parseInt(response.result) > 0) {
            setOrderPDFDialog({
              open: true,
              orderId: parseInt(response.result),
            });
          }
        }
      });
    }
  };

  const connection = useSignalREffect('emailHub');
  const handleEmailChanged = useCallback(
    (newStatus: number, emailId: number, openetAt: Date | null) => {
      setRows((prevRows) => {
        return prevRows.map((row) => {
          let found = row.orderEmails.find(
            (email: any) => email.id === emailId
          );
          if (found) {
            found.status = newStatus as EmailStatuses;
            found.openedAt = new Date(openetAt);
            row.lastEmailStatus = newStatus as EmailStatuses;
          }
          return row;
        });
      });
    },
    []
  );

  useEffect(() => {
    if (!connection) return;

    var callback = handleEmailChanged;
    connection.on('EmailStatusChanged', callback);

    return () => {
      connection.off('EmailStatusChanged', callback);
    };
  }, [connection, handleEmailChanged]);

  function base64ToArrayBuffer(_base64Str) {
    var binaryString = window.atob(_base64Str);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const handleAddInvoice = (orderId: number, invoiceInfo: any) => {
    dispatch({ type: 'SHOW_QUERY' });
    orderService
      .addInvoice({
        id: orderId,
        invoiceInfo,
        clientId: invoiceInfo.clientId,
        companyId: invoiceInfo.companyId,
        paymentType: invoiceInfo.paymentType,
        isElectronic: invoiceInfo.isElectronic,
        paid: invoiceInfo.paid,
        invoiceNumberPrefix:
          documentBlocks.find((x) => x.id === invoiceInfo.documentBlockId)
            ?.prefix ?? '',
        documentBlockId: invoiceInfo.documentBlockId,
        dueDate: invoiceInfo.dueDate,
        fulFillmentDate: invoiceInfo.fulFillmentDate,
        addressId: invoiceInfo.addressId,
      })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres számla kiállítás!', {
            variant: 'success',
          });
          setInvoiceInfoDialogOpen({ open: false, orderId: 0 });
          if (response.result && response.result.content) {
            var byte = base64ToArrayBuffer(response.result.content);
            var blob = new Blob([byte], {
              type: response.result.contentType,
            });
            window.open(URL.createObjectURL(blob), '_blank');
          }
          setReload(!reload);
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Megrendelések{titleDescriptor}</h2>
        </Grid>
        {!externalRows && (
          <>
            <Grid item xs={12}>
              <DatePickerHeader
                selectedInterval={selectedInterval}
                setSelectedInterval={setSelectedInterval}
                tabValue={tabValue}
                setTabValue={setTabValue}
                netGrossPicker={false}
                localStorageKey={'OrderPage'}
              />
            </Grid>
            <Grid item>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="appearedOnLocation"
                  row
                  name="appearedOnLocation"
                  value={selectedFilterType}
                  onChange={(event) =>
                    setSelectedFilterType(event.target.value as string)
                  }
                >
                  <FormControlLabel
                    value="filterDateByCreation"
                    control={<Radio />}
                    label="Kiállítás dátuma"
                  />
                  <FormControlLabel
                    value="filterDateByFullfilment"
                    control={<Radio />}
                    label="Teljesítés dátuma"
                  />
                  <FormControlLabel
                    value="filterDateByPaid"
                    control={<Radio />}
                    label="Fizetés dátuma"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  marginBottom={2}
                >
                  <RadioGroup
                    row
                    value={
                      showSales
                        ? 'sales'
                        : showBookings
                          ? 'bookings'
                          : 'deleted'
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      setShowSales(value === 'sales');
                      setShowBookings(value === 'bookings');
                      setShowDeletedItems(value === 'deleted');
                    }}
                  >
                    <FormControlLabel
                      value="sales"
                      control={<Radio color="primary" />}
                      label="Eladások"
                    />
                    <FormControlLabel
                      value="bookings"
                      control={<Radio color="primary" />}
                      label="Foglalások"
                    />
                    <FormControlLabel
                      value="deleted"
                      control={<Radio color="primary" />}
                      label="Sztornó"
                    />
                  </RadioGroup>
                </Stack>
              </Grid>
              <Grid item>
                <Grid item container>
                  {checkPermission(['BillingoInvoiceSync']) && (
                    <Tooltip title="Billingo számlák szinkronizálása">
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setSyncDialog(true);
                          }}
                          color="primary"
                        >
                          <Sync />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                  )}
                  {checkPermission(['SzamlazzhuInvoiceSync']) && (
                    <Tooltip title="Számlázz.hu számlák szinkronizálása">
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setSzamlazzhuSyncDialog(true);
                          }}
                          color="primary"
                        >
                          <Sync />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows.filter((x) => x.isBooking === showBookings)}
            columns={columns}
            localStorageKey={'OrdersPage'}
            getRowId={(row) => `${row.id}-${row.isBooking}-${row.isDeleted}`}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.Order}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'OrdersPage'}
        />
      </Grid>
      <OrderEmailDialog
        open={emailDialog.open}
        setOpen={setEmailDialog}
        orderId={emailDialog.id}
        onSent={(result) => {
          setRows((prevRows) => {
            return prevRows.map((row) => {
              if (row.id === emailDialog.id) {
                row.lastEmailStatus = EmailStatuses.Sent;
                row.orderEmails.push(result);
              }
              return row;
            });
          });
        }}
      />
      <Dialog open={syncDialog} onClose={() => setSyncDialog(false)}>
        <DialogTitle>Szinkronizáció kezdő időpontja:</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={2}>
            <Grid item xs={12}>
              <DatePicker
                value={dateOfSync}
                onChange={(value) => {
                  setDateOfSync(value);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch({ type: 'SHOW_QUERY' });
              billingoService
                .syncInvoices(dateOfSync.toISOString())
                .then((e) => {
                  enqueueSnackbar('Sikeres szinkronizáció!', {
                    variant: 'success',
                  });
                  setSyncDialog(false);
                  setTimeout(() => {
                    setReload(!reload);
                  }, 1000);
                })

                .catch((e) => {
                  enqueueSnackbar('Sikertelen szinkronizáció!', {
                    variant: 'error',
                  });
                })
                .finally(() => dispatch({ type: 'HIDE' }));
            }}
            variant="contained"
            disabled={!dateOfSync}
          >
            Szinkronizálás
          </Button>
          <Button
            onClick={() => {
              setSyncDialog(false);
            }}
            color="primary"
            variant="outlined"
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={szamlazzhuSyncDialog}
        onClose={() => setSzamlazzhuSyncDialog(false)}
      >
        <DialogTitle>Kérem adjon meg egy formátumot!</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={2}>
            <Grid item xs={12}>
              A szinkronizáció a megadott formátum szerint fog történni. Az
              formátum utolsó számától kezdve fogja szinkronizálni a számlákat.
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={szamlazzhuSyncFormat}
                onChange={(e) => setSzamlazzhuSyncFormat(e.target.value)}
                fullWidth
                label={'Formátum'}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Pl:AAA-2024-11 </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              szamlazzhuSync(szamlazzhuSyncFormat);
              setSzamlazzhuSyncDialog(false);
            }}
            variant="contained"
            disabled={!szamlazzhuSyncFormat}
          >
            Szinkronizálás
          </Button>
          <Button
            onClick={() => {
              setSzamlazzhuSyncDialog(false);
            }}
            color="primary"
            variant="outlined"
          >
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
      <OrderPDFDialog
        open={orderPDFDialog.open}
        onClose={() => setOrderPDFDialog({ open: false, orderId: 0 })}
        orderId={orderPDFDialog.orderId}
      />
      <InvoiceInfoDialog
        open={invoiceInfoDialogOpen.open}
        setOpen={(bool: boolean) => {
          setInvoiceInfoDialogOpen({ open: bool, orderId: 0 });
        }}
        invoiceNumberPrefixes={invoicePrefixes}
        clients={clients}
        onClientAdded={(client) => {
          dispatch(fromClients.loadClients());
        }}
        onSubmit={(invoiceInfo) =>
          handleAddInvoice(invoiceInfoDialogOpen.orderId, invoiceInfo)
        }
        documentBlocks={documentBlocks}
      />
      <InvoiceStornoDialog
        open={invoiceStornoDialog.open}
        setOpen={() => {
          setInvoiceStornoDialog({ open: false, entity: null });
        }}
        entity={invoiceStornoDialog.entity}
        onStorno={(entity, reason) => {
          handleDelete(entity, reason);
          setInvoiceStornoDialog({ open: false, entity: null });
        }}
      />
    </Paper>
  );
};

export default OrdersPage;
