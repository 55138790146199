import { useEffect, useState } from 'react';

export interface IBarcodedEntity {
  barcode: string;
}

export const useBarcodeScanner = (
  onBarcodeRead,
  items: IBarcodedEntity[] = [],
  minBarcodeLength: number = 5,
  isActive: boolean = true
) => {
  const [barcodeRead, setBarcodeRead] = useState({
    data: '',
    timeStamp: 0,
    item: null,
  });
  const barcode = {
    timing: 10,
    data: '',
  };

  const barcodeReaded = () => {
    if (barcode.data.length >= minBarcodeLength && barcode.data !== '0') {
      setBarcodeRead((state) => {
        var item = items.find((x) => x.barcode === barcode.data);
        onBarcodeRead({ data: barcode.data, timeStamp: barcode.timing, item });
        return { data: barcode.data, timeStamp: barcode.timing, item };
      });
    } else {
      barcode.data = '';
    }
  };

  let timeout = setTimeout(barcodeReaded, 500);

  const scanner = (e) => {
    if (!isActive) return;
    if (barcode.data.length === 0 || e.timeStamp - barcode.timing < 20) {
      barcode.data += e.key;
      barcode.timing = e.timeStamp;
      clearTimeout(timeout);
      timeout = setTimeout(barcodeReaded, 500);
    } else {
      barcode.data = '';
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', scanner);
    return () => {
      window.removeEventListener('keypress', scanner);
      clearTimeout(timeout);
    };
  }, [items, onBarcodeRead]);

  return { lastBarcode: barcodeRead };
};
