import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip, Badge } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  FileUpload,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import instrumentService from '../../../services/deviceManagement/instrumentService';
import { CompanyAssetStates } from '../../../types/InstrumentStates';
import computerService from '../../../services/deviceManagement/computerService';
import MoveAssetDialog from '../MoveAssetDialog';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import CompanyAssetLogDialog from '../CompanyAssetLogDialog';
import ArticleIcon from '@mui/icons-material/Article';
import companyAssetService from '../../../services/deviceManagement/companyAssetService';
import BuildIcon from '@mui/icons-material/Build';
import GppGoodIcon from '@mui/icons-material/GppGood';
import EngineeringIcon from '@mui/icons-material/Engineering';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilePageDialog from '../../../components/FilePageDialog';
import { ChimneySweepingCertificate } from '../../chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export interface Instrument {
  serialNumber: string;
  type: string;
  oSVersion: string;
  other: string;
  purchaseDate: Date;
}

const InstrumentPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [assetLogDialog, setAssetLogDialog] = useState<any>({
    open: false,
    companyAssetId: 0,
  });
  const dispatch = useDispatch<any>();
  const [moveAssetDialog, setMoveAssetDialog] = useState<any>({
    open: false,
    entity: { assetId: 0, userId: 0, companyId: 0, warehouseId: 0 },
  });
  const titleDescriptor = useFunctionDescriptor('InstrumentsPage.title');

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    instrumentService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '4 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon color="primary" />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    if (
      checkPermission(['CompanyAssetStateUpdate']) &&
      params.row.state !== CompanyAssetStates.WaitingForFix
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Megjelölés javításra váróként">
              <BuildIcon />
            </Tooltip>
          }
          label="Megjelölés javításra váróként"
          onClick={() =>
            companyAssetService
              .updateStatus({
                companyAssetId: params.row.companyAssetId,
                state: CompanyAssetStates.WaitingForFix,
              })
              .then((response) => {
                if (response.canceled) return;
                if (!response.hasError) {
                  enqueueSnackbar('Sikeres mozgatás', {
                    variant: 'success',
                  });
                  setRows(
                    rows.map((row) =>
                      row.id === params.row.id
                        ? {
                            ...row,
                            state: CompanyAssetStates.WaitingForFix,
                          }
                        : row
                    )
                  );
                } else
                  enqueueSnackbar(response.errorMessages.join(','), {
                    variant: 'error',
                  });
              })
          }
          showInMenu
        />
      );
    }
    if (
      checkPermission(['CompanyAssetStateUpdate']) &&
      params.row.state !== CompanyAssetStates.Working
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Megjelölés működőként">
              <GppGoodIcon />
            </Tooltip>
          }
          label="Megjelölés működőként"
          onClick={() =>
            companyAssetService
              .updateStatus({
                companyAssetId: params.row.companyAssetId,
                state: CompanyAssetStates.Working,
              })
              .then((response) => {
                if (response.canceled) return;
                if (!response.hasError) {
                  enqueueSnackbar('Sikeres mozgatás', {
                    variant: 'success',
                  });
                  setRows(
                    rows.map((row) =>
                      row.id === params.row.id
                        ? {
                            ...row,
                            state: CompanyAssetStates.Working,
                          }
                        : row
                    )
                  );
                } else
                  enqueueSnackbar(response.errorMessages.join(','), {
                    variant: 'error',
                  });
              })
          }
          showInMenu
        />
      );
    }
    if (
      checkPermission(['CompanyAssetStateUpdate']) &&
      params.row.state !== CompanyAssetStates.UnderFixing
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Megjelölés javítás alatt">
              <EngineeringIcon />
            </Tooltip>
          }
          label="Megjelölés javítás alatt"
          onClick={() =>
            companyAssetService
              .updateStatus({
                companyAssetId: params.row.companyAssetId,
                state: CompanyAssetStates.UnderFixing,
              })
              .then((response) => {
                if (response.canceled) return;
                if (!response.hasError) {
                  enqueueSnackbar('Sikeres mozgatás', {
                    variant: 'success',
                  });
                  setRows(
                    rows.map((row) =>
                      row.id === params.row.id
                        ? {
                            ...row,
                            state: CompanyAssetStates.UnderFixing,
                          }
                        : row
                    )
                  );
                } else
                  enqueueSnackbar(response.errorMessages.join(','), {
                    variant: 'error',
                  });
              })
          }
          showInMenu
        />
      );
    }
    if (
      checkPermission(['CompanyAssetStateUpdate']) &&
      params.row.state !== CompanyAssetStates.Scrapped
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Megjelölés selejtként">
              <RemoveCircleOutlineIcon />
            </Tooltip>
          }
          label="Megjelölés selejtként"
          onClick={() =>
            companyAssetService
              .updateStatus({
                companyAssetId: params.row.companyAssetId,
                state: CompanyAssetStates.Scrapped,
              })
              .then((response) => {
                if (response.canceled) return;
                if (!response.hasError) {
                  enqueueSnackbar('Sikeres mozgatás', {
                    variant: 'success',
                  });
                  setRows(
                    rows.map((row) =>
                      row.id === params.row.id
                        ? {
                            ...row,
                            state: CompanyAssetStates.Scrapped,
                          }
                        : row
                    )
                  );
                } else
                  enqueueSnackbar(response.errorMessages.join(','), {
                    variant: 'error',
                  });
              })
          }
          showInMenu
        />
      );
    }

    if (checkPermission(['CompanyAssetMove'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Mozgatás">
              <DriveFileMoveIcon />
            </Tooltip>
          }
          label="Mozgatás"
          onClick={() =>
            setMoveAssetDialog({
              open: true,
              entity: {
                assetId: params.row.companyAssetId,
                userId: params.row.holderId,
                companyId: params.row.companyId,
                warehouseId: params.row.warehouseId,
              },
            })
          }
          showInMenu
        />
      );
    }

    let readonly = !checkPermission(['InstrumentEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/erp/instruments/edit/${params.row.id}`)}
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Napló">
            <ArticleIcon />
          </Tooltip>
        }
        label={'Napló'}
        onClick={() =>
          setAssetLogDialog({
            open: true,
            companyAssetId: params.row.companyAssetId,
          })
        }
        showInMenu
      />
    );

    if (checkPermission(['InstrumentDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
          showInMenu
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'purchaseDate',
      headerName: 'Vásárlás dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 200,
    },
    { field: 'companyName', headerName: 'Cég', flex: 100 },
    { field: 'warehouseName', headerName: 'Raktár', flex: 200 },
    { field: 'holderName', headerName: 'Felelős', flex: 200 },
    {
      field: 'serialNumber',
      headerName: 'Szériaszám',
      headerClassName: 'title',
      flex: 100,
    },
    { field: 'name', headerName: 'Név', flex: 200 },
    { field: 'other', headerName: 'Egyéb ', flex: 200 },
    {
      field: 'state',
      headerName: 'Állapot',
      valueFormatter(params) {
        return params.value === CompanyAssetStates.Scrapped
          ? 'Selejtezett'
          : params.value === CompanyAssetStates.Working
            ? 'Működő'
            : params.value === CompanyAssetStates.WaitingForFix
              ? 'Javításra vár'
              : 'Javítás alatt';
      },
      flex: 200,
    },
    {
      field: 'tuvExpirationDate',
      headerName: 'TÜV lejárati dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 200,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    instrumentService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Műszerek{titleDescriptor}</h2>
        </Grid>
        {checkPermission(['InstrumentCreate']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Műszer létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/instruments/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'InstrumentsPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.Instrument}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'InstrumentsPage'}
        />
        <MoveAssetDialog
          open={moveAssetDialog.open}
          baseEntity={moveAssetDialog.entity}
          close={() => {
            setMoveAssetDialog({
              open: false,
              entity: { assetId: 0, userId: 0, companyId: 0, warehouseId: 0 },
            });
            dispatch({ type: 'SHOW_QUERY' });
            instrumentService
              .list()
              .then((response) => {
                if (response.canceled) return;
                if (!response.hasError) setRows(response.records);
                else
                  enqueueSnackbar(response.errorMessages.join(','), {
                    variant: 'error',
                  });
              })
              .finally(() => dispatch({ type: 'HIDE' }));
          }}
        />
        <CompanyAssetLogDialog
          open={assetLogDialog.open}
          assetId={assetLogDialog.companyAssetId}
          setOpen={setAssetLogDialog}
        />
      </Grid>
    </Paper>
  );
};

export default InstrumentPage;
