import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Grid,
  IconButton,
  Switch,
  Tab,
  Tabs,
  TextField,
  Button,
  useMediaQuery,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isValid,
  parseISO,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import moment, { invalid } from 'moment';
import { useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

export interface DatePickerHeaderProps {
  selectedInterval: any;
  setSelectedInterval: any;
  tabValue: number;
  setTabValue: any;
  netGrossPicker?: boolean;
  isGross?: boolean;
  setIsGross?: any;
  localStorageKey: string;
  setSelectedIntervalLocal?: () => void;
  setTabLocal?: () => void;
  setNullSelectedInterval?: boolean;
}

const DatePickerHeader = (props: DatePickerHeaderProps) => {
  const {
    selectedInterval,
    tabValue,
    setSelectedInterval,
    setTabValue,
    netGrossPicker = false,
    isGross = false,
    setIsGross,
    localStorageKey,
    setNullSelectedInterval = false,
  } = props;

  const isSmallScreen = useMediaQuery('(max-width:1400px)');
  const [now, setNow] = useState<Date>(new Date());
  const tabLocal = useLocalStorage(localStorageKey + 'DatePickerTabValue');
  const selectedIntervalLocal = useLocalStorage(
    localStorageKey + 'DatePickerSelectedInterval'
  );

  useEffect(() => {
    if (tabLocal.value !== null) {
      setTabValue(tabLocal.value);
      changeSelectedInterval(tabLocal.value);
    }
  }, [tabLocal.value]);

  useEffect(() => {
    if (
      selectedIntervalLocal.value &&
      selectedIntervalLocal.value.selectedInterval !== selectedInterval &&
      moment(selectedIntervalLocal.value.date).isSame(moment(now), 'day')
    ) {
      let startDate = null;
      let endDate = null;
      if (selectedIntervalLocal.value.selectedInterval?.startDate !== null) {
        startDate = moment(
          selectedIntervalLocal.value.selectedInterval?.startDate
        ).toDate();
      }
      if (selectedIntervalLocal.value.selectedInterval?.endDate !== null) {
        endDate = moment(
          selectedIntervalLocal.value.selectedInterval?.endDate
        ).toDate();
      }
      setSelectedInterval({
        startDate: startDate,
        endDate: endDate,
      });
    }
  }, [selectedIntervalLocal.value]);

  const changeSelectedInterval = (tabValue: any) => {
    const nowstart = moment(now).startOf('day').toDate();
    const nowend = moment(now).endOf('day').toDate();
    switch (tabValue) {
      case 0:
        setAndSaveSelectedInterval({
          startDate: nowstart,
          endDate: nowend,
        });
        break;
      case 1:
        const startOfWeekDate = startOfWeek(nowstart, {
          weekStartsOn: 1,
        });
        const endOfWeekDate = endOfWeek(nowend, {
          weekStartsOn: 1,
        });
        setAndSaveSelectedInterval({
          startDate: startOfWeekDate,
          endDate: endOfWeekDate,
        });
        break;
      case 2:
        const startOfMonthDate = startOfMonth(nowstart);
        const endOfMonthDate = endOfMonth(nowend);
        setAndSaveSelectedInterval({
          startDate: startOfMonthDate,
          endDate: endOfMonthDate,
        });
        break;
      case 3:
        const quarterStart = startOfQuarter(nowstart);
        const quarterEnd = endOfQuarter(nowend);
        setAndSaveSelectedInterval({
          startDate: quarterStart,
          endDate: quarterEnd,
        });
        break;
      case 4:
        const startOfYearDate = startOfYear(nowstart);
        const endOfYearDate = endOfYear(nowend);
        setAndSaveSelectedInterval({
          startDate: startOfYearDate,
          endDate: endOfYearDate,
        });
        break;
      case 5:
        setAndSaveSelectedInterval({
          startDate: null,
          endDate: null,
        });
        break;
      default:
        break;
    }
  };

  const setAndSaveTab = (value: any) => {
    tabLocal.setLocalStorageValue(value);
    setTabValue(value);
    changeSelectedInterval(value);
  };

  const setAndSaveSelectedInterval = (value: any) => {
    selectedIntervalLocal.setLocalStorageValue({
      selectedInterval: value,
      date: now,
    });
    setSelectedInterval(value);
  };

  return (
    <Box
      sx={{
        borderBottom: 0,
        borderColor: 'divider',
        pb: 2,
        maxWidth: isSmallScreen ? '280px' : '100%',
      }}
    >
      <Grid container>
        <Grid item xs={1} xl={'auto'}>
          <IconButton
            disabled={tabValue === 5}
            onClick={() => {
              var subType =
                tabValue === 0
                  ? 'day'
                  : tabValue === 1
                    ? 'week'
                    : tabValue === 2
                      ? 'month'
                      : tabValue === 3
                        ? 'quarter'
                        : tabValue === 4
                          ? 'year'
                          : 'month';
              setAndSaveSelectedInterval({
                startDate: moment(selectedInterval.startDate)
                  .subtract(1, subType as any)
                  .toDate(),
                endDate:
                  tabValue === 1
                    ? moment(selectedInterval.endDate)
                        .subtract(1, subType as any)
                        .toDate()
                    : moment(selectedInterval.endDate)
                        .subtract(1, subType as any)
                        .endOf(subType as any)
                        .toDate(),
              });
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} xl={'auto'}>
          <Tabs
            value={tabValue}
            onChange={(e, value) => {
              if (value !== (setNullSelectedInterval ? 6 : 5)) {
                setAndSaveTab(value);
              }
            }}
            TabIndicatorProps={{ style: { background: '#1976d2' } }}
            sx={{
              maxWidth: isSmallScreen ? '280px' : '100%',
              overflowX: 'scroll',
            }}
            scrollButtons="auto"
            variant="scrollable"
          >
            <Tab value={0} label="Napi" sx={{ fontWeight: 'bold' }} />
            <Tab value={1} label="Heti" sx={{ fontWeight: 'bold' }} />
            <Tab value={2} label="Havi" sx={{ fontWeight: 'bold' }} />
            <Tab value={3} label="Negyed év" sx={{ fontWeight: 'bold' }} />
            <Tab value={4} label="Év" sx={{ fontWeight: 'bold' }} />
            {setNullSelectedInterval && (
              <Tab value={5} label="Összes" sx={{ fontWeight: 'bold' }} />
            )}
          </Tabs>
        </Grid>
        <Grid item xs={1} xl={'auto'}>
          <IconButton
            disabled={tabValue === 5}
            onClick={() => {
              var subType =
                tabValue === 0
                  ? 'day'
                  : tabValue === 1
                    ? 'week'
                    : tabValue === 2
                      ? 'month'
                      : tabValue === 3
                        ? 'quarter'
                        : 'year';
              setAndSaveSelectedInterval({
                startDate: moment(selectedInterval.startDate)
                  .add(1, subType as any)
                  .toDate(),
                endDate:
                  tabValue === 1
                    ? moment(selectedInterval.endDate)
                        .add(1, subType as any)
                        .toDate()
                    : moment(selectedInterval.endDate)
                        .add(1, subType as any)
                        .endOf(subType as any)
                        .toDate(),
              });
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
        <Grid item pb={2}>
          <Grid container item spacing={2} alignItems={'center'}>
            <Grid item xs={12} xl={'auto'} textAlign={'center'}>
              <DatePicker
                value={selectedInterval.startDate}
                onChange={(value) => {
                  if (isValid(new Date(value))) {
                    setAndSaveSelectedInterval({
                      ...selectedInterval,
                      startDate: value,
                    });
                  }
                }}
                label="Kezdő dátum"
                inputFormat="yyyy-MM-dd"
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid item xs={12} xl={'auto'} textAlign={'center'}>
              <DatePicker
                value={selectedInterval.endDate}
                onChange={(value) => {
                  if (isValid(new Date(value))) {
                    setAndSaveSelectedInterval({
                      ...selectedInterval,
                      endDate: value
                        ? moment(value).endOf('day').toDate()
                        : null,
                    });
                  }
                }}
                label="Vég dátum"
                inputFormat="yyyy-MM-dd"
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            {netGrossPicker && (
              <>
                <Grid item xs={4} xl={'auto'} textAlign={'right'}>
                  Nettó
                </Grid>
                <Grid item xs={4} xl={'auto'} textAlign={'center'}>
                  <Switch
                    checked={isGross}
                    onChange={() => setIsGross(!isGross)}
                    color="primary"
                  />
                </Grid>
                <Grid item xs={4} xl={'auto'}>
                  Bruttó
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DatePickerHeader;
