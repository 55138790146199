import { Search } from '@mui/icons-material';
import { Grid, IconButton, TextField } from '@mui/material';
import { useState } from 'react';

const SearchQueryTextField = ({ ...props }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Prevent form submission (default behavior)
    props.setSearchQuery(searchQuery); // Trigger search query update
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder="Keresés..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton type="submit">
                <Search />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </form>
  );
};

export default SearchQueryTextField;
