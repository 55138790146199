import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WorkItemForm from './WorkItemForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import workItemService from '../../../services/erp/workItemService';
import clientService from '../../../services/crm/clientService';
import taskTypeService from '../../../services/erp/taskTypeService';
import userService from '../../../services/authority/userService';
import serviceService from '../../../services/erp/serviceService';
import priceCategoryService from '../../../services/pricing/priceCategoryService';
import warehouseService from '../../../services/wms/warehouseService';
import itemPriceService from '../../../services/pricing/itemPriceService';
import { WorkItemStatus } from './WorkItemForm';
import * as fromSystemConfiguration from '../../../stateManagement/thunks/systemConfigurationsThunk';
import workItemStatisticService from '../../../services/erp/workItemStatisticService';
import roleService from '../../../services/authority/roleService';
import permissionService from '../../../services/authority/permissionService';
import {
  reset,
  setForm,
} from '../../../stateManagement/actions/workItemActions';
import taxTypeService from '../../../services/erp/taxTypeService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import documentBlockService from '../../../services/billing/documentBlockService';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import abilityService from '../../../services/authority/abilityService';
import { Currency } from '../../../types/Currency';
import { DocumentLanguage } from '../../../types/DocumentLanguage';

const WorkItemEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [taskTypes, setTaskTypes] = useState<any[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const [services, setServices] = useState<string[]>([]);
  const [warehouses, setWarehouses] = useState<string[]>([]);
  const [priceCategories, setPriceCategories] = useState<string[]>([]);
  const [currentRolePermissionNames, setCurrentRolePermissionNames] =
    useState<any>(null);
  const [listPrices, setListPrices] = useState<string[]>([]);
  const [addresses, setAddresses] = useState<string[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const [invoicePrefixes, setInvoicePrefixes] = useState<any[]>([]);
  const user = useSelector((state: any) => state.user.userInfo);
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);
  const [representative, setRepresentative] = useState<any>({
    clientId: 0,
    representativeName: { firstName: '', lastName: '' },
    representativePhone: '',
  });
  const params = useParams();
  const { form, workItemInProgress } = useSelector<any>(
    (state) => state.workItem
  ) as any;

  const [entity, setEntity] = useState<any>(
    workItemInProgress
      ? form
      : {
          id: 0,
          priority: 0,
          description: '',
          comment: '',
          isPrivacyPolicyAgreedByClient: false,
          isContractTermsAgreedByClient: false,
          isPriceDifferenceAcceptedByClient: false,
          timeWindowStart: null,
          timeWindowEnd: null,
          reason: '',
          taskTypeId: null,
          assignedToId: null,
          clientId: null,
          companyId: null,
          itemPackages: [],
          workAddressId: null,
          priceCategoryId: null,
          warehouseId: null,
          selectedServices: [],
          selectedItems: [],
          selectedAssignments: [],
          hasOfferPriceDifference: false,
          offerPrice: 0,
          signature: '',
          billingAddressId: null,
          currency: Currency.HUF,
          invoiceLanguage: DocumentLanguage.Hu,
          conversionRate: 1,
          workItemStatistic: {
            workItemId: 0,
            wasAppropiateJobDone: false,
            whereDidClientFindUs: '',
            rating: 0,
          },
          workItemStatus: '',
        }
  );

  const { checkPermission } = useCheckPermission();

  const handleLoadItemPrices = (
    warehouseId: number,
    priceCategoryId: number,
    setFieldValue: any
  ) => {
    itemPriceService
      .listListPrices(warehouseId, priceCategoryId, true)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setListPrices(response.records);
          if (
            (form.priceCategoryId ?? entity.priceCategoryId) !== priceCategoryId
          ) {
            var clonedSelectedItems = [
              ...(form.selectedItems ?? entity.selectedItems),
            ];
            var updatedSelectedItems = clonedSelectedItems.map(
              (selectedItem) => {
                var matchedItem = response.records.find(
                  (item) => item.itemId === selectedItem.itemId
                );
                if (matchedItem) {
                  return {
                    ...selectedItem,
                    sellPriceGross: matchedItem.gross,
                    sellPriceNet: matchedItem.net,
                  };
                }
                return selectedItem;
              }
            );
            setFieldValue('selectedItems', [...updatedSelectedItems]);

            var clonedItemPackages = [
              ...(form.itemPackages ?? entity.itemPackages),
            ];

            let newitemPackages = clonedItemPackages.map((itemPackage) => {
              let sellPriceGross = 0;
              let sellPriceNet = 0;
              let newItems = itemPackage.items?.map((item) => {
                let found = response.records.find(
                  (y) => y.itemId === item.itemId
                );

                sellPriceGross += found?.gross ?? 0 * item?.amount ?? 0;
                sellPriceNet += found?.net ?? 0 * item?.amount ?? 0;
                return {
                  ...item,
                  sellPriceNet: found?.net ?? 0,
                  sellPriceGross: found?.gross ?? 0,
                };
              });
              itemPackage.services?.map((service) => {
                sellPriceGross +=
                  service?.servicePriceGross ?? 0 * service?.amount ?? 0;
                sellPriceNet +=
                  service?.servicePriceNet ?? 0 * service?.amount ?? 0;
              });
              return {
                ...itemPackage,
                items: newItems,
                sellPriceGross: sellPriceGross,
                sellPriceNet: sellPriceNet,
              };
            });
            setFieldValue('itemPackages', [...newitemPackages]);
          }
        }
      });
  };

  const handleClientSelected = (clientId: number) => {
    if (!(clientId > 0)) return;

    clientService.get(clientId).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setAddresses(response.result.addresses);
        setRepresentative({
          clientId: response.result.id,
          representativeName: response.result.representativeName,
          representativePhone: response.result.representativePhone,
        });
      }
    });
  };

  useEffect(() => {
    documentBlockService
      .list(DocumentBlockTypes.Invoice, user.userId)
      .then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setDocumentBlocks(response.records);
        }
      });
  }, [user]);

  useEffect(() => {
    serviceService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setServices(response.records);
      }
    });
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    taskTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaskTypes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (user.userId > 0) {
      userService.get(user.userId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          return enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
        setInvoicePrefixes(response.result?.invoicePrefixes?.split(';') ?? []);
      });
    }
  }, [user]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(false, null)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setClients(response.records);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  }, []);

  const onCompleteWithoutInvoice = (
    entity: any,
    setSubmitting: any,
    paymentType: any,
    companyId: any
  ) => {
    dispatch({ type: 'SHOW_SAVE' });
    if (representative) {
      clientService.updateRepresentative(representative).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      });
    }
    workItemService.update({ ...entity }).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        return workItemService
          .complete({
            ...entity,
            createInvoice: false,
            invoiceInfo: {},
            paymentType: paymentType,
            companyId: companyId,
            invoiceAfterwards: entity.invoiceAfterwards,
            conversionRate: entity.conversionRate,
            currency: entity.currency,
            invoiceLanguage: entity.invoiceLanguage,
          })
          .then((response) => {
            if (response.canceled) return;
            if (response.hasError) {
              setErrors(response.errorMessages);
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('Sikeres mentés!', {
                variant: 'success',
              });
              params.source === 'offer'
                ? navigate('/erp/offers')
                : params.source === 'ClosingSheet'
                  ? navigate('/erp/closingSheets')
                  : navigate('/erp/workItems');
            }
          })
          .finally(() => {
            dispatch({ type: 'HIDE' });
            dispatch(reset());
            setSubmitting(false);
          });
      }
    });
  };

  const completeSurvey = (entity: any) => {
    workItemService.update({ ...entity }).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
      dispatch(reset());
    });

    workItemService.completeWorkFlow(entity.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres művelet', {
          variant: 'success',
        });
        navigate('/erp/workItems');
      }
    });
  };

  const workItemClose = (id: number) => {
    dispatch({ type: 'SHOW_SAVE' });
    workItemService
      .close(id)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          params.source === 'offer'
            ? navigate('/erp/offers')
            : params.source === 'ClosingSheet'
              ? navigate('/erp/closingSheets')
              : navigate('/erp/workItems');
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        dispatch(reset());
      });
  };

  const addInvoice = async (
    id: any,
    invoiceInfo: any,
    conversionRate: any,
    currency: any,
    invoiceLanguage: DocumentLanguage
  ) => {
    dispatch({ type: 'SHOW_SAVE' });
    workItemService
      .addInvoice({
        id,
        invoiceInfo,
        conversionRate: conversionRate,
        currency: currency,
        invoiceLanguage: invoiceLanguage,

        clientId: invoiceInfo.clientId,
        companyId: invoiceInfo.companyId,
        paymentType: invoiceInfo.paymentType,
        isElectronic: invoiceInfo.isElectronic,
        paid: invoiceInfo.paid,
        invoiceNumberPrefix:
          documentBlocks.find((x) => x.id === invoiceInfo.documentBlockId)
            ?.prefix ?? '',
        documentBlockId: invoiceInfo.documentBlockId,
        dueDate: invoiceInfo.dueDate,
        fulFillmentDate: invoiceInfo.fulFillmentDate,
        addressId: invoiceInfo.addressId,
      })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          navigate('/erp/workItems');
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        dispatch(reset());
      });
  };

  const onCompleteWithInvoice = async (
    entity: any,
    setSubmitting: any,
    invoiceInfo: any
  ) => {
    dispatch({ type: 'SHOW_SAVE' });

    try {
      if (representative) {
        const response =
          await clientService.updateRepresentative(representative);
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      }

      const response = await workItemService.update({ ...entity });

      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        const completeResponse = await workItemService.complete({
          id: entity.id,
          invoiceInfo: invoiceInfo,
          createInvoice: true,
          invoiceAfterwards: false,
          conversionRate: entity.conversionRate,
          currency: entity.currency,
          invoiceLanguage: entity.invoiceLanguage,

          clientId: invoiceInfo.clientId,
          companyId: invoiceInfo.companyId,
          paymentType: invoiceInfo.paymentType,
          isElectronic: invoiceInfo.isElectronic,
          paid: invoiceInfo.paid,
          invoiceNumberPrefix:
            documentBlocks.find((x) => x.id === invoiceInfo.documentBlockId)
              ?.prefix ?? '',
          documentBlockId: invoiceInfo.documentBlockId,
          dueDate: invoiceInfo.dueDate,
          fulFillmentDate: invoiceInfo.fulFillmentDate,
          addressId: invoiceInfo.addressId,
        });

        if (completeResponse.hasError) {
          setErrors(completeResponse.errorMessages);
          enqueueSnackbar(completeResponse.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          dispatch(reset());
          params.source === 'offer'
            ? navigate('/erp/offers')
            : params.source === 'ClosingSheet'
              ? navigate('/erp/closingSheets')
              : navigate('/erp/workItems');
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      dispatch({ type: 'HIDE' });
      setSubmitting(false);
    }
  };

  const handleIncomplete = (
    entity: any,
    reason: string,
    setSubmitting: any
  ) => {
    dispatch({ type: 'SHOW_SAVE' });
    if (representative) {
      clientService.updateRepresentative(representative).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      });
    }
    return workItemService
      .update({
        ...entity,
        conversionRate: entity.conversionRate,
        currency: entity.currency,
        reason,
      })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          dispatch(reset());
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  const handleEvaluation = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    var newEntity = { ...entity };
    if (
      newEntity.roleName !== 'Dispatcher' ||
      newEntity.workItemStatus === WorkItemStatus.Closed
    ) {
      newEntity.workItemStatus = WorkItemStatus.Evaluated;
    }
    return workItemService
      .update({ ...newEntity })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          dispatch(reset());

          navigate('/erp/workItems');
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  const load = () => {
    if (parseInt(params.id) > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      workItemService
        .get(params.id)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            setErrors(response.errorMessages);
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setEntity(response.result);
            dispatch(setForm(response.result));
            if (response.result.clientId)
              handleClientSelected(response.result.clientId);
          }
        })
        .finally(() => {
          dispatch({ type: 'HIDE' });
        });
    }
  };
  useEffect(() => {
    if (!(parseInt(form.id) > 0) && parseInt(params.id) > 0) {
      load();
    }
  }, [params.id]);

  useEffect(() => {
    if (entity.roleId > 0) {
      var permissions;
      permissionService.list().then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          permissions = response;
          roleService.get(entity.roleId).then((response) => {
            if (response.canceled) return;
            if (response.hasError) {
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
            } else {
              setCurrentRolePermissionNames(
                response.result.permissions.map(
                  (x) => permissions?.find((y) => y.id === x).name
                )
              );
            }
          });
        }
      });
    }
  }, [entity.roleId]);

  useEffect(() => {
    if (entity.roleId) {
      priceCategoryService.listByRole(entity.roleId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setPriceCategories(response.records);
        }
      });
    }
  }, [entity.roleId]);

  return (
    <WorkItemForm
      entity={entity}
      employees={users}
      step={params.step}
      taskTypes={taskTypes}
      onTaskTypeAdded={(taskType) =>
        setTaskTypes((taskTypes) => [...taskTypes, taskType])
      }
      addresses={addresses}
      onAddressAdded={(address, clientId) => {
        setAddresses((addresses) => [...addresses, address]);
        setClients((clients) =>
          clients.map((x) =>
            x.id === clientId
              ? { ...x, addresses: [...x.addresses, address] }
              : x
          )
        );
      }}
      clients={clients}
      taxes={taxes}
      services={services}
      source={params.source}
      representative={representative}
      currentRolePermissionNames={currentRolePermissionNames}
      setRepresentative={setRepresentative}
      warehouses={warehouses}
      listPrices={listPrices}
      addInvoice={addInvoice}
      documentBlocks={documentBlocks}
      handleIncomplete={handleIncomplete}
      completeSurvey={completeSurvey}
      priceCategories={priceCategories}
      currentUser={user}
      onServiceAdded={(service) =>
        setServices((services) => [...services, service])
      }
      onClientAdded={(client) => {
        if (clients.find((x) => x.id === client.id)) {
          setClients((clients) =>
            clients.map((x) => (x.id === client.id ? client : x))
          );
        } else {
          setClients((clients) => [...clients, client]);
        }
      }}
      onClientChange={handleClientSelected}
      loadItemPrices={handleLoadItemPrices}
      readonly={
        entity.workItemStatus === WorkItemStatus.Closed ||
        entity.workItemStatus === WorkItemStatus.Evaluated ||
        (!checkPermission(['EditNotAssignedWorkItem']) &&
          user.userId !== entity.assignedToId) ||
        !checkPermission(['WorkItemEdit'])
      }
      onCompleteWithInvoice={onCompleteWithInvoice}
      onCompleteWithoutInvoice={onCompleteWithoutInvoice}
      workItemClose={workItemClose}
      invoiceNumberPrefixes={invoicePrefixes}
      errors={errors}
      loadWorkItem={load}
      handleCancel={() => {
        dispatch(reset());
        params.source === 'offer'
          ? navigate('/erp/offers')
          : params.source === 'ClosingSheet'
            ? navigate('/erp/closingSheets')
            : navigate('/erp/workItems');
      }}
      handleEvaluation={handleEvaluation}
    />
  );
};

export default WorkItemEdit;
