import { Autocomplete, Grid, InputAdornment, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import useCheckPermission from '../../hooks/useCheckPermission';
import ItemCreate from '../wms/items/ItemCreate';
import { Currency } from '../../types/Currency';
import { currencyAdornment } from '../../utils/valueFormatters';

export default function AddNewItemDialog(props: any) {
  const {
    open,
    setOpen,
    disableAmountCheck = false,
    addToCart,
    cart,
    items,
    getAvailableAmount,
    selectUnpriced = false,
    itemScanned,
    externalTaxTypeId = null,
    taxes,
    workItem = null,
    canOverrideGrossPrice = false,
    canAddCustomItem = true,
    currency = Currency.HUF,
    conversionRate = 1,
  } = props;
  const [item, setItem] = React.useState({
    itemId: 0,
    itemName: '',
    itemAlternateName: '',
    itemNumber: '',
    amount: 0,
    discount: 0,
    sellPriceGross: 0,
    sellPriceNet: 0,
    taxTypeId: 0,
    comment: '',
    isComplexItem: false,
    parts: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setItem({
      itemId: 0,
      itemName: '',
      itemAlternateName: '',
      itemNumber: '',
      amount: 0,
      discount: 0,
      sellPriceGross: 0,
      sellPriceNet: 0,
      taxTypeId: 0,
      comment: '',
      isComplexItem: false,
      parts: [],
    });
    setOpen(false);
  };

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (itemScanned !== undefined && itemScanned !== null) {
      setItem({
        ...itemScanned,
        amount: 1,
        discount: 0,
        taxTypeId: taxes[0]?.id ?? 0,
        sellPriceGross: itemScanned.net * (1 + (taxes[0]?.value ?? 27) / 100),
        sellPriceNet: itemScanned.net,
      });
    }
  }, [itemScanned]);

  const searchItems = (searchTerm) => {
    // Convert the search term to lowercase for case-insensitive search
    const searchTermLower = searchTerm?.toLowerCase();

    // Perform a fuzzy search by splitting the search term into tokens
    const searchTokens = searchTermLower.split(/\s+/);

    // Filter items based on the search tokens
    const filteredItems = items
      ?.filter((x) => selectUnpriced || getAvailableAmount(x.itemId, cart) > 0)
      .filter((item) => {
        // Convert the item name to lowercase
        const itemNameLower =
          item.itemNumber?.toLowerCase() +
          item.itemName?.toLowerCase() +
          item.barcode?.toLowerCase();
        // Check if each token is present in the item name
        return searchTokens.every((token) => itemNameLower.includes(token));
      });

    return filteredItems?.slice(0, 200);
  };
  const isCustomItem = item?.itemId < 0;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Termék felvétele</DialogTitle>
      <DialogContent style={{ height: '500px' }}>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          {!(item.itemId < 0) && (
            <Grid item xs={12}>
              <CreateEntityDecorator
                hideAdd={!checkPermission(['ItemCreate']) || !selectUnpriced}
                AutocompleteComponent={
                  <Autocomplete
                    disablePortal
                    id="itemId"
                    value={item ?? ''}
                    onChange={(event, value: any) => {
                      if (value == null) {
                        setItem({
                          itemId: 0,
                          itemName: '',
                          itemAlternateName: '',
                          itemNumber: '',
                          amount: 0,
                          discount: 0,
                          sellPriceGross: 0,
                          sellPriceNet: 0,
                          taxTypeId: 0,
                          comment: '',
                          isComplexItem: false,
                          parts: [],
                        });
                        return;
                      }
                      setItem({
                        ...value,
                        sellPriceNet: value?.net / conversionRate,
                        amount: 1,
                        isComplexItem: value?.isComplexItem,
                        parts: value?.parts,
                        discount: 0,
                        taxTypeId:
                          externalTaxTypeId == null
                            ? (taxes[0]?.id ?? 0)
                            : externalTaxTypeId,
                        sellPriceGross:
                          (value.net *
                            (1 +
                              (externalTaxTypeId == null
                                ? (taxes[0]?.value ?? 27)
                                : (taxes.find((g) => g.id === externalTaxTypeId)
                                    ?.value ?? 27)) /
                                100)) /
                          conversionRate,
                      });
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.itemId === value.itemId;
                    }}
                    filterOptions={(options, state) => {
                      const filtered = searchItems(state.inputValue);
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      var item = items.find((g) => g.itemId === option.itemId);

                      if (item == null || item === undefined) {
                        return '';
                      }

                      return `${item.itemName} (${
                        item.barcode
                      }) - Elérhető: ${getAvailableAmount(
                        item.itemId,
                        cart
                      )} db`;
                    }}
                    options={items?.filter(
                      (x) =>
                        selectUnpriced || getAvailableAmount(x.itemId, cart) > 0
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Termék"
                        fullWidth
                      />
                    )}
                  />
                }
                onSave={(item) => {
                  addToCart({
                    id: item.id,
                    itemId: item.id,
                    itemName: item.name,
                    itemAlternateName: item.itemAlternateName,
                    itemNumber: item.itemNumber,
                    isComplexItem: item.isComplexItem,
                    parts: item.parts,
                    amount: 1,
                    discount: 0,
                    sellPriceGross: 0,
                    sellPriceNet: 0,
                  });
                }}
                CreateEntityComponent={ItemCreate}
              />
            </Grid>
          )}
          {item.itemId < 0 && (
            <Grid item xs={12}>
              <TextField
                value={item.itemName}
                onChange={(event) => {
                  setItem({ ...item, itemName: event.target.value });
                }}
                label="Terkmék neve"
                disabled={item.itemId === 0}
                name="itemName"
                size="small"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              value={item.amount}
              onChange={(event) => {
                let amount = parseFloat(event.target.value);
                if (
                  !disableAmountCheck &&
                  !isCustomItem &&
                  (workItem !== null
                    ? workItem?.roleName === 'Mechanic'
                    : true) &&
                  amount >= 0 &&
                  amount > getAvailableAmount(item.itemId, cart)
                ) {
                  enqueueSnackbar(
                    'Hozzáadni kívánt mennyiség nem elérhető a kiválasztott raktárban vagy nulla!',
                    {
                      variant: 'error',
                    }
                  );
                  return;
                }
                setItem({ ...item, amount: amount });
              }}
              label="Darabszám"
              disabled={item.itemId === 0}
              name="amount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">db</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={item.sellPriceNet?.toFixed(0)}
              onChange={(event) => {
                let sellPriceNet = parseFloat(event.target.value);
                setItem({
                  ...item,
                  sellPriceNet: sellPriceNet,
                  sellPriceGross:
                    sellPriceNet *
                    (1 +
                      (taxes?.find((x) => x.id === item.taxTypeId)?.value ??
                        27) /
                        100),
                });
              }}
              label="Nettó ár"
              disabled={!canOverrideGrossPrice && item.itemId >= 0}
              name="sellPriceNet"
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    {currencyAdornment(currency)}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={item.sellPriceGross?.toFixed(0)}
              onChange={(event) => {
                let sellPriceGross = parseFloat(event.target.value);
                setItem({
                  ...item,
                  sellPriceGross: sellPriceGross,
                  sellPriceNet:
                    sellPriceGross /
                    (1 +
                      (taxes?.find((x) => x.id === item.taxTypeId)?.value ??
                        27) /
                        100),
                });
              }}
              label="Bruttó ár"
              disabled={!canOverrideGrossPrice && item.itemId >= 0}
              name="sellPriceGross"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    {currencyAdornment(currency)}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={item.discount}
              onChange={(event) => {
                let discount = parseFloat(event.target.value);
                if (
                  parseFloat(event.target.value) >= 0 &&
                  parseFloat(event.target.value) <= 100
                ) {
                  setItem({ ...item, discount: discount });
                }
              }}
              label="Kedvezmény"
              disabled={item.itemId === 0}
              name="discount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="taxTypeId"
              disabled={item.itemId === 0 || externalTaxTypeId !== null}
              value={item.taxTypeId ?? ''}
              onChange={(event, value: number) => {
                setItem({
                  ...item,
                  taxTypeId: value,
                  sellPriceGross:
                    item.sellPriceNet *
                    (1 +
                      (taxes?.find((x) => x.id === value)?.value ?? 27) / 100),
                });
              }}
              getOptionLabel={(option) =>
                taxes?.find((g) => g.id === option)?.name ?? ''
              }
              options={taxes?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="ÁFA típusa" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={item.comment}
              onChange={(event) => {
                setItem({ ...item, comment: event.target.value });
              }}
              label="Megjegyzés"
              disabled={item.itemId === 0}
              name="comment"
              multiline
              minRows={2}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !(item.itemId !== 0 && item.taxTypeId !== null) ||
            item.itemName === ''
          }
          onClick={() => {
            if (item.itemId !== 0 && item.taxTypeId !== null) {
              addToCart(item);
              setItem({
                itemId: 0,
                itemName: '',
                itemAlternateName: '',
                itemNumber: '',
                amount: 1,
                discount: 0,
                sellPriceGross: 0,
                sellPriceNet: 0,
                taxTypeId: 0,
                comment: '',
                isComplexItem: false,
                parts: [],
              });
            }
          }}
        >
          Hozzáadás
        </Button>
        {canAddCustomItem && (
          <Tooltip title="Egyedi tétel hozzáadása">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                let id = cart.map((x) => x.orderId < 0).length + 1;

                setItem({
                  itemId: -id,
                  itemName: 'Gyüjtő ' + id,
                  itemNumber: 'A-' + id.toString().padStart(3, '0'),
                  amount: 1,
                  discount: 0,
                  sellPriceGross: 0,
                  sellPriceNet: 0,
                  itemAlternateName: '',
                  taxTypeId: taxes[0]?.id ?? 0,
                  comment: '',
                  isComplexItem: false,
                  parts: [],
                });
              }}
            >
              Egyedi tétel
            </Button>
          </Tooltip>
        )}
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
