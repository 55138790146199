import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Grid, TextField, IconButton, Box } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import DataCard from './DataCard';
import SearchQueryTextField from './SearchQueryTextField';

const InfiniteScrollGrid = ({
  filteredRows,
  columns,
  exportToExcel,
  disableSearchBar,
  ...props
}) => {
  const [visibleRows, setVisibleRows] = useState(filteredRows.slice(0, 5)); // Initially load 5 rows
  const [hasMore, setHasMore] = useState(filteredRows.length > 5);
  const observerRef = useRef<HTMLDivElement | null>(null);

  // Function to load more rows
  const loadMoreRows = useCallback(() => {
    const currentLength = visibleRows.length;
    const newRows = filteredRows.slice(currentLength, currentLength + 5);

    if (newRows.length > 0) {
      setVisibleRows((prev) => [...prev, ...newRows]);
    }

    if (visibleRows.length + newRows.length >= filteredRows.length) {
      setHasMore(false); // Stop loading when all rows are loaded
    }
  }, [filteredRows, visibleRows.length]);

  useEffect(() => {
    if (filteredRows && filteredRows.length > 0) {
      setVisibleRows(filteredRows.slice(0, 5));
      setHasMore(filteredRows.length > 5);
    } else {
      setVisibleRows([]);
      setHasMore(false);
    }
  }, [filteredRows]);

  // IntersectionObserver to detect when the last item is visible
  useEffect(() => {
    if (!observerRef.current || !hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreRows();
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(observerRef.current);
    return () => observer.disconnect();
  }, [loadMoreRows, hasMore, filteredRows]);

  return (
    <Grid container spacing={2} paddingBottom={2}>
      {!disableSearchBar && (
        <Grid item xs={12}>
          <SearchQueryTextField setSearchQuery={props.setSearchQuery} />
        </Grid>
      )}

      {filteredRows.length > 0 && (
        <Grid item>
          <IconButton
            color="primary"
            onClick={() => exportToExcel(filteredRows)}
          >
            <GetApp />
          </IconButton>
        </Grid>
      )}

      {visibleRows.map((row, index) => (
        <Grid item xs={12} key={row.id}>
          {index === visibleRows.length - 2 && hasMore && (
            <div ref={observerRef} style={{ height: '10px' }} /> // Trigger load more
          )}
          <DataCard row={row} headers={columns} {...props} />
        </Grid>
      ))}

      {filteredRows.length === 0 && (
        <Box padding={2} textAlign="center">
          Nincs megjeleníthető adat
        </Box>
      )}
    </Grid>
  );
};

export default InfiniteScrollGrid;
