import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Form, Formik } from 'formik';
import ClientCreate from '../../crm/clients/ClientCreate';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import { DatePicker } from '@mui/x-date-pickers';
import { CheckBox } from '@mui/icons-material';

const ChimneyForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    chimneyTypes,
    clients,
  } = props;

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>Kémény {values.id > 0 ? 'szerkesztése' : 'létrehozása'}</h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={'auto'} p={1}>
                <TextField
                  label="Utolsó ellenőrzés éve"
                  value={values.lastControlYear ?? ''}
                  name="lastControlYear"
                  onChange={handleChange}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Használat kezdete"
                  value={values.usingSinceDate ?? ''}
                  onChange={(date) => setFieldValue('usingSinceDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={'auto'} p={1}>
                <TextField
                  label="Csoport/járat számjelzése"
                  value={values.groupNumber ?? ''}
                  name="groupNumber"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="chimneyTypeId"
                  disabled={readonly}
                  value={values.chimneyTypeId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('chimneyTypeId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = chimneyTypes?.find((g) => g.id === option);
                    if (found) {
                      return found.name;
                    } else return '';
                  }}
                  options={chimneyTypes?.map((x) => x.id) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Égéstermék elvezető típusa"
                      required
                      fullWidth
                      error={
                        !!touched.chimneyTypeId &&
                        !!validationErrors.chimneyTypeId
                      }
                      helperText={
                        !!touched.chimneyTypeId &&
                        !!validationErrors.chimneyTypeId &&
                        (validationErrors.chimneyTypeId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="clientId"
                  disabled={readonly}
                  value={values.clientId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('clientId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = clients.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${
                        found?.companyName === ''
                          ? found.name.fullName
                          : found.companyName
                      } (${found.taxNumber})}`;
                    } else return 'Nem található';
                  }}
                  options={clients.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ügyfél"
                      required
                      fullWidth
                      error={!!touched.clientId && !!validationErrors.clientId}
                      helperText={
                        !!touched.clientId &&
                        !!validationErrors.clientId &&
                        (validationErrors.clientId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="addressId"
                  disabled={readonly}
                  value={values.addressId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('addressId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = clients
                      .find((g) => g.id === values.clientId)
                      ?.addresses.find((g) => g.id === option);
                    if (found) {
                      return found.fullAddress;
                    } else return 'Nem található';
                  }}
                  options={
                    clients
                      .find((g) => g.id === values.clientId)
                      ?.addresses.map((x) => x.id) ?? []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cím"
                      required
                      fullWidth
                      error={
                        !!touched.addressId && !!validationErrors.addressId
                      }
                      helperText={
                        !!touched.addressId &&
                        !!validationErrors.addressId &&
                        (validationErrors.addressId as string)
                      }
                    />
                  )}
                />
              </Grid>
              {(chimneyTypes
                ?.find((g) => g.id === values.chimneyTypeId)
                ?.name?.startsWith('K') ||
                chimneyTypes
                  ?.find((g) => g.id === values.chimneyTypeId)
                  ?.name?.startsWith('N')) && (
                <Grid item xs={12}>
                  <TextField
                    label="Csatlakozó elem hossza"
                    value={values.connectionElementLength ?? ''}
                    name="connectionElementLength"
                    type="number"
                    onChange={(e) =>
                      setFieldValue('connectionElementLength', e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isInUse}
                      onChange={(e, checked) =>
                        setFieldValue('isInUse', checked)
                      }
                      color="primary"
                    />
                  }
                  label="Használatban van"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.hasBeenUsed}
                      onChange={(e, checked) =>
                        setFieldValue('hasBeenUsed', checked)
                      }
                      color="primary"
                    />
                  }
                  label="Volt-e már használatban"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isBanned}
                      onChange={(e, checked) =>
                        setFieldValue('isBanned', checked)
                      }
                      color="primary"
                    />
                  }
                  label="Tiltott-e a használata"
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ChimneyForm;
