import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
  GridRowModel,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  TextField,
  Autocomplete,
  Button,
  Tooltip,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  SwapHoriz,
  Edit as EditIcon,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import DataList from '../../components/DataList';
import AddNewItemDialog from './AddNewItemDialog';
import { useBarcodeScanner } from '../../components/barcode-scanner/useBarcodeScanner';

import warehouseService from '../../services/wms/warehouseService';
import warehouseTransactionService from '../../services/wms/warehouseTransactionService';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import EditItemDialog from './EditItemDialog';
import { CartItem } from '../sales/SalesPage';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useCheckPermission from '../../hooks/useCheckPermission';

const StockReplenishmentPage = () => {
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [addItemDialogOpen, setAddItemDialogOpen] = useState<boolean>(false);
  const [sourceWarehouseId, setSourceWarehouseId] = useState<number>(0);
  const [targetWarehouseId, setTargetWarehouseId] = useState<number>(0);
  const [items, setItems] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [cart, setCart] = useState<any[]>([]);
  const titleDescriptor = useFunctionDescriptor('StockReplenishmentPage.title');
  const { checkPermission } = useCheckPermission();
  const [editItemDialogOpen, setEditItemDialogOpen] = useState({
    open: false,
    row: { itemId: 0, itemName: '', amount: 0 },
  });

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const addCartItem = (item) => {
    var found = cart.find((x) => x.itemId === item.itemId);

    if (found) {
      if (getAvailableAmount(item.itemId) >= item.amount) {
        setCart((c) => [
          ...c.filter((x) => x.itemId !== item.itemId),
          {
            ...found,
            amount: found.amount + item.amount,
          },
        ]);
        setAddItemDialogOpen(false);
        return;
      }
    } else {
      if (getAvailableAmount(item.itemId) >= item.amount) {
        setCart((c) => [...c, { ...item }]);
        setAddItemDialogOpen(false);
        return;
      }
    }
    enqueueSnackbar('Nem áll rendelkezésre a kívánt mennyiség!', {
      variant: 'error',
    });
  };

  const handleBarcodeRead = (barcode) => {
    if (barcode.item) {
      addCartItem({ ...barcode.item, amount: 1 });
    } else {
      enqueueSnackbar(
        'Ismeretlen árucikk, ellenőrizze hogy van-e kiválasztott árkategória / raktár',
        {
          variant: 'error',
        }
      );
    }
  };

  useBarcodeScanner(handleBarcodeRead, items);

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  const fetchItems = () => {
    if (sourceWarehouseId > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      warehouseService
        .listItems(sourceWarehouseId, true)
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError) setItems(response.records);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  };

  useEffect(() => {
    fetchItems();
  }, [sourceWarehouseId]);

  useEffect(() => {
    let source = parseInt(
      window.localStorage.getItem('stockReplenishment.sourceWarehouseId')
    );
    if (!isNaN(source)) {
      setSourceWarehouseId(source);
    }
    let target = parseInt(
      window.localStorage.getItem('stockReplenishment.targetWarehouseId')
    );
    if (!isNaN(target)) {
      setTargetWarehouseId(target);
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'itemNumber',
      headerName: 'Cikkszám',
      headerClassName: 'title',
      flex: 100,
      editable: false,
      valueGetter: (params) =>
        items.find((x) => x.itemId === params.row.itemId)?.itemNumber ?? '',
    },
    {
      field: 'name',
      headerName: 'Termék',
      flex: 100,
      editable: false,
    },
    {
      field: 'brand',
      headerName: 'Márka',
      flex: 100,
      editable: false,
    },
    {
      field: 'itemGroup',
      headerName: 'Termékcsoport',
      flex: 100,
      editable: false,
    },
    {
      field: 'amount',
      headerName: 'Mennyiség',
      editable: true,
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 50,
      disableExport: true,
      getActions: (params: GridRowParams, color: any) => [
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          }
          label="Edit"
          onClick={() => {
            setEditItemDialogOpen({
              open: true,
              row: params.row,
            });
          }}
        />,
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          onClick={() => {
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné?',
              onConfirm: async () => {
                setCart((c) => [
                  ...c.filter((x) => x.itemId !== params.row.itemId),
                ]);
              },
            });
          }}
        />,
      ],
    } as GridActionsColDef,
  ];

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    setCart((c) => [...c.filter((x) => x.itemId !== oldRow.itemId), newRow]);
  };

  const processEditItem = (cartItem: CartItem) => {
    setCart((c) => [
      ...c.filter((x) => x.itemId !== cartItem.itemId),
      cartItem,
    ]);
  };

  const getAvailableAmount = (itemId: number) => {
    let warehouseAmount = 0;
    let found = items.find((x) => x.itemId === itemId);
    if (found) {
      if (found.isComplexItem) {
        let partCount = found.parts?.map((x) => {
          let available = getAvailableAmount(x.partItemId);

          return { count: Math.floor(available / x.partAmount) };
        }) ?? [{ count: 0 }];
        if (partCount.length > 0) {
          let minCount = Math.min(...partCount.map((x) => x.count));
          warehouseAmount = minCount;
        }
      } else {
        warehouseAmount = found.amount;
      }
    }
    if (cart?.length > 0) {
      let cartAmount = 0;

      if (!found.isComplexItem) {
        let complexItemsInCart = cart.filter(
          (x) => items.find((y) => x.itemId === y.itemId)?.isComplexItem
        );
        if (complexItemsInCart.length > 0) {
          complexItemsInCart.forEach((complexItem) => {
            let complexItemParts = items.find(
              (x) => x.itemId === complexItem.itemId
            )?.parts;
            complexItemParts?.forEach((part) => {
              if (part.partItemId === itemId) {
                cartAmount += part.partAmount * complexItem.amount;
              }
            });
          });
        }
        cartAmount += cart
          .filter((x) => x.itemId === itemId)
          .reduce((acc, cur) => (acc += cur.amount), 0);
      }

      return warehouseAmount - cartAmount;
    }
    return warehouseAmount;
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Árufeltöltés{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12} md={5}>
          <Autocomplete
            disablePortal
            id="sourceWarehouseId"
            value={sourceWarehouseId}
            onChange={(event, value) => {
              setSourceWarehouseId(value);
              window.localStorage.setItem(
                'stockReplenishment.sourceWarehouseId',
                value
              );
            }}
            getOptionLabel={(option) =>
              warehouses.find((g) => g.id === option)?.name ?? ''
            }
            options={warehouses
              .filter((x) => x.id !== targetWarehouseId)
              .map((g) => g.id)}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Honnan" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <Tooltip title="Csere">
            <IconButton
              color="primary"
              onClick={() => {
                let target = targetWarehouseId;
                let source = sourceWarehouseId;
                setSourceWarehouseId(target);
                setTargetWarehouseId(source);
              }}
            >
              <SwapHoriz fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} md={5}>
          <Autocomplete
            disablePortal
            id="warehouseId"
            value={targetWarehouseId}
            onChange={(event, value) => {
              setTargetWarehouseId(value);
              window.localStorage.setItem(
                'stockReplenishment.targetWarehouseId',
                value
              );
            }}
            getOptionLabel={(option) =>
              warehouses.find((g) => g.id === option)?.name ?? ''
            }
            options={warehouses
              .filter((x) => x.id !== sourceWarehouseId)
              .map((g) => g.id)}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Hova" />
            )}
          />
        </Grid>
        {checkPermission(['StockReplenishmentAddButton']) && (
          <Grid container item xs={12} justifyContent="end">
            <Grid item>
              <Tooltip title="Termék hozzáadása">
                <IconButton
                  color="primary"
                  onClick={() => setAddItemDialogOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={cart}
            columns={columns}
            getRowId={(row) => row.itemId}
            processRowUpdate={processRowUpdate}
            localStorageKey={'StockReplenishmentPage'}
          />
          <AddNewItemDialog
            open={addItemDialogOpen}
            setOpen={setAddItemDialogOpen}
            getAvailableAmount={getAvailableAmount}
            items={items}
            cart={cart}
            addToCart={addCartItem}
          />
          <EditItemDialog
            open={editItemDialogOpen.open}
            setOpen={setEditItemDialogOpen}
            getAvailableAmount={getAvailableAmount}
            items={items}
            onSubmit={processEditItem}
            row={editItemDialogOpen.row}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        sx={{ textAlign: { xs: 'center' } }}
        pb={3}
        spacing={2}
      >
        <Grid item xs={12} md="auto">
          <Button
            variant="contained"
            onClick={() => {
              dispatch({ type: 'SHOW_SAVE' });
              warehouseTransactionService
                .stockReplenishment({
                  targetWarehouseId,
                  sourceWarehouseId,
                  items: cart.map((x) => {
                    return {
                      itemId: x.itemId,
                      amount: x.amount,
                    };
                  }),
                })
                .then((response) => {
                  if (response.canceled) return;
                  if (!response.hasError) {
                    enqueueSnackbar('Sikeres árufeltöltés!', {
                      variant: 'success',
                    });
                    setCart([]);
                    fetchItems();
                  } else
                    enqueueSnackbar(response.errorMessages.join(','), {
                      variant: 'error',
                    });
                })
                .finally(() => dispatch({ type: 'HIDE' }));
            }}
          >
            Tranzakció inditása
          </Button>
        </Grid>
        <Grid item xs={12} md="auto">
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setCart([]);
            }}
          >
            Alaphelyzet
          </Button>
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default StockReplenishmentPage;
