import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import EntityNavigator from './EntityNavigator';
import { MoreVert } from '@mui/icons-material';

const DataCard = (props: any) => {
  const { row, headers, ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  if (!headers || headers.length === 0 || !row) {
    return <div>Nincs adat</div>;
  }

  var actions = headers.find((h) => h.field === 'actions');
  var getHeaders = headers.find((h) => h.field === 'getHeaders');
  var title = headers.find((h) => h.headerClassName === 'title');

  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        margin: 'auto',
        transition: '0.3s',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        boxShadow: '0 8px 40px -12px rgba(1,1,1,1)',
      }}
    >
      <CardHeader
        sx={{
          background: '#009be5',
          display: 'block',
          color: '#fff',
          boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
        }}
        action={
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            <Grid
              item
              xs={11}
              container
              justifyContent="center"
              alignItems="center"
            >
              {!title?.renderCell ? (
                <Typography
                  variant="h6"
                  component="span"
                  color="textPrimary"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: '#fff',
                    textAlign: 'center',
                    width: '100%',
                    pl: 1,
                  }}
                >
                  {(!title?.valueFormatter
                    ? !title?.valueGetter
                      ? !title?.renderCell
                        ? !title?._valueGetter
                          ? row[title?.field]
                          : null
                        : title?._valueGetter({ row: row })
                      : title?.valueGetter({ row: row })
                    : title?.valueFormatter({
                        id: row.id ? row.id : other.getRowId(row),
                        value: row[title?.field],
                      })) ?? ''}
                </Typography>
              ) : (
                <Grid item xs={12} textAlign={'center'}>
                  {title?.renderCell({ row, value: row[title?.field] })}
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={1}
              container
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                aria-label="actions"
                aria-controls="actions-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color={actions?.getActions({ row }, 'inherit')[0]?.props?.color}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                {actions?.getActions({ row }, 'inherit').map((x, index) => {
                  if (x.props.entityType && x.props.entityType > 0) {
                    return (
                      <MenuItem
                        key={`${row.id}-${index}`}
                        onClick={handleMenuClose}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ marginRight: 8, color: 'primary' }}>
                            <EntityNavigator
                              entityType={x.props.entityType}
                              entityId={x.props.entityId}
                              value={x.props.value}
                              isMobileView
                              showIcon={x.props.showIcon}
                              readonly={x.props.readonly}
                              onSave={(result) => {
                                x.props.onSave(result);
                              }}
                            />
                          </span>
                          {x?.props?.value}
                        </div>
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem
                        key={`${row.id}-${index}`}
                        onClick={() => {
                          x?.props?.onClick();
                          handleMenuClose();
                        }}
                      >
                        <Tooltip title={x?.props?.label}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {x?.props?.icon && (
                              <span
                                style={{ marginRight: 8, color: 'primary' }}
                              >
                                {React.cloneElement(x.props.icon, {
                                  color: 'primary',
                                })}
                              </span>
                            )}
                            {x?.props?.label}
                          </div>
                        </Tooltip>
                      </MenuItem>
                    );
                  }
                })}
              </Menu>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          justifyContent="center"
          sx={{ width: '100%', textAlign: 'center' }}
        >
          <Grid
            container
            justifyContent="center"
            sx={{ width: '100%', textAlign: 'center' }}
          >
            {getHeaders?.getActions({ row }, 'inherit').map((x, index) => {
              if (x.type.name === 'EntityNavigator') {
                return (
                  <Grid item key={`${row.id}-${index}`}>
                    <EntityNavigator
                      entityType={x.props.entityType}
                      entityId={x.props.entityId}
                      value={x.props.value}
                      isMobileView
                      showIcon={x.props.showIcon}
                      readonly={x.props.readonly}
                      onSave={(result: any) => {
                        x.props.onSave(result);
                      }}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item key={`${row.id}-${index}`}>
                    <Tooltip title={x?.props?.label}>
                      <IconButton
                        aria-label="settings"
                        onClick={() => x?.props?.onClick()}
                        color={x?.props?.color}
                      >
                        {x?.props?.icon}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                );
              }
            })}
          </Grid>
          {headers
            ?.filter(
              (c) => c.headerClassName !== 'title' && c.field !== 'getHeaders'
            )
            ?.map(
              (h) =>
                h.field !== 'actions' && (
                  <React.Fragment key={h.field}>
                    <Grid item xs={12} container textAlign="center">
                      {h.headerName && h.headerName?.length > 0 && (
                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            component="span"
                            color="textPrimary"
                            sx={{ fontWeight: 'bold', fontSize: '1rem' }}
                          >
                            {h.headerName}:
                          </Typography>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent={'center'}
                        p={0}
                      >
                        {!h.renderCell ? (
                          <TextField
                            value={
                              (!h.valueFormatter
                                ? !h.valueGetter
                                  ? !h.renderCell
                                    ? !h._valueGetter
                                      ? row[h.field]
                                      : null
                                    : h._valueGetter({ row: row })
                                  : h.valueGetter({ row: row })
                                : h.valueFormatter({
                                    id: row.id ? row.id : other.getRowId(row),
                                    value: row[h.field],
                                  })) ?? ''
                            }
                            multiline
                            title={h.headerName}
                            disabled
                            sx={{ border: 0 }}
                            fullWidth
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            inputProps={{
                              min: 0,
                              style: { textAlign: 'center' },
                            }}
                          />
                        ) : (
                          <Grid item textAlign={'center'}>
                            {h.renderCell({ row, value: row[h.field] })}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
            )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DataCard;
