import { Grid, Paper, Badge, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataList from '../../../components/DataList';
import EntityNavigator from '../../../components/EntityNavigator';
import '../../../css/dataGrid.css';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import { EntityTypes } from '../../../types/EntityTypes';
import { formatCurrency } from '../../../utils/valueFormatters';
import ChimneySweepingCertificatePickerDialog from './ChimneySweepingCertificatePickerDialog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import {
  parseJSON,
  endOfMonth,
  startOfMonth,
  eachMonthOfInterval,
  eachDayOfInterval,
} from 'date-fns';
import moment from 'moment';
import DatePickerHeader from '../../../components/DatePickerHeader';

const ChimneySweepingCertificateBillingPage = (props: any) => {
  const [rows, setRows] = useState<any[]>([]);
  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [now] = useState(new Date());
  const [tabValue, setTabValue] = useState<number>(2);

  const titleDescriptor = useFunctionDescriptor(
    'ChimneySweepingCertificateBillingPage.title'
  );
  const [pickerDialog, setPickerDialog] = useState({
    open: false,
    id: 0,
    clientId: 0,
  });
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: startOfMonth(moment(now).startOf('day').toDate()),
    endDate: endOfMonth(moment(now).endOf('day').toDate()),
  });

  const fetchData = async (signal?: any) => {
    const { startDate, endDate } = selectedInterval;

    // Parse the startDate and endDate to Date objects
    let start = new Date(startDate);
    let end = new Date(endDate);
    // Helper function to perform the query based on the list type
    const performQuery = async (start, end) => {
      return await chimneySweepingCertificateService.list(
        start,
        end,
        null,
        signal,
        false
      );
    };

    try {
      if (!endDate) {
        end = new Date();
      }
      let months = eachMonthOfInterval({ start, end });
      if (eachDayOfInterval({ start, end }).length < 28) {
        months = [start];
      }
      let allRecords = [];

      for (let i = months.length - 1; i >= 0; i--) {
        let isSameStartMonth =
          months[i].getMonth() === start.getMonth() &&
          months[i].getFullYear() === start.getFullYear();
        let isSameEndMonth =
          months[i].getMonth() === end.getMonth() &&
          months[i].getFullYear() === end.getFullYear();
        const monthStart =
          1 === months.length ? start : isSameStartMonth ? start : months[i];
        const monthEnd =
          i === months.length - 1
            ? end
            : isSameEndMonth
              ? end
              : endOfMonth(monthStart);
        const formattedStart = monthStart;
        const formattedEnd = monthEnd;
        if (i === months.length - 1) {
          dispatch({ type: 'SHOW_QUERY' });
        }
        const response = await performQuery(formattedStart, formattedEnd);

        if (response.canceled) {
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        if (response.hasError) {
          console.error('Query had errors:', response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        allRecords = allRecords.concat(response.records);
        setRows(allRecords);
        if (i === months.length - 1) {
          dispatch({ type: 'HIDE' });
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      enqueueSnackbar('An error occurred while fetching data.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Button
            variant="contained"
            color={color ? color : 'primary'}
            onClick={() => {
              setPickerDialog({
                open: true,
                id: params.row.id,
                clientId: params.row.clientId,
              });
            }}
            sx={{ padding: '0px 10px', borderRadius: '8px' }}
          >
            Számla kiállítása
          </Button>
        }
        label="Számla kiállítása"
        onClick={() => {
          setPickerDialog({
            open: true,
            id: params.row.id,
            clientId: params.row.clientId,
          });
        }}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleDateString()
          : 'Nincs';
      },
    },
    {
      field: 'documentNumber',
      headerClassName: 'title',
      headerName: 'Dokumentum száma',
      flex: 100,
      valueGetter(params) {
        return params.value ? parseInt(params.value) : params.value;
      },
    },
    {
      field: 'employee',
      headerName: 'Dolgozó',
      flex: 100,
      valueFormatter(params) {
        return params.value ? params.value.fullName : 'Nincs';
      },
    },

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 200,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    { field: 'clientPhone', headerName: 'Telefonszám', flex: 200 },
    { field: 'workAddress', headerName: 'Cím', flex: 200 },
    {
      field: 'finalPriceGross',
      headerName: 'Végösszeg(Bruttó)',
      flex: 200,
      valueFormatter(params) {
        return formatCurrency(params.value.toFixed(0));
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 200,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Számla nélküli tanúsítványok{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <DatePickerHeader
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            tabValue={tabValue}
            setTabValue={setTabValue}
            netGrossPicker={false}
            localStorageKey={'ChimneySweepingCertificateBillingPage'}
          />
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ChimneySweepingCertificateBillingPage'}
          />
        </Grid>
        <ChimneySweepingCertificatePickerDialog
          open={pickerDialog.open}
          clientId={pickerDialog.clientId}
          setOpen={setPickerDialog}
          chimneySweepingCertificateId={pickerDialog.id}
          onCreated={() => {
            fetchData();
          }}
        />
      </Grid>
    </Paper>
  );
};

export default ChimneySweepingCertificateBillingPage;
