import { Delete as DeleteIcon, FileUpload } from '@mui/icons-material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import {
  Autocomplete,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataList from '../../../components/DataList';
import FileUploadingDialog from '../../../components/FileUploadingDialog';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import fileAttachmentService from '../../../services/files/fileAttachmentService';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import { translateAttachmentTypesName } from '../../../utils/nameFormatters';
import EditIcon from '@mui/icons-material/Edit';
import FilesEdit from './FilesEdit';
import ClearIcon from '@mui/icons-material/Clear';
import { parseJSON } from 'date-fns';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const FilesPage = (props: any) => {
  const {
    attachmentType,
    entityUniqueId = null,
    isDialog = false,
    localStorageKey,
    onList = () => {},
    overrideFileName = null,
  } = props;
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('FilesPage.title');
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [fileEditDialog, setFileEditDialog] = useState({
    open: false,
    entity: null,
  });
  const [attachmentTypeState, setAttachmentTypeState] =
    useState<AttachmentTypes | null>(attachmentType ? attachmentType : null);

  const { checkPermission } = useCheckPermission();
  const sasToken = useSelector((state: any) => state.app.sasToken);
  const getLocalHasDocument = () => {
    const hasDocument = localStorage.getItem(
      `filesPage_hasDocument${window.location.hash}_${localStorageKey}`
    );
    return JSON.parse(hasDocument);
  };
  const [hasDocument, setHasDocument] = useState<boolean | null>(
    getLocalHasDocument()
  );

  const saveHasDocument = (hasDocument) => {
    localStorage.setItem(
      `filesPage_hasDocument${window.location.hash}_${localStorageKey}`,
      JSON.stringify(hasDocument)
    );
  };

  useEffect(() => {
    if (entityUniqueId) {
      setHasDocument(true);
      saveHasDocument(true);
    }
  }, [hasDocument]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    fileAttachmentService
      .list(attachmentTypeState, hasDocument, entityUniqueId)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setRows(response.records);
          onList(response.records.length);
        } else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, [hasDocument, trigger, attachmentTypeState, entityUniqueId]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Megnyitás">
            <FileOpenIcon />
          </Tooltip>
        }
        label={'Megnyitás'}
        onClick={() => {
          window.open(params.row.url + sasToken, '_blank');
        }}
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Letöltés">
            <DownloadForOfflineIcon />
          </Tooltip>
        }
        label={'Letöltés'}
        onClick={() => {
          // Extract the original filename from your database (replace 'originalFilename' with the actual property)
          const file = rows.find((x) => x.id === params.row.id);
          const originalFilename = file.fileName;

          const blobUrl = params.row.url + sasToken;
          const filename = originalFilename;
          fetch(blobUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.error('Error downloading file:', error));
        }}
      />
    );
    if (checkPermission(['UpdateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          }
          label={'Szerkesztés'}
          onClick={() => setFileEditDialog({ open: true, entity: params.row })}
        />
      );
    }

    if (
      checkPermission(['DetachFileAttachment']) &&
      attachmentType === AttachmentTypes.ChimneySweepingCertificate &&
      params.row.entityUniqueId !== null
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Leválasztás">
              <ClearIcon />
            </Tooltip>
          }
          label="Leválasztás"
          showInMenu
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan le szeretné választani a fájlt?',
              onConfirm: async () => handleDetach(params.row.id),
            })
          }
        />
      );
    }
    if (checkPermission(['DeleteFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          showInMenu
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Feltöltés dátuma',
      flex: 200,
      valueFormatter(params) {
        return parseJSON(params.value).toLocaleString();
      },
    },
    { field: 'fileName', headerName: 'Fájlnév', flex: 100 },
    { field: 'description', headerName: 'Leírás', flex: 100 },
    {
      field: 'type',
      headerName: 'Fájl típus',
      valueFormatter(params) {
        return translateAttachmentTypesName(params.value);
      },
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    fileAttachmentService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };
  const handleDetach = (id: number) => {
    fileAttachmentService.detach(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres leválasztás', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const handleFilePairing = () => {
    dispatch({ type: 'SHOW_QUERY' });
    fileAttachmentService
      .pairFileAttachments()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setTrigger(!trigger);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  return (
    <Paper elevation={isDialog ? 0 : 1} sx={{ width: '100%' }}>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Fájlok{titleDescriptor}</h2>
        </Grid>
        {!entityUniqueId && (
          <Grid item xs={12} md={3} pb={2} pr={2}>
            <Autocomplete
              value={hasDocument}
              onChange={(event, value) => {
                setHasDocument(value);
                saveHasDocument(value);
              }}
              options={[null, true, false]}
              getOptionLabel={(option) => {
                if (option === null) return 'Minden';
                else if (option === true) return 'Van dokumentum';
                else return 'Nincs dokumentum';
              }}
              renderInput={(params) => <TextField {...params} label="Szűrő" />}
            />
          </Grid>
        )}
        {!attachmentType && (
          <Grid item xs={12} md={3}>
            <Autocomplete
              disablePortal
              id="attachmentType"
              value={attachmentTypeState}
              onChange={(event, value) => {
                setAttachmentTypeState(value as AttachmentTypes);
              }}
              getOptionLabel={(option) => {
                if (option === null) return 'Minden';
                else {
                  return translateAttachmentTypesName(
                    option as AttachmentTypes
                  );
                }
              }}
              options={[
                null,
                ...Object.values(AttachmentTypes).filter((x) =>
                  Number.isFinite(x)
                ),
              ]}
              renderInput={(params) => (
                <TextField {...params} required label="Típus" />
              )}
            />
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          md={
            attachmentType ? (entityUniqueId ? 12 : 9) : entityUniqueId ? 9 : 6
          }
          p={2}
          justifyContent="end"
        >
          {attachmentTypeState ===
            AttachmentTypes.ChimneySweepingCertificate && (
            <Grid item>
              <Tooltip title="File párosítás">
                <IconButton color="primary" onClick={() => handleFilePairing()}>
                  <AutorenewIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {checkPermission(['CreateFileAttachment']) && (
            <Grid item>
              <Tooltip title="Fájl feltöltése">
                <IconButton
                  color="primary"
                  onClick={() => setFileUploadDialogOpen(true)}
                >
                  <FileUpload />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns.filter((x) =>
              attachmentType ? x.field !== 'type' : true
            )}
            localStorageKey={'filesPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <FileUploadingDialog
          open={fileUploadDialogOpen}
          onClose={() => {
            setFileUploadDialogOpen(false);
          }}
          onAllFileUploaded={() => {
            setTrigger(!trigger);
            setFileUploadDialogOpen(false);
          }}
          attachmentTypeState={attachmentTypeState}
          onSelect={(file) => {}}
          entityUniqueId={entityUniqueId}
          overrideFileName={overrideFileName}
        />
        <FilesEdit
          open={fileEditDialog.open}
          onClose={() => setFileEditDialog({ open: false, entity: null })}
          entity={fileEditDialog.entity}
          onSuccess={(entity) =>
            setRows(rows.map((x) => (x.id === entity.id ? entity : x)))
          }
        />
      </Grid>
    </Paper>
  );
};

export default FilesPage;
