import {
  Search,
  DoDisturb,
  CheckCircle,
  JoinFull,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import {
  GridActionsColDef,
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { parseJSON } from 'date-fns';
import { useState } from 'react';
import DataList from '../../../components/DataList';
import '../../../css/dataGrid.css';
import useCheckPermission from '../../../hooks/useCheckPermission';
import CombineChimneyDialog from '../chimneys/CombineChimneyDialog';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import chimneyService from '../../../services/chimneySweeping/chimneyService';
import { useSnackbar } from 'notistack';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';

export default function ChimneyPickerDialog(props: any) {
  const {
    open,
    onClose,
    onSelected,
    chimneys,
    selectedLength = 0,
    setChimneys,
  } = props;
  const { checkPermission } = useCheckPermission();
  const [selectedChimneys, setSelectedChimneys] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const confirmDeleteDialog = useConfirmDeleteDialog();

  const handleClose = () => {
    setSelectedChimneys([]);
    onClose();
  };
  const [combineChimneyDialog, setCombineChimneyDialog] = useState<any>({
    open: false,
    chimneyId: 0,
    addressId: 0,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const confirmDialog = useConfirmDialog();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const searchChimneys = () => {
    // Convert the search term to lowercase for case-insensitive search
    const searchTermLower = searchTerm.toLowerCase();

    // Perform a fuzzy search by splitting the search term into tokens
    const searchTokens = searchTermLower.split(/\s+/);

    // Filter chimneys based on the search tokens
    const filteredChimneys = chimneys?.filter((chimney) => {
      // Convert the chimney name to lowercase
      const chimneyNameLower = chimney.typeName.toLowerCase();

      // Check if each token is present in the chimney name
      return searchTokens.every((token) => chimneyNameLower.includes(token));
    });

    return filteredChimneys;
  };
  const handleDelete = (id: number) => {
    chimneyService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setChimneys(chimneys.filter((row) => row.id !== id));
      }
    });
  };
  const handleClosing = (row: any, isClosed: boolean) => {
    chimneyService.update({ ...row, isClosed }).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
      }
      setChimneys(
        chimneys.map((r) => {
          if (r.id === row.id) {
            return { ...r, closingDate: response.result.closingDate };
          }
          return r;
        })
      );
      setSelectedChimneys([...selectedChimneys.filter((x) => x.id !== row.id)]);
    });
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (checkPermission(['ChimneyCombine'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Összevonás">
              <JoinFull />
            </Tooltip>
          }
          label="Összevonás"
          onClick={() =>
            setCombineChimneyDialog({
              open: true,
              chimneyId: params.row.id,
              addressId: params.row.addressId,
            })
          }
          showInMenu
        />
      );
    }

    if (checkPermission(['ChimneyEdit']) && !params.row.closingDate) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Megszűntetés">
              <DoDisturb />
            </Tooltip>
          }
          label="Megszűntetés"
          onClick={() =>
            confirmDialog.setConfirmParams({
              open: true,
              name: 'Megszűnteti a kéményt?',
              onConfirm: async () => handleClosing(params.row, true),
            })
          }
          showInMenu
        />
      );
    }

    if (checkPermission(['ChimneyEdit']) && params.row.closingDate) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Megszűntetés visszavonása">
              <CheckCircle />
            </Tooltip>
          }
          label="Megszűntetés visszavonása"
          onClick={() =>
            confirmDialog.setConfirmParams({
              open: true,
              name: 'Visszavonja a kémény megszűntetését?',
              onConfirm: async () => handleClosing(params.row, false),
            })
          }
          showInMenu
        />
      );
    }

    if (checkPermission(['ChimneyDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          showInMenu
          onClick={() =>
            confirmDeleteDialog.setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    actions.push(
      <Button
        variant="contained"
        color={color ? color : 'primary'}
        disabled={
          selectedChimneys?.length + selectedLength >= 5 ||
          params.row.closingDate
        }
        onClick={() => {
          if (selectedChimneys.some((x) => x.id === params.row.id)) {
            setSelectedChimneys(
              selectedChimneys.filter((x) => x.id !== params.row.id)
            );
          } else {
            setSelectedChimneys([...selectedChimneys, params.row]);
          }
        }}
        sx={{ padding: '0px 10px' }}
      >
        Kiválasztás
      </Button>
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'typeName',
      headerName: 'Típus',
      flex: 150,
      valueGetter(params) {
        return params.row.typeName.startsWith('K') &&
          params.row.connectionElementLength
          ? `${params.row.typeName}(${params.row.connectionElementLength} fm)`
          : params.row.typeName;
      },
    },
    {
      field: 'groupNumber',
      headerName: 'Csoport/járat számjelzése',
      flex: 150,
    },
    {
      field: 'usingSinceDate',
      headerName: 'Használatba vétel dátuma',
      valueFormatter(params) {
        return params.value
          ? new Date(params.value as string).toLocaleDateString()
          : '-';
      },
      flex: 200,
    },
    {
      field: 'lastControlYear',
      headerName: 'Utolsó ellenőrzés éve',
      flex: 100,
    },
    {
      field: 'isInUse',
      headerName: 'Használatban van',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 150,
    },
    {
      field: 'hasBeenUsed',
      headerName: 'Volt már használatban',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 150,
    },
    {
      field: 'isBanned',
      headerName: 'Tiltott',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 50,
    },
    {
      field: 'closingDate',
      headerName: 'Megszűntetés dátuma',
      valueFormatter(params) {
        return params.value ? parseJSON(params.value).toLocaleString() : '-';
      },
      flex: 200,
    },
    {
      minWidth: 250,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle>Válasszon kéményeket!</DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <Grid item xs={12} pb={2}>
            <TextField
              value={searchTerm ?? ''}
              onChange={handleSearchChange}
              label="Termék keresése"
              name="name"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <DataList
            rows={searchChimneys()}
            columns={columns}
            localStorageKey={'SelectChimneysDialog'}
            getRowClassName={(params) => {
              return selectedChimneys?.some((x) => x.id === params.row.id)
                ? 'verified'
                : '';
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'inherit',
              },
            }}
          />
          <confirmDeleteDialog.ConfirmDeleteDialog />
          <confirmDialog.ConfirmDialog actionName={'Megszüntetés'} />
          <CombineChimneyDialog
            chimneyId={combineChimneyDialog.chimneyId}
            addressId={combineChimneyDialog.addressId}
            open={combineChimneyDialog.open}
            onClose={() =>
              setCombineChimneyDialog({ open: false, chimneyId: 0 })
            }
            onSelected={() => {
              setChimneys([
                ...chimneys?.filter(
                  (r) => r.id !== combineChimneyDialog.chimneyId
                ),
              ]);
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!(chimneys?.length > 0)}
          onClick={() => {
            onSelected(selectedChimneys);
            handleClose();
          }}
        >
          Véglegesítés
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
