import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { formatCurrency } from '../../../utils/valueFormatters';
import { Download, Help, HelpCenterSharp, Upload } from '@mui/icons-material';

const RankingCardComponent = (props: any) => {
  const {
    allData,
    cardHeader,
    disableName = false,
    filteredLastYearData,
  } = props;
  const isSmallScreen = useMediaQuery('(max-width:1400px)');

  const generateColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00f555ff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  };

  let lastYearDifferencePercentage =
    (((allData?.totalIncomeValue ?? 0) -
      (filteredLastYearData?.totalIncomeValue ?? 0)) /
      (filteredLastYearData?.totalIncomeValue ?? 0)) *
    100;

  return (
    <Card
      sx={{
        margin: 'auto',
        transition: '0.3s',
        borderRadius: '10px',
      }}
    >
      <CardHeader
        sx={{
          background: '#E4E4E4',
          display: 'block',
          color: 'black',
        }}
        action={
          <Grid container item xs={12}>
            {cardHeader}
          </Grid>
        }
      />
      <CardContent style={{ marginTop: 'auto', padding: '0px' }}>
        <Grid container item xs={12} sx={{}} p={2}>
          {!disableName && (
            <>
              {' '}
              <Grid
                item
                container
                xs={12}
                textAlign={'center'}
                justifyContent={'center'}
                pt={1}
              >
                {allData?.imageUrl ? (
                  <img
                    width={50}
                    height={50}
                    src={allData?.imageUrl}
                    alt="nincs"
                  />
                ) : (
                  <Grid item>
                    <Avatar
                      sx={{
                        width: 50,
                        height: 50,
                        background:
                          generateColor(allData?.employeeName ?? '') ?? '#000',
                      }}
                    >
                      {(allData?.employeeName && allData?.employeeName[0]) ??
                        ''}
                      {allData?.employeeName &&
                        allData?.employeeName[0]?.includes(' ') &&
                        allData?.employeeName?.split(' ')[1][0]}
                    </Avatar>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} textAlign={'center'}>
                <Typography fontWeight={'bold'}>
                  {allData?.employeeName ?? ''}
                </Typography>
              </Grid>
            </>
          )}
          <Grid
            item
            container
            xs={12}
            height={'40%'}
            justifyContent={'center'}
            alignContent={'center'}
            textAlign={'center'}
          >
            <Grid item xs={12} position="relative">
              <Typography
                variant="h4"
                sx={{
                  display: 'inline-block',
                  marginRight: isSmallScreen ? '0px' : '10px',
                }}
              >
                {formatCurrency(allData?.totalIncomeValue ?? 0)}
              </Typography>
              {filteredLastYearData &&
              filteredLastYearData.totalIncomeValue > 0 ? (
                <>
                  <Typography
                    variant="h6"
                    color={lastYearDifferencePercentage > 0 ? 'green' : 'error'}
                    style={{
                      display: isSmallScreen ? 'block' : 'inline-block',
                      marginRight: isSmallScreen ? '0px' : '-67px',
                    }}
                  >
                    {lastYearDifferencePercentage > 0 ? '+' : ''}
                    {lastYearDifferencePercentage?.toFixed(0)}%{' '}
                    <sup>
                      <Tooltip
                        title={
                          <Typography
                            variant="body1"
                            style={{
                              whiteSpace: 'pre-line',
                              fontSize: '1.2rem',
                            }}
                          >
                            Előző évi bevétel azonos időszakban:
                            {formatCurrency(
                              filteredLastYearData?.totalIncomeValue ?? 0
                            )}
                          </Typography>
                        }
                      >
                        <Help fontSize="small" color="info" />
                      </Tooltip>
                    </sup>
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                {allData?.totalIncomes?.length ?? 0} db számla
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4"></Typography>
        </Grid>
        <Grid container sx={{ borderTop: { xl: '1px solid grey' } }}>
          <Grid
            item
            xs={12}
            xl={4}
            sx={{
              borderRight: { xl: '1px solid grey' },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'center', xl: 'left' },
                height: '100%',
                wordBreak: 'break-word',
              }}
            >
              <Tooltip title="Teljesített">
                <FavoriteIcon
                  fontSize="small"
                  color="success"
                  sx={{ marginRight: '5px' }}
                />
              </Tooltip>
              {formatCurrency(allData?.paidIncomeValue ?? 0)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            xl={4}
            sx={{ borderRight: { xl: '1px solid grey' } }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'center', xl: 'left' },
                height: '100%',
                wordBreak: 'break-word',
              }}
            >
              <Tooltip title="Kintlévő">
                <SwapVerticalCircleIcon
                  fontSize="small"
                  color="warning"
                  sx={{ marginRight: '5px' }}
                />
              </Tooltip>
              {formatCurrency(allData?.outstandingIncomeValue ?? 0)}
            </Typography>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Typography
              variant="subtitle1"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: { xs: 'center', xl: 'left' },
                height: '100%',
                wordBreak: 'break-word',
              }}
            >
              <Tooltip title="Lejárt">
                <AccessTimeFilledIcon
                  fontSize="small"
                  color="error"
                  sx={{ marginRight: '5px' }}
                />
              </Tooltip>
              {formatCurrency(allData?.dueIncomeValue ?? 0)}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RankingCardComponent;
