import { Grid, IconButton, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import CreateEntityDialog from './CreateEntityDialog';
import { useEffect, useState } from 'react';

const CreateEntityDecorator = (props) => {
  const {
    AutocompleteComponent,
    onSave,
    CreateEntityComponent,
    hideAdd = false,
    createEntityComponentProps,
    isClientCreate = false,
    forceOpen = false,
    setForceOpen = () => {},
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (forceOpen) {
      setOpen(true);
    }
  }, [forceOpen]);

  return (
    <Grid container style={{ position: 'relative' }}>
      {AutocompleteComponent && (
        <Grid item xs={12} pr={2}>
          {AutocompleteComponent}
        </Grid>
      )}
      {hideAdd !== true ? (
        <Grid
          item
          style={{
            position: AutocompleteComponent ? 'absolute' : 'unset',
            top: '0',
            right: '-1.5rem',
          }}
        >
          <Tooltip title="Új hozzáadása">
            <IconButton
              color="primary"
              onClick={() => {
                setOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : null}

      <CreateEntityDialog
        CreateEntityComponent={CreateEntityComponent}
        open={open}
        handleCancel={() => {
          setOpen(false);
          setForceOpen(false);
        }}
        navigateBack={false}
        onSave={(item) => {
          onSave(item);
          setOpen(false);
          setForceOpen(false);
        }}
        isClientCreate={isClientCreate}
        {...createEntityComponentProps}
      />
    </Grid>
  );
};
export default CreateEntityDecorator;
