import { createAction } from '@reduxjs/toolkit';
import { InvoiceTypes } from '../../types/InvoiceTypes';
import { Currency } from '../../types/Currency';
import {
  invoiceInfo,
  CartItem,
  CartService,
} from '../../pages/sales/SalesPage';

export const reset = createAction('sales/reset');

export const setDiscount = createAction<number>('sales/setDiscount');
export const setDocumentBlockId = createAction<number>(
  'sales/setDocumentBlockId'
);
export const setSavedPrices = createAction<any>('sales/setSavedPrices');
export const setAddressId = createAction<number>('sales/setAddressId');
export const setInvoiceInfo = createAction<invoiceInfo>('sales/setInvoiceInfo');
export const setIsCopy = createAction<boolean>('sales/setIsCopy');
export const setDescription = createAction<string>('sales/cart/setDescription');
export const setPaymentType = createAction<string>('sales/cart/setPaymentType');
export const setCompanyId = createAction<number>('sales/cart/setCompanyId');
export const setPaid = createAction<boolean>('sales/cart/setPaid');
export const setDueDate = createAction<Date>('sales/cart/setDueDate');
export const setFulfillmentDate = createAction<Date>(
  'sales/cart/setFulfillmentDate'
);
export const setClientId = createAction<number>('sales/cart/setClientId');
export const setOrderId = createAction<number>('sales/cart/setOrderId');
export const setIsBooking = createAction<boolean>('sales/cart/setIsBooking');
export const setCreateInvoice = createAction<boolean>('sales/setCreateInvoice');
export const setIsReadonly = createAction<boolean>('sales/setIsReadonly');
export const setIsElectronic = createAction<boolean>('sales/setIsElectronic');
export const setInvoiceType = createAction<InvoiceTypes>(
  'sales/setInvoiceType'
);
export const setPreviousInvoiceType = createAction<InvoiceTypes>(
  'sales/setPreviousInvoiceType'
);

export const addToCart = createAction<CartItem>('sales/cart/add');
export const setCart = createAction<CartItem[]>('sales/cart/set');
export const setOriginalCart = createAction<CartItem[]>(
  'sales/cart/setOriginal'
);
export const addToServices = createAction<CartService>('sales/services/add');
export const setServices = createAction<CartService[]>('sales/services/set');
export const removeFromServices = createAction<number>('sales/services/remove');
export const addToItemPackages = createAction<CartService>(
  'sales/ItemPackages/add'
);
export const setItemPackages = createAction<CartService[]>(
  'sales/ItemPackages/set'
);
export const removeFromItemPackages = createAction<number>(
  'sales/ItemPackages/remove'
);
export const replaceItemPackage = createAction<{
  itemPackage: any;
  oldId: number;
}>('sales/ItemPackages/replace');
export const setBookings = createAction<CartItem[]>('sales/cart/setBookings');
export const clearCart = createAction('sales/cart/clear');
export const removeFromCart = createAction<number>('sales/cart/remove');
export const replaceCartItem = createAction<{
  cartItem: CartItem;
  oldId: number;
}>('sales/cart/replace');
