import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyPicker from '../../../../../components/CurrencyPicker';
import workItemService from '../../../../../services/erp/workItemService';
import orderService from '../../../../../services/sales/orderService';
import {
  setCompanyId,
  setPaymentType,
} from '../../../../../stateManagement/actions/salesActions';
import { ClientTypes } from '../../../../../types/ClientTypes';
import { Currency } from '../../../../../types/Currency';
import { DocumentLanguage } from '../../../../../types/DocumentLanguage';
import { formatCurrency } from '../../../../../utils/valueFormatters';
import InvoiceInfoDialog from '../../../../sales/InvoiceInfoDialog';
import { WorkItemStatus } from '../../WorkItemForm';

const CompleteWorkItem: React.FC = (props: any) => {
  const {
    values,
    readonly,
    setIsSubmitting,
    setFieldValue,
    isSubmitting,
    clients,
    onClientAdded,
    onCompleteWithInvoice,
    onCompleteWithoutInvoice,
    invoiceNumberPrefixes,
    addInvoice,
    documentBlocks,
    taskTypes,
    services,
  } = props;
  const paymentTypes = ['Készpénz', 'Bankkártya', 'Átutalás', 'Utánvét'];
  const { companyId, paymentType } = useSelector((state: any) => state.sales);
  const [invoiceInfoDialogOpen, setInvoiceDialogOpen] = React.useState(false);
  const [hasNoInvoice, setHasNoInvoice] = React.useState(true);
  const user = useSelector((state: any) => state.user.userInfo);
  const createdAt = new Date(values?.createdAt).toLocaleDateString('hu-HU');
  const { enqueueSnackbar } = useSnackbar();
  const disableButtons = values?.selectedItems?.some((x) => x.itemName === '');

  const [inoviceAfterwardsDialog, setInvoiceAfterwardsDialog] =
    React.useState(false);
  const dispatch = useDispatch();

  const handleDialogOpen = () => {
    workItemService.update({ ...values }).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setInvoiceDialogOpen(true);
      }
    });
  };

  const handleCurrencyChange = (
    previousConversionRate: number,
    conversionRate: number
  ) => {
    if (values.selectedItems && values.selectedItems.length > 0) {
      setFieldValue(
        'selectedItems',
        values.selectedItems.map((x) => {
          let prevSellPriceGross =
            (x.sellPriceGross * previousConversionRate) / conversionRate;
          let prevSellPriceNet =
            (x.sellPriceNet * previousConversionRate) / conversionRate;
          return {
            ...x,
            sellPriceNet: prevSellPriceNet,
            sellPriceGross: prevSellPriceGross,
          };
        })
      );
    }
    if (values.itemPackages && values.itemPackages.length > 0) {
      setFieldValue(
        'itemPackages',
        values.itemPackages.map((itemPackage) => {
          let sellPriceGross = 0;
          let sellPriceNet = 0;
          let newItems = itemPackage.items?.map((item) => {
            let prevSellPriceGross =
              (item.sellPriceGross * previousConversionRate) / conversionRate;
            let prevSellPriceNet =
              (item.sellPriceNet * previousConversionRate) / conversionRate;
            sellPriceGross += prevSellPriceGross ?? 0 * item?.amount ?? 0;
            sellPriceNet += prevSellPriceNet ?? 0 * item?.amount ?? 0;

            return {
              ...item,
              sellPriceNet: prevSellPriceNet ?? 0,
              sellPriceGross: prevSellPriceGross ?? 0,
            };
          });
          let newServices = itemPackage.services?.map((service) => {
            let prevSellPriceGross =
              (service.servicePriceGross * previousConversionRate) /
              conversionRate;
            let prevSellPriceNet =
              (service.servicePriceNet * previousConversionRate) /
              conversionRate;
            sellPriceGross += prevSellPriceGross ?? 0 * service?.amount ?? 0;
            sellPriceNet += prevSellPriceNet ?? 0 * service?.amount ?? 0;

            return {
              ...service,
              servicePriceNet: prevSellPriceNet ?? 0,
              servicePriceGross: prevSellPriceGross ?? 0,
            };
          });

          return {
            ...itemPackage,
            items: newItems,
            services: newServices,
            sellPriceGross: sellPriceGross,
            sellPriceNet: sellPriceNet,
          };
        })
      );
    }
    if (values.selectedServices && values.selectedServices.length > 0) {
      let newServices = values.selectedServices?.map((service) => {
        let prevSellPriceGross =
          (service.servicePriceGross * previousConversionRate) / conversionRate;
        let prevSellPriceNet =
          (service.servicePriceNet * previousConversionRate) / conversionRate;
        return {
          ...service,
          servicePriceNet: prevSellPriceNet ?? 0,
          servicePriceGross: prevSellPriceGross ?? 0,
        };
      });
      setFieldValue('selectedServices', newServices);
    }
  };

  const netPrice =
    values.selectedItems?.reduce((a, x) => a + x.sellPriceNet * x.amount, 0) +
    values.itemPackages?.reduce((a, x) => a + x.sellPriceNet * x.amount, 0) +
    values.selectedServices?.reduce(
      (a, x) => a + x.servicePriceNet * x.amount,
      0
    );
  const grossPrice =
    values.selectedItems?.reduce((a, x) => a + x.sellPriceGross * x.amount, 0) +
    values.itemPackages?.reduce((a, x) => a + x.sellPriceGross * x.amount, 0) +
    values.selectedServices?.reduce(
      (a, x) => a + x.servicePriceGross * x.amount,
      0
    );

  const discount =
    values.selectedItems?.reduce(
      (a, x) => a + x.sellPriceGross * x.amount * (x.discount / 100),
      0
    ) +
    values.itemPackages?.reduce(
      (a, x) => a + x.sellPriceGross * x.amount * (x.discount / 100),
      0
    ) +
    values.selectedServices?.reduce(
      (a, x) => a + x.servicePriceGross * x.amount * (x.discount / 100),
      0
    );
  const discountNet =
    values.selectedItems?.reduce(
      (a, x) => a + x.sellPriceNet * x.amount * (x.discount / 100),
      0
    ) +
    values.itemPackages?.reduce(
      (a, x) => a + x.sellPriceNet * x.amount * (x.discount / 100),
      0
    ) +
    values.selectedServices?.reduce(
      (a, x) => a + x.servicePriceNet * x.amount * (x.discount / 100),
      0
    );

  const payPrice = grossPrice - discount;

  useEffect(() => {
    if (values.orderId > 0) {
      orderService.get(values.orderId).then((res) => {
        setHasNoInvoice(
          res.result.invoiceNumber === null || res.result.invoiceNumber === ''
        );
      });
    }
  }, [values.orderId]);

  const calculatefinalPrice = (isGross: boolean) => {
    return isGross ? grossPrice - discount : netPrice - discountNet;
  };

  const isInvoiceAfterwards =
    (values.workItemStatus === WorkItemStatus.Closed ||
      values.workItemStatus === WorkItemStatus.Evaluated ||
      values.workItemStatus === WorkItemStatus.Completed) &&
    !(values.orderId > 0);

  return (
    <Grid container justifyContent="center" spacing={1} pt={5}>
      <Grid item xs={6} md={6}>
        <strong>Nettó összeg:</strong>
      </Grid>
      <Grid item xs={6} md={3} textAlign="right">
        {formatCurrency(netPrice, values.currency)}
      </Grid>
      <Grid item xs={6} md={6}>
        <strong> Bruttó összeg:</strong>
      </Grid>
      <Grid item xs={6} md={3} textAlign="right">
        {formatCurrency(grossPrice, values.currency)}
      </Grid>
      <Grid item xs={8} md={6} sx={{ borderBottom: '2px solid' }}>
        <strong> Ebből kedvezmény:</strong>
      </Grid>
      <Grid
        item
        xs={4}
        md={3}
        textAlign="right"
        sx={{ borderBottom: '2px solid' }}
      >
        {formatCurrency(discount * -1, values.currency)}
      </Grid>
      <Grid item md={6}>
        <strong> Bruttó fizetendő végösszeg:</strong>
      </Grid>
      <Grid item md={3} textAlign="right">
        {formatCurrency(payPrice, values.currency)}
      </Grid>
      <Grid item xs={12} p={2}>
        <CurrencyPicker
          currency={values.currency}
          setCurrency={(value: Currency) => {
            setFieldValue('currency', value);
          }}
          conversionRate={values.conversionRate}
          // readonly={readonly}
          setConversionRate={(value: number) => {
            handleCurrencyChange(values.conversionRate, value);
            setFieldValue('conversionRate', value);
            // setConversionRateState(value);
          }}
          localStorageKey="WorkItemForm"
          language={values.invoiceLanguage}
          setLanguage={(value: DocumentLanguage) => {
            setFieldValue('invoiceLanguage', value);
          }}
        />
      </Grid>
      {(values.roleName === 'Mechanic' || isInvoiceAfterwards) && (
        <>
          <Grid item xs={12} pt={2} pb={2} textAlign={'center'}>
            <Autocomplete
              disablePortal
              id="companyId"
              disabled={readonly && !isInvoiceAfterwards}
              value={companyId}
              onChange={(event, value) => {
                dispatch(setCompanyId(value));
                setFieldValue('companyId', value);
              }}
              getOptionLabel={(option) =>
                clients?.find((g) => g.id === option)?.companyName ?? ''
              }
              options={clients
                ?.filter((x) => x.clientType === ClientTypes.Distributor)
                ?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ maxWidth: '300px' }}
                  required
                  label="Számlakibocsátó"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} pt={2} textAlign={'center'}>
            <Autocomplete
              disablePortal
              id="paymentType"
              disabled={readonly && !isInvoiceAfterwards}
              value={paymentType}
              onChange={(event, value) => {
                dispatch(setPaymentType(value));
                setFieldValue('paymentType', value);
              }}
              options={paymentTypes?.map((g) => g)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  style={{ maxWidth: '300px' }}
                  label="Fizetési mód"
                />
              )}
            />
          </Grid>
        </>
      )}
      <Grid item container xs={12} justifyContent="center" spacing={2}>
        {values.roleName === 'Mechanic' && (
          <Grid item>
            <Button
              onClick={() => {
                if (disableButtons) {
                  enqueueSnackbar('Valamelyik terméknek hiányzik a neve!', {
                    variant: 'error',
                  });
                } else {
                  setInvoiceDialogOpen(true);
                }
              }}
              disabled={
                readonly ||
                values.roleName !== 'Mechanic' ||
                isSubmitting ||
                !companyId ||
                !paymentType
              }
              variant="contained"
            >
              Számla kiállítása
            </Button>
          </Grid>
        )}
        {(values.workItemStatus === WorkItemStatus.Closed ||
          values.workItemStatus === WorkItemStatus.Evaluated ||
          values.workItemStatus === WorkItemStatus.Completed) &&
          hasNoInvoice && (
            <Grid item>
              <Button
                onClick={() => {
                  if (disableButtons) {
                    enqueueSnackbar('Valamelyik terméknek hiányzik a neve!', {
                      variant: 'error',
                    });
                  } else {
                    handleDialogOpen();
                  }
                }}
                variant="contained"
              >
                Számla kiállítása utólag
              </Button>
            </Grid>
          )}
        {(values.roleName === 'Mechanic' || isInvoiceAfterwards) && (
          <Grid item>
            <Button
              onClick={() => {
                if (disableButtons) {
                  enqueueSnackbar('Valamelyik terméknek hiányzik a neve!', {
                    variant: 'error',
                  });
                } else {
                  setInvoiceAfterwardsDialog(true);
                }
              }}
              disabled={
                (readonly && !isInvoiceAfterwards) ||
                isSubmitting ||
                !companyId ||
                !paymentType ||
                paymentType === 'Bankkártya' ||
                paymentType === 'Átutalás'
              }
              variant="contained"
            >
              Zárás számla nélkül
            </Button>
          </Grid>
        )}
      </Grid>
      <InvoiceInfoDialog
        onSubmit={(invoiceInfo: any) => {
          if (
            values.workItemStatus === WorkItemStatus.Closed ||
            values.workItemStatus === WorkItemStatus.Evaluated ||
            values.workItemStatus === WorkItemStatus.Completed
          ) {
            addInvoice(
              values.id,
              invoiceInfo,
              values.conversionRate,
              values.currency,
              values.invoiceLanguage
            ).then(() => {
              setInvoiceDialogOpen(false);
            });
          } else {
            onCompleteWithInvoice(values, setIsSubmitting, invoiceInfo).then(
              () => {
                setInvoiceDialogOpen(false);
              }
            );
          }
        }}
        open={invoiceInfoDialogOpen}
        setOpen={setInvoiceDialogOpen}
        clients={clients}
        workItemClient={values.clientId}
        invoiceNumberPrefixes={invoiceNumberPrefixes}
        onClientAdded={onClientAdded}
        documentBlocks={documentBlocks}
      />
      <Dialog
        open={inoviceAfterwardsDialog}
        onClose={() => setInvoiceAfterwardsDialog(false)}
      >
        <DialogTitle>Számlázás</DialogTitle>
        <DialogContent>
          <DialogContentText>Utólag számlázandó?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onCompleteWithoutInvoice(
                {
                  ...values,
                  invoiceAfterwards: true,
                  completeWorkFlow: isInvoiceAfterwards ? false : true,
                },
                setIsSubmitting,
                paymentType,
                companyId
              );
            }}
            variant="contained"
            color="primary"
          >
            Igen
          </Button>
          <Button
            onClick={() => {
              onCompleteWithoutInvoice(
                { ...values, invoiceAfterwards: false },
                setIsSubmitting,
                paymentType,
                companyId
              );
            }}
            variant="contained"
            color="primary"
          >
            Nem
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CompleteWorkItem;
