import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AttachmentTypes } from '../types/AttachmentTypes';
import useFileSelect from '../hooks/useFileSelect';
import { useSnackbar } from 'notistack';

const FileUploadingDialog = (props: any) => {
  const {
    open,
    onClose,
    attachmentTypeState,
    onAllFileUploaded,
    onSelect,
    entityUniqueId = null,
    overrideFileName = null,
  } = props;

  const { FileSelect, onUpload, uploading, error, uploadedFile } =
    useFileSelect();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Fájlok feltöltése</DialogTitle>
      <DialogContent>
        <FileSelect
          onUpload={(
            fileName: string,
            contentType: string,
            data: any,
            type: AttachmentTypes,
            entityUniqueId: string | null
          ) =>
            onUpload(
              overrideFileName
                ? `${overrideFileName}${fileName.substring(fileName.lastIndexOf('.'))}`
                : fileName,
              contentType,
              data,
              type,
              entityUniqueId,
              (uploadedFile: any) => {},
              (error: any) => {
                enqueueSnackbar(error, { variant: 'error' });
              }
            )
          }
          onAllFileUploaded={() => {
            onAllFileUploaded();
          }}
          onSelect={(file: any) => {
            onSelect();
          }}
          fileAttachmentType={attachmentTypeState}
          uploading={uploading}
          entityUniqueId={entityUniqueId}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Mégse</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadingDialog;
