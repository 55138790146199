import { Search } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import { GridActionsColDef, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataList from '../../../components/DataList';
import '../../../css/dataGrid.css';
import chimneyService from '../../../services/chimneySweeping/chimneyService';
import useConfirmDialog from '../../../hooks/useConfirmDialog';

export default function CombineChimneyDialog(props: any) {
  const { open, onClose, onSelected, chimneyId, addressId } = props;
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [selectedChimneyId, setSelectedChimneyId] = useState<number>(0);
  const [chimney, setChimney] = useState<any>(null);
  const handleClose = () => {
    setSelectedChimneyId(0);
    onClose();
  };
  const confirmDialog = useConfirmDialog();

  const handleCombine = (selectedChimneyId: any) => {
    chimneyService
      .combine({
        chimneyIdToDelete: chimneyId,
        chimneyIdToKeep: selectedChimneyId,
      })
      .then((response) => {
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('A kémények sikeresen össze lettek vonva!', {
            variant: 'success',
          });
          onSelected();
        }
      })
      .finally(() => {
        handleClose();
      });
  };

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const searchChimneys = () => {
    // Convert the search term to lowercase for case-insensitive search
    const searchTermLower = searchTerm.toLowerCase();

    // Perform a fuzzy search by splitting the search term into tokens
    const searchTokens = searchTermLower.split(/\s+/);

    // Filter chimneys based on the search tokens
    const filteredChimneys = rows.filter((chimney) => {
      // Convert the chimney name to lowercase
      const chimneyNameLower = chimney.typeName.toLowerCase();

      // Check if each token is present in the chimney name
      return searchTokens.every((token) => chimneyNameLower.includes(token));
    });

    return filteredChimneys;
  };

  useEffect(() => {
    if (chimneyId === 0 || !addressId) return;
    dispatch({ type: 'SHOW_QUERY' });
    let controller = new AbortController();
    chimneyService
      .list(addressId)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setChimney(response.records.find((x) => x.id === chimneyId) || null);
          setRows(response.records.filter((x) => x.id !== chimneyId) || []);
        } else if (response.errorMessages.length > 0) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      controller.abort();
    };
  }, [chimneyId, addressId]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <Button
        variant="contained"
        color={color ? color : 'primary'}
        disabled={chimney === null}
        onClick={() => {
          setSelectedChimneyId(params.row.id);
        }}
        sx={{ padding: '0px 10px' }}
      >
        Kiválasztás
      </Button>
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'typeName',
      headerName: 'Típus',
      flex: 150,
      valueGetter(params) {
        return params.row.typeName.startsWith('K') &&
          params.row.connectionElementLength
          ? `${params.row.typeName}(${params.row.connectionElementLength} fm)`
          : params.row.typeName;
      },
    },
    {
      field: 'groupNumber',
      headerName: 'Csoport/járat számjelzése',
      flex: 150,
    },
    {
      field: 'usingSinceDate',
      headerName: 'Használatba vétel dátuma',
      valueFormatter(params) {
        return params.value
          ? new Date(params.value as string).toLocaleDateString()
          : '-';
      },
      flex: 200,
    },
    {
      field: 'lastControlYear',
      headerName: 'Utolsó ellenőrzés éve',
      flex: 100,
    },
    {
      field: 'isInUse',
      headerName: 'Használatban van',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 150,
    },
    {
      field: 'hasBeenUsed',
      headerName: 'Volt már használatban',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 150,
    },
    {
      field: 'isBanned',
      headerName: 'Tiltott',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 50,
    },
    {
      field: 'closingDate',
      headerName: 'Megszűntetés dátuma',
      valueFormatter(params) {
        return params.value ? parseJSON(params.value).toLocaleString() : '-';
      },
      flex: 200,
    },
    {
      minWidth: 250,
      field: 'actions',
      type: 'actions',
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle>
        Válassza ki melyik kéménnyel vonjuk össze a kiválasztott kéményt!
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <Grid item xs={12} pb={2}>
            <TextField
              value={searchTerm ?? ''}
              onChange={handleSearchChange}
              label="Termék keresése"
              name="name"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <DataList
            rows={searchChimneys()}
            columns={columns}
            localStorageKey={'SelectChimneyIdDialog'}
            getRowClassName={(params) => {
              return params.row.id === selectedChimneyId ? 'verified' : '';
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: 'inherit',
              },
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!(selectedChimneyId > 0)}
          onClick={() => {
            confirmDialog.setConfirmParams({
              open: true,
              name: `Figyelem! Az elsődlegesen választott kémény adatai törlésre kerülnek!`,
              onConfirm: async () => handleCombine(selectedChimneyId),
            });
          }}
        >
          Véglegesítés
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          Mégse
        </Button>
      </DialogActions>
      <confirmDialog.ConfirmDialog
        actionName={'Biztosan összevonja a termékeket?'}
      />
    </Dialog>
  );
}
