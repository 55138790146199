import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility,
} from '@mui/icons-material';
import { Grid, IconButton, Paper, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import userCertificationService from '../../../services/HR/userCertificationService';

const UserCertificationPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('UserCertificationsPage.title');

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    userCertificationService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'obtainingDate',
      headerName: 'Megszerzés dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 100,
    },
    {
      field: 'validityDate',
      headerName: 'Érvényesség dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 100,
    },
    { field: 'certificationName', headerName: 'Képesítés', flex: 100 },
    { field: 'userName', headerName: 'Dolgozó', flex: 100 },
    {
      field: 'documentNumber',
      headerClassName: 'title',
      headerName: 'Dokumentum száma',
      flex: 100,
    },

    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Dolgozói képesítések{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'UserCertificationsPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default UserCertificationPage;
