/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef } from '@mui/x-data-grid';
import { Paper, Grid, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DataList from '../../../components/DataList';
import warehouseTransactionService from '../../../services/wms/warehouseTransactionService';
import { Add as AddIcon } from '@mui/icons-material';
import { MoveDown as MoveIcon } from '@mui/icons-material';
import { Remove as RemoveIcon } from '@mui/icons-material';
import { Delete as DiscardIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { useSnackbar } from 'notistack';
import { parseJSON } from 'date-fns';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import DatePickerHeader from '../../../components/DatePickerHeader';
import React from 'react';

export interface TransactionLog {
  id: string;
  warehouseId: string;
  warehouseItemId: string;
  warehouseTransactionType: number;
  amount: number;
  description: string;
  date: Date;
}

const TransactionLogs = () => {
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const titleDescriptor = useFunctionDescriptor('TransactionLogs.title');
  const [now, setNow] = useState<any>(new Date());
  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: now,
    endDate: now,
  });
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const [dateTabValue, setDateTabValue] = React.useState<any>(0);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    warehouseTransactionService
      .list(selectedInterval.startDate, selectedInterval.endDate, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setRows(response.records);
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      abort.abort();
    };
  }, [selectedInterval]);

  const getTypeName = (params: any) => {
    switch (params.row.warehouseTransactionType) {
      case 1:
        return 'Hozzáadva';
      case 2:
        return 'Eltávolítva';
      case 3:
        return 'Lefoglalva';
      case 4:
        return 'Foglalás feloldása';
      case 5:
        return 'Selejtezve';
      case 7:
        return 'Beszerzés alatt';
      case 8:
        return 'Beszerzés alatt(Piszkozat)';
      case 9:
        return 'Beszerzés alatt(Megszakítva)';
    }
  };

  const getDate = (params: any) => {
    return new Date(params.row.date);
  };

  const columns: GridColDef[] = [
    {
      field: 'warehouseName',
      headerName: 'Raktár',
      headerClassName: 'title',
      flex: 100,
      editable: false,
    },
    {
      field: 'itemName',
      headerName: 'Termék',
      flex: 100,
      editable: false,
    },
    {
      field: 'warehouseTransactionType',
      headerName: 'Esemény típusa',
      flex: 100,
      editable: false,
      valueGetter: getTypeName,
    },
    {
      field: 'amount',
      headerName: 'Mennyiség',
      flex: 100,
      editable: false,
    },
    {
      field: 'date',
      headerName: 'Időpont',
      flex: 100,
      editable: false,
      valueGetter: getDate,
      valueFormatter: (params: any) => {
        return parseJSON(params.value).toLocaleString();
      },
    },
    {
      field: 'description',
      headerName: 'Leírás',
      width: 300,
      editable: false,
    },
  ];

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Raktár események{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <DatePickerHeader
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            tabValue={dateTabValue}
            setTabValue={setDateTabValue}
            localStorageKey="TransactionLogsPage"
          />
        </Grid>
        {checkPermission(['WarehouseOperations']) && (
          <Grid container item xs={12} p={4} justifyContent="end">
            <Grid item>
              <Tooltip title="Termék hozzáadása raktárhoz">
                <IconButton
                  component={RouterLink}
                  to={`/wms/transactions/add`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Termék kivétele a raktárból">
                <IconButton
                  component={RouterLink}
                  to={`/wms/transactions/remove`}
                  color="primary"
                >
                  <RemoveIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Termék áthelyezése másik raktárba">
                <IconButton
                  component={RouterLink}
                  to={`/wms/transactions/move`}
                  color="primary"
                >
                  <MoveIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Termék selejtezése">
                <IconButton
                  component={RouterLink}
                  to={`/wms/transactions/discard`}
                  color="primary"
                >
                  <DiscardIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'TransactionLogs'}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date', sort: 'desc' }],
              },
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TransactionLogs;
