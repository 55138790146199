import { Delete as DeleteIcon, Sync } from '@mui/icons-material';
import { Grid, IconButton, Paper, Tab, Tabs, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EntityNavigator from '../../../components/EntityNavigator';
import TreeViewComponent from '../../../components/TreeViewComponent';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import itemGroupService from '../../../services/wms/itemGroupService';
import { EntityTypes } from '../../../types/EntityTypes';

export interface ItemGroup {
  id: string;
  itemGroupGroupId: string;
}

const ItemGroupsPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('ItemGroupsPage.title');
  const [tabValue, setTabValue] = useState(0);

  const { checkPermission } = useCheckPermission();

  const fetch = () => {
    itemGroupService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError)
          setRows([
            ...response.records.map((x: any) => ({
              ...x,
              draggable: true,
              open: true,
            })),
            {
              id: null,
              name: 'Fő',
              parentId: -1,
              draggable: false,
              open: true,
            },
          ]);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    if (tabValue === 0) {
      fetch();
    } else {
      itemGroupService
        .listDeleted()
        .then((response) => {
          if (response.canceled) return;
          if (!response.hasError)
            setRows([
              ...response.records.map((x: any) => ({
                ...x,
                draggable: true,
                open: true,
              })),
              {
                id: null,
                name: 'Fő',
                parentId: -1,
                draggable: false,
                open: true,
              },
            ]);
          else
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  }, [tabValue]);

  const getActions = (row, color: any) => {
    var actions = [];

    if (tabValue === 1) {
      actions.push(
        <Grid item display={'inline-flex'}>
          <Tooltip title={'Visszaállítás'}>
            <IconButton
              color={color ? color : 'primary'}
              onClick={() =>
                setParams({
                  open: true,
                  name: 'Biztosan visszaállítja a termékcsoportot?',
                  onConfirm: async () => handleRecover(row.id),
                })
              }
            >
              {<Sync />}
            </IconButton>
          </Tooltip>
        </Grid>
      );
    }

    let readonly = !checkPermission(['ItemGroupEdit']);
    actions.push(
      <EntityNavigator
        entityType={EntityTypes.ItemGroupEdit}
        entityId={row.id}
        value={readonly ? 'Megtekintés' : 'Szerkesztés'}
        showIcon
        setDisplay={'inline-flex'}
        readonly={readonly}
        onSave={(result: any) => {
          setRows(
            rows.map((x) =>
              x.id === row.id ? { ...result, droppable: true } : x
            )
          );
        }}
      />
    );
    if (checkPermission(['ItemGroupDelete'])) {
      actions.push(
        <Grid item display={'inline-flex'}>
          <Tooltip title={'Törlés'}>
            <IconButton
              color={color ? color : 'primary'}
              onClick={() =>
                setParams({
                  open: true,
                  name:
                    tabValue === 1
                      ? 'Figyelem! Biztosan szeretné VÉGLEGESEN törölni a termékcsoportot és az alatta lévő termékcsoportokat?'
                      : 'Figyelem! A termékcsoport alatt lévő csoportok is törlődnek, biztosan törölni szeretné?',
                  onConfirm: async () => handleDelete(row.id),
                })
              }
            >
              {<DeleteIcon />}
            </IconButton>
          </Tooltip>
        </Grid>
      );
    }

    return actions;
  };

  const getFrontActions = (row, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['ItemGroupEdit']);
    if (tabValue === 0) {
      actions.push(
        <EntityNavigator
          entityType={EntityTypes.ItemGroupCreate}
          entityId={row.id}
          value={'Hozzáadás'}
          showIcon
          isCreate
          onSave={(result: any) => {
            setRows([...rows, { ...result, droppable: true, open: true }]);
          }}
        />
      );
    }

    return actions;
  };

  const handleDelete = (id: number) => {
    if (tabValue === 1) {
      itemGroupService.deletePermanently(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres törlés', {
            variant: 'success',
          });
          setRows(rows.filter((row) => row.id !== id));
        }
      });
    } else {
      itemGroupService.delete(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres törlés', {
            variant: 'success',
          });
          setRows(rows.filter((row) => row.id !== id));
        }
      });
    }
  };

  const handleRecover = (id: number) => {
    if (tabValue === 1) {
      itemGroupService.recover(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres visszaállítás', {
            variant: 'success',
          });
          setRows(rows.filter((row) => row.id !== id));
        }
      });
    }
  };

  const handleDNG = (
    sourceId: number,
    newOrder: number,
    newParentId: number
  ) => {
    dispatch({ type: 'SHOW_QUERY' });
    itemGroupService
      .move({ id: sourceId, newOrder: newOrder, newParentId: newParentId })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mozgatás', {
            variant: 'success',
          });
          fetch();
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const reOrder = () => {
    dispatch({ type: 'SHOW_QUERY' });
    itemGroupService
      .reOrder()
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres sorrend módosítás', {
            variant: 'success',
          });
          setRows([
            ...response.records.map((x: any) => ({
              ...x,
              draggable: true,
              open: true,
            })),
            {
              id: null,
              name: 'Fő',
              parentId: -1,
              draggable: false,
              open: true,
            },
          ]);
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Termékcsoportok{titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={(e, value) => {
              setTabValue(value);
            }}
            TabIndicatorProps={{ style: { background: '#1976d2' } }}
            variant="fullWidth"
          >
            <Tab value={0} label="Aktív" sx={{ fontWeight: 'bold' }} />
            <Tab value={1} label="Törölt" sx={{ fontWeight: 'bold' }} />
          </Tabs>
        </Grid>
        <Grid item xs={12} pt={2} textAlign={'right'}>
          <Tooltip title={'Sorrend alaphelyzet'}>
            <IconButton
              color={'primary'}
              onClick={() => reOrder()}
              disabled={!checkPermission(['ItemGroupEdit']) || tabValue === 1}
            >
              {<Sync />}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <TreeViewComponent
            data={rows}
            behindActions={(node: any) => (
              <Grid item>{getActions(node, 'primary')}</Grid>
            )}
            handleDNG={handleDNG}
            readonly={!checkPermission(['ItemGroupEdit'])}
            disableMove={tabValue === 1}
            frontActions={(node: any) => (
              <Grid item>{getFrontActions(node, 'primary')}</Grid>
            )}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default ItemGroupsPage;
